import config from '../config';

const fetchGoogleInstance = async (url, options = {}) => {
  const token = localStorage.getItem('token');
  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  const defaultOptions = {
    headers: { ...defaultHeaders, ...options.headers },
    ...options,
  };

  const response = await fetch(`${config.apiGoogleUrl}${url}`, defaultOptions);

  return response;
};

export default fetchGoogleInstance;
