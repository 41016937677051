import React, { Component } from 'react';
import zxcvbn from 'zxcvbn';
import fetchInstance from '../utils/fetchInstance';
import config from '../config';
import UserPageStyles from './styles/UserPageStyles';
import Title from './styles/TitleStyle';
import FormStyles from './styles/FormStyles';
import ExtendedWrapperFromModalStyles from './styles/ExtendedWrapperFromModalStyles';
import FileUploader from './FileUploader/FileUploader';

export default class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: props.user.name,
        email: props.user.email,
        facebookAccount: props.user.facebookAccount,
        googleAccount: props.user.googleAccount,
        profileImage: props.user.profileImage,
      },
      strength: 0,
      selectedFile: null,

      file: null,
      uploader: null,
      size: 'cover',
      bgName: 'No file chosen',
    };
  }

  handleInput = (ev) => {
    const user = { ...this.state.user };
    user[ev.target.name] = ev.target.value;
    let strength = this.state.strength;
    if (ev.target.name === 'new__password')
      strength = zxcvbn(ev.target.value).score;
    return this.setState({ user, strength });
  };

  updateUser = (ev) => {
    if (ev) ev.preventDefault();
    const user = { ...this.state.user };
    if (!user.name || !user.email) {
      return this.props.setBanner(
        true,
        'bad',
        'User must have both name and email',
      );
    }
    if (user.password || user.new__password) {
      if (!user.password || !user.new__password) {
        return this.props.setBanner(
          true,
          'bad',
          'All password fields must be filled',
        );
      }

      if (user.new__password.length < 6) {
        return this.props.setBanner(
          true,
          'bad',
          'Password must be at least 6 characters long',
        );
      }

      if (this.state.strength < 2) {
        return this.props.setBanner(true, 'bad', 'Password is too weak');
      }
    }

    if (this.state.uploader) {
      user.profileImage = this.state.uploader.uploads[0].path;
    }

    fetchInstance('/updateUser', {
      method: 'POST',
      body: JSON.stringify({ user }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.success) {
          // Force a full user refresh in the app
          this.props.checkUser();
          return this.props.setBanner(
            true,
            'good',
            'Success. Account updated.',
          );
        }
        throw new Error(data.message);
      })
      .catch((err) => this.props.setBanner(true, 'bad', err.message));
  };

  unlinkSocialAccount = (ev) => {
    const user = { ...this.state.user };
    const account = ev.target.name.split('__')[1];
    user[account] = 'delete';
    this.setState({ user }, this.updateUser);
  };

  deleteUser = (ev) => {
    ev.preventDefault();

    const user = { ...this.state.user };

    if (!user.password) {
      return this.props.setBanner(
        true,
        'bad',
        'Enter your password to delete account',
      );
    }

    const confirm = window.confirm('Are you sure?');
    if (!confirm) {
      return;
    }

    fetchInstance('/deleteUser', {
      method: 'POST',
      body: JSON.stringify({ user }),
    })
      .then((res) => {
        if (res.ok) {
          if (res.status === 204) {
            this.props.logout();
          }
          return res.json();
        }
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        throw new Error(data.message);
      })
      .catch((err) => this.props.setBanner(true, 'bad', err.message));
  };

  setSelectedFile = (uploader, e) => {
    this.setState({
      file: uploader.uploads[0],
      uploader: uploader,
      bgName: uploader.uploads[0].filename,
    });
  };

  handleFacebookConnect = () => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${config.apiFbUrl}/facebook-auth`;

    const tokenInput = document.createElement('input');
    tokenInput.type = 'hidden';
    tokenInput.name = 'token';
    const token = localStorage.getItem('token');
    tokenInput.value = token;

    form.appendChild(tokenInput);

    // Append the form to the body and submit it
    document.body.appendChild(form);

    form.submit();
  };

  handleGoogleConnect = () => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${config.apiGoogleUrl}/googleAuth`;

    const tokenInput = document.createElement('input');
    tokenInput.type = 'hidden';
    tokenInput.name = 'token';
    const token = localStorage.getItem('token');
    tokenInput.value = token;

    form.appendChild(tokenInput);

    // Append the form to the body and submit it
    document.body.appendChild(form);

    form.submit();
  };

  render() {
    const { user } = this.state;
    const bgDimensions = {
      width: '100%',
      height: '250px',
      objectFit: 'cover',
    };

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            height: 'inherit',
            padding: '2rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ExtendedWrapperFromModalStyles>
            <Title>Edit Profile</Title>
            <FormStyles method="POST" onSubmit={this.updateUser}>
              <div className="group">
                <label>Name</label>
                <input
                  type="text"
                  required
                  name="name"
                  value={user.name}
                  onChange={this.handleInput}
                />
                <span />
              </div>

              <div className="group">
                <label>Email</label>
                <input
                  type="email"
                  required
                  name="email"
                  value={user.email}
                  onChange={this.handleInput}
                />
                <span />
              </div>

              <div className="group">
                <label>Profile Picture</label>
                <FileUploader
                  dimensions={bgDimensions}
                  uploads={[{ path: this.state.user.profileImage }]}
                  onChange={this.setSelectedFile}
                />
              </div>

              <div className="group">
                <label>Current Password</label>
                <input
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={this.handleInput}
                  autoComplete="new-password"
                />
                <span />
              </div>

              <div className="group">
                <div style={{ display: 'flex' }}>
                  <label htmlFor="new__password">New Password</label>
                  <b style={{ marginLeft: 'auto' }}>
                    {`Strength: ${this.state.strength} / 4 ${
                      this.state.user.new__password
                        ? this.state.strength < 2
                          ? '❌'
                          : '✔'
                        : ''
                    }`}
                  </b>
                </div>
                <input
                  type="password"
                  name="new__password"
                  value={user['new__password']}
                  onChange={this.handleInput}
                />
                <span />
              </div>

              {/*<div className="group">*/}
              {/*    <label>Confirm</label>*/}
              {/*    <input type="password" name="new__password__confirm" value={user["new__password__confirm"]} onChange={this.handleInput} />*/}
              {/*    <span />*/}
              {/*</div>*/}

              <div
                className="button-wrapper"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '30px',
                }}
              >
                <button className="warning-button" onClick={this.deleteUser}>
                  Delete account
                </button>
                <button className="confirm-button">Save</button>
              </div>
            </FormStyles>
          </ExtendedWrapperFromModalStyles>
        </div>

        <div
          style={{
            height: 'inherit',
            padding: '2rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ExtendedWrapperFromModalStyles>
            <UserPageStyles>
              <Title>Your Social Accounts</Title>
              <div className="buttonGrouping">
                {this.props.user.googleAccount &&
                this.props.user.googleAccount.name ? (
                  <div className="googleConnected">
                    <button
                      onClick={this.handleGoogleConnect}
                      className="glogo-button"
                    >
                      <img
                        className="glogo"
                        src="/g-logo.png"
                        alt="google logo"
                      />
                      <span>
                        Linked to {this.props.user.googleAccount.name}
                      </span>
                    </button>
                  </div>
                ) : (
                  <div className="googleConnect">
                    <button
                      onClick={this.handleGoogleConnect}
                      className="glogo-button"
                    >
                      <img
                        className="glogo"
                        src="/g-logo.png"
                        alt="google logo"
                      />
                      <span>Connect to Google</span>
                    </button>
                  </div>
                )}
                {this.props.user.googleAccount &&
                this.props.user.googleAccount.name ? (
                  <button
                    title="Removes the linked account but does not revoke given permissions"
                    name="unlink__googleAccount"
                    onClick={this.unlinkSocialAccount}
                    type="button"
                    className="unlink"
                  >
                    ✖
                  </button>
                ) : null}
              </div>
              <div className="buttonGrouping">
                {this.props.user.facebookAccount &&
                this.props.user.facebookAccount.name ? (
                  <div className="facebookConnected">
                    <button
                      onClick={this.handleFacebookConnect}
                      className="flogo-button"
                    >
                      <img
                        className="flogo"
                        src="/flogo-HexRBG-Wht-72.svg"
                        alt="facebook logo"
                      />
                      <span>
                        Linked to {this.props.user.facebookAccount.name}
                      </span>
                    </button>
                  </div>
                ) : (
                  <div className="facebookConnect">
                    <button
                      onClick={this.handleFacebookConnect}
                      className="flogo-button"
                    >
                      <img
                        className="flogo"
                        src="/flogo-HexRBG-Wht-72.svg"
                        alt="facebook logo"
                      />
                      <span>Connect to Facebook</span>
                    </button>
                  </div>
                )}

                {this.props.user.facebookAccount &&
                this.props.user.facebookAccount.name ? (
                  <button
                    title="Removes the linked account but does not revoke given permissions"
                    name="unlink__facebookAccount"
                    className="unlink"
                    onClick={this.unlinkSocialAccount}
                  >
                    ✖
                  </button>
                ) : null}
              </div>
            </UserPageStyles>
          </ExtendedWrapperFromModalStyles>
        </div>
      </div>
    );
  }
}
