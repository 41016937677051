import { defaultTransform } from './Defaults';

const normalizeTransform = (currentTransform) => {
  return {
    ...defaultTransform,
    ...(Boolean(currentTransform?.matrix3d) &&
      currentTransform.matrix3d.length > 0 && {
        matrix3d: currentTransform.matrix3d,
      }),
    ...(Boolean(currentTransform?.translate) &&
      currentTransform.translate.length > 0 && {
        translate: currentTransform.translate,
      }),
    ...(Boolean(currentTransform?.scaleX) && {
      scaleX: currentTransform.scaleX,
    }),
    ...(Boolean(currentTransform?.scaleY) && {
      scaleY: currentTransform.scaleY,
    }),
    ...(Boolean(currentTransform?.rotate) && {
      rotate: currentTransform.rotate,
    }),
  };
};

export default normalizeTransform;
