import { useFormikContext } from 'formik';

export const useFormCard = () => {
  const formik = useFormikContext();
  const { values, handleBlur, touched, errors } = formik;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  return {
    values,
    handleInputChange,
    handleBlur,
    touched,
    errors,
    setFieldValue: formik.setFieldValue,
  };
};
