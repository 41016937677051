import React, { useState, useMemo } from 'react';
import { CloudUpload, Ban } from 'react-bootstrap-icons';
import cx from 'classnames';
import { Dispatcher, Events } from '../../../../../Components/Events';
import FileUploader from '../../../../../Components/FileUploader/FileUploader';
import Button from '../../../../../Components/FormComponents/Button';
import { ButtonVariantEnum } from '../../../../../shared/enums/buttonEnum';
import styles from './BannerBackgroundOptions.module.css';

const MAX_HEIGHT = 250;
const CONTAINER_WIDTH = 220;

const calculateScaledDimensions = (originalWidth, originalHeight) => {
  const scaleFactor = Math.min(1, MAX_HEIGHT / originalHeight);
  const scaledWidth = originalWidth * scaleFactor;

  const finalWidth = Math.min(scaledWidth, CONTAINER_WIDTH);
  const finalHeight = (originalHeight / originalWidth) * finalWidth;

  return {
    width: finalWidth,
    height: finalHeight,
  };
};

const BannerBackgroundOptions = ({
  closeModal,
  resolutions,
  existingBackgrounds,
}) => {
  const [uploads, setUploads] = useState(() => {
    const initialUploads = {};
    if (existingBackgrounds) {
      Object.entries(existingBackgrounds).forEach(([resolution, data]) => {
        if (data.src) {
          initialUploads[resolution] = {
            file: {
              path: data.src,
              name: data.src.split('/').pop(),
            },
            uploader: null,
          };
        }
      });
    }
    return initialUploads;
  });

  const [error, setError] = useState(null);
  const [errorResolutions, setErrorResolutions] = useState([]);
  const [disabledResolutions, setDisabledResolutions] = useState(() => {
    if (existingBackgrounds) {
      return resolutions.filter(
        (res) => existingBackgrounds[res]?.disabled === true,
      );
    }
    return [];
  });

  const handleFileChange = (resolution) => (uploader) => {
    setUploads((prev) => ({
      ...prev,
      [resolution]: {
        file: uploader.uploads[0],
        uploader,
      },
    }));

    setErrorResolutions((currentErrorResolutions) => {
      const updatedErrorResolutions = currentErrorResolutions.filter(
        (res) => res !== resolution,
      );

      if (updatedErrorResolutions.length > 0) {
        setError(
          `Please upload images for all resolutions. Missing: ${updatedErrorResolutions.join(
            ', ',
          )}`,
        );
      } else {
        setError(null);
      }

      return updatedErrorResolutions;
    });
  };

  const handleDisable = (resolution) => {
    setDisabledResolutions((prev) => {
      const isCurrentlyDisabled = prev.includes(resolution);

      if (isCurrentlyDisabled) {
        return prev.filter((res) => res !== resolution);
      } else {
        if (uploads[resolution]) {
          setUploads((prevUploads) => {
            // eslint-disable-next-line no-unused-vars
            const { [resolution]: removed, ...rest } = prevUploads;
            return rest;
          });
        }
        setErrorResolutions((current) =>
          current.filter((res) => res !== resolution),
        );
        return [...prev, resolution];
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const missingResolutions = resolutions
      .filter((res) => !disabledResolutions.includes(res))
      .filter((res) => !uploads[res]);

    if (missingResolutions.length > 0) {
      setErrorResolutions(missingResolutions);
      setError(
        `Please upload images for all enabled resolutions. Missing: ${missingResolutions.join(
          ', ',
        )}`,
      );
      return;
    }

    Object.entries(uploads).forEach(([resolution, data]) => {
      if (!disabledResolutions.includes(resolution)) {
        Dispatcher.dispatch({
          event: Events.CHANGE_BANNER_BACKGROUND,
          payload: {
            resolution,
            file: data.file,
            disabled: false,
          },
        });
      }
    });

    disabledResolutions.forEach((resolution) => {
      Dispatcher.dispatch({
        event: Events.CHANGE_BANNER_BACKGROUND,
        payload: {
          resolution,
          file: null,
          disabled: true,
        },
      });
    });

    closeModal();
  };

  const dimensions = useMemo(() => {
    return resolutions.reduce((acc, resolution) => {
      const [originalWidth, originalHeight] = resolution.split('x').map(Number);
      acc[resolution] = calculateScaledDimensions(
        originalWidth,
        originalHeight,
      );
      return acc;
    }, {});
  }, [resolutions]);

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit}>
        <div className={styles.uploadsGrid}>
          {resolutions.map((resolution) => {
            const { width, height } = dimensions[resolution];
            const hasUpload = uploads[resolution]?.file;
            const hasError = errorResolutions.includes(resolution);
            const isDisabled = disabledResolutions.includes(resolution);

            return (
              <div key={resolution} className={styles.uploadWrapper}>
                <div
                  className={cx(
                    styles.inner,
                    hasError && styles.hasError,
                    isDisabled && styles.isDisabled,
                  )}
                >
                  <div className={styles.resolutionWrapper}>
                    <span className={styles.resolutionLabel}>{resolution}</span>
                    <button
                      type="button"
                      className={styles.disableButton}
                      onClick={() => handleDisable(resolution)}
                    >
                      {isDisabled ? 'Disabled' : 'Disable'} <Ban />
                    </button>
                  </div>
                  <div className={styles.uploadBox} style={{ width, height }}>
                    <div
                      className={cx(styles.uploadContent, {
                        [styles.hasImage]: hasUpload,
                      })}
                    >
                      {hasUpload ? (
                        <img
                          src={uploads[resolution].file.path}
                          alt={`Background ${resolution}`}
                        />
                      ) : (
                        <CloudUpload size={24} />
                      )}
                      <FileUploader
                        className={styles.fileUploader}
                        onChange={handleFileChange(resolution)}
                        inputClassName={styles.fileUploaderInput}
                        uploads={[]}
                        preview="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {error && <div className={styles.error}>{error}</div>}

        <div className={styles.actions}>
          <Button
            type="submit"
            variant={ButtonVariantEnum.PRIMARY}
            className={styles.actionButton}
          >
            Update
          </Button>
          <Button
            type="button"
            variant={ButtonVariantEnum.SECONDARY}
            onClick={closeModal}
            className={styles.actionButton}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BannerBackgroundOptions;
