import React, { useState } from 'react';
import {
  CloudUpload,
  Ban,
  Shuffle,
  Arrow90degUp,
  Gear,
} from 'react-bootstrap-icons';
import cx from 'classnames';
import Button from '../../../../../Components/FormComponents/Button';
import AdvancedSection from '../BannerText/Modal/AdvancedSection';
import FileUploader from '../../../../../Components/FileUploader/FileUploader';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../shared/enums/buttonEnum';
import styles from './ResolutionItem.module.css';

const ResolutionItem = ({
  resolution,
  hasUpload,
  hasError,
  isDisabled,
  isMainResolution,
  showCopyButtons,
  handleDisable,
  handleCopyToAll,
  handleCopyFromMain,
  handleFileChange,
  currentOptions,
  onClassChange,
  onStyleChange,
  isDisabledResolution,
}) => {
  const [toggleCustomCss, setToggleCustomCss] = useState(false);

  return (
    <div className={styles.uploadWrapper}>
      <div
        className={cx(
          styles.inner,
          hasError && styles.hasError,
          isDisabled && styles.isDisabled,
          isMainResolution && styles.mainResolution,
        )}
      >
        <div className={styles.resolutionWrapper}>
          <span className={styles.resolutionLabel}>{resolution}</span>
          <button
            type="button"
            className={styles.disableButton}
            onClick={() => handleDisable(resolution)}
          >
            {isDisabled ? 'Disabled' : 'Disable'} <Ban />
          </button>
        </div>
        <div className={styles.uploadBox}>
          <div
            className={cx(styles.uploadContent, {
              [styles.hasImage]: hasUpload,
            })}
          >
            {hasUpload ? (
              <img src={currentOptions.file.path} alt={`Image ${resolution}`} />
            ) : (
              <CloudUpload size={24} />
            )}
            <FileUploader
              className={styles.fileUploader}
              onChange={handleFileChange(resolution)}
              inputClassName={styles.fileUploaderInput}
              uploads={[]}
              preview="false"
            />
          </div>
        </div>
        {showCopyButtons && (
          <div className={styles.copyButtons}>
            {isMainResolution ? (
              <div className={styles.buttonGroup}>
                <Button
                  type="button"
                  variant={ButtonVariantEnum.TEXT}
                  onClick={handleCopyToAll}
                  disabled={!hasUpload}
                  size={ButtonSizeEnum.XS}
                  leftIcon={<Shuffle />}
                >
                  Copy to all resolutions
                </Button>
                <Button
                  type="button"
                  variant={ButtonVariantEnum.TEXT}
                  onClick={() => setToggleCustomCss(!toggleCustomCss)}
                  size={ButtonSizeEnum.XS}
                  className={cx(
                    styles.settingsButton,
                    toggleCustomCss && styles.active,
                  )}
                >
                  <Gear />
                </Button>
              </div>
            ) : (
              <div className={styles.buttonGroup}>
                <Button
                  type="button"
                  variant={ButtonVariantEnum.TEXT}
                  onClick={() => handleCopyFromMain(resolution)}
                  disabled={!hasUpload}
                  size={ButtonSizeEnum.XS}
                  leftIcon={<Arrow90degUp className={styles.reflectIcon} />}
                >
                  Copy from main
                </Button>
                <Button
                  type="button"
                  variant={ButtonVariantEnum.TEXT}
                  onClick={() => setToggleCustomCss(!toggleCustomCss)}
                  size={ButtonSizeEnum.XS}
                  className={cx(
                    styles.settingsButton,
                    toggleCustomCss && styles.active,
                  )}
                >
                  <Gear />
                </Button>
              </div>
            )}
          </div>
        )}
        {toggleCustomCss && (
          <div className={styles.advancedSection}>
            <AdvancedSection
              isOpen={true}
              onToggle={() => setToggleCustomCss(!toggleCustomCss)}
              isMainResolution={isMainResolution}
              selectedResolution={resolution}
              currentOptions={currentOptions}
              onClassChange={onClassChange(resolution)}
              onStyleChange={onStyleChange(resolution)}
              isDisabled={isDisabledResolution}
              hideButton={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResolutionItem;
