import React, { useState, useEffect } from 'react';
import {
  Images,
  FileImage,
  FileImageFill,
  Image,
  ImageFill,
  ImageAlt,
  CardImage,
} from 'react-bootstrap-icons';
import styles from './LoadingAnimation.module.css';

const generateRandomChars = (count = 50) => {
  const chars = '▀▄█▌▐░▒▓■□▢▣▤▥▦▧▨▩▪▫▬▭▮▯▰▱▲▼◄►◆◇○●◐◑◒◓◔◕';
  return Array.from({ length: count }, () =>
    chars.charAt(Math.floor(Math.random() * chars.length)),
  );
};

const imageIcons = [
  Images,
  FileImage,
  FileImageFill,
  Image,
  ImageFill,
  ImageAlt,
  CardImage,
];

const getRandomIcon = (currentIconIndex, maxLength) => {
  let newIndex;
  do {
    newIndex = Math.floor(Math.random() * maxLength);
  } while (newIndex === currentIconIndex);

  return newIndex;
};

const LoadingAnimation = () => {
  const [phase, setPhase] = useState(1);
  const [chars, setChars] = useState(generateRandomChars());
  const [currentIcon, setCurrentIcon] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setPhase((prev) => (prev >= 2 ? 1 : prev + 1));
      if (phase === 2) {
        setChars(generateRandomChars());
        setCurrentIcon((prev) => getRandomIcon(prev, imageIcons.length));
      }
    }, 2000);

    return () => clearInterval(timer);
  }, [phase]);

  const IconComponent = imageIcons[currentIcon];

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${styles[`phase${phase}`]}`}>
        {phase === 1 && (
          <div className={styles.randomChars}>
            {chars.map((char, index) => (
              <span
                key={index}
                style={{
                  '--delay': `${index * 0.02}s`,
                  '--x': `${Math.random() * 100 - 50}px`,
                  '--y': `${Math.random() * 100 - 50}px`,
                }}
              >
                {char}
              </span>
            ))}
          </div>
        )}
        {phase === 2 && (
          <div className={styles.imageIcon}>
            <IconComponent size={48} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadingAnimation;
