import styled from 'styled-components';

export const StackedContainer = styled.div`
  display: flex;
  align-items: flex-end; /* Align images by their bottom edge */
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StackedImage = styled.img`
  position: absolute;
  left: ${(props) => props.left}%;
  width: ${(props) => props.width}%;
  height: ${(props) => props.height}%;
  top: 50%; /* Vertically center */
  transform: translateY(-50%); /* Adjust for centering */
  z-index: ${(props) => props.zIndex};
  object-fit: cover;
`;
