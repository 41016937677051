import React from 'react';
import PropTypes from 'prop-types';
import NetworkEnum from '../../shared/enums/networkEnum';
import { ReactComponent as FacebookIcon } from '../../images/logos/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../images/logos/instagram.svg';
import { ReactComponent as GoogleAdsIcon } from '../../images/logos/googleAds.svg';
import styles from './NetworkButtons.module.css';

const networkIcons = {
  [NetworkEnum.FACEBOOK]: FacebookIcon,
  [NetworkEnum.INSTAGRAM]: InstagramIcon,
  [NetworkEnum.GOOGLE]: GoogleAdsIcon,
};

const NetworkButtons = ({ selectedNetwork, onChange }) => {
  return (
    <div className={styles.root}>
      <label className={styles.label} htmlFor="network">
        Platform
      </label>
      <div className={styles.inner}>
        {Object.values(NetworkEnum).map((network) => {
          const NetworkIcon = networkIcons[network];
          return (
            <button
              key={network}
              type="button"
              className={`${styles.networkButton} ${
                selectedNetwork === network ? styles.active : ''
              }`}
              onClick={() => onChange(network)}
            >
              <NetworkIcon
                width={20}
                height={20}
                className={styles.networkIcon}
              />
              <span className={styles.networkName}>{network}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

NetworkButtons.propTypes = {
  selectedNetwork: PropTypes.oneOf(Object.values(NetworkEnum)).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NetworkButtons;
