import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.div`
  position: relative;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.black};
  span {
    position: relative;
    width: 100%;
    display: block;

    &:before,
    &:after {
      content: '';
      width: 0;
      height: 3px;
      position: absolute;
      transition: all 0.2s ease;
    }
  }

  label {
    font-size: 1.2em;
    pointer-events: none;
    font-weight: 200;
    position: absolute;
    top: 0;
    left: 8px;
    transition: all 0.2s ease;
  }
  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.lightGrey};
    font-size: 1.2em;
    padding: 0.8rem;
    &:focus {
      outline: none;
      border-bottom: none;
      & ~ label {
        font-size: 0.9em;
        font-weight: 600;
        top: calc(-1.2em - 8px);
      }
    }
  }
`;
const SearchBox = (props) => (
  <StyledInput>
    <input {...props} />
    <label>{props.name}</label>
    <span />
  </StyledInput>
);

export default SearchBox;
