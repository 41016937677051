import React from 'react';

import styles from './Actions.module.css';
import BannerTextButton from './Elements/BannerText/BannerTextButton';
import BannerImageButton from './Elements/BannerImage/BannerImageButton';
import BannerBackgroundButton from './Elements/BannerBackground/BannerBackgroundButton';

const CreatorActions = ({
  bannerSet,
  currentBackgrounds,
  currentElementsLength,
}) => {
  const resolutions =
    bannerSet?.resolutions?.map((res) => `${res.width}x${res.height}`) || [];

  return (
    <div className={styles.root}>
      <BannerTextButton
        resolutions={resolutions}
        currentElementsLength={currentElementsLength}
      />
      <BannerImageButton
        resolutions={resolutions}
        currentElementsLength={currentElementsLength}
      />
      <BannerBackgroundButton
        resolutions={resolutions}
        currentBackgrounds={currentBackgrounds}
      />
    </div>
  );
};

export default CreatorActions;
