import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { CloudUpload, X, Trash } from 'react-bootstrap-icons';
import { nanoid } from 'nanoid';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../shared/enums/buttonEnum';
import Button from '../../../../../Components/FormComponents/Button';
import styles from './ModalBody.module.css';

const BulkUploadModalBody = ({ toastId, allResolutions, handleAddVariant }) => {
  const [uploadedFiles, setUploadedFiles] = useState({});

  const handleFileUpload = (resolution, files) => {
    const fileArray = Array.from(files);
    setUploadedFiles((prev) => ({
      ...prev,
      [resolution]: [...(prev[resolution] || []), ...fileArray],
    }));
  };

  const removeFile = (resolution, index) => {
    setUploadedFiles((prev) => {
      const newFiles = prev[resolution].filter((_, i) => i !== index);
      const newState = { ...prev };
      if (newFiles.length === 0) {
        delete newState[resolution];
      } else {
        newState[resolution] = newFiles;
      }
      return newState;
    });
  };

  const handleConfirm = () => {
    const maxImages = Math.max(
      ...allResolutions.map((res) => uploadedFiles[res]?.length || 0),
    );

    if (maxImages === 0) {
      toast.error('Please upload at least one image');
      return;
    }

    for (let i = 0; i < maxImages; i++) {
      const variations = allResolutions.reduce((acc, resolution) => {
        const files = uploadedFiles[resolution] || [];
        acc[resolution] = {
          src: files[i] ? URL.createObjectURL(files[i]) : '',
          status: files[i] ? 'enabled' : 'disabled',
          id: nanoid(),
        };
        return acc;
      }, {});

      handleAddVariant({ variations, id: nanoid() });
    }

    toast.dismiss(toastId);
    toast.success(`Added ${maxImages} new variation sets`);
  };

  const handleClearResolution = (resolution) => {
    setUploadedFiles((prev) => {
      const newState = { ...prev };
      delete newState[resolution];
      return newState;
    });
  };

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Bulk upload</h3>
      <p className={styles.description}>
        Upload multiple images for each resolution. Images will be grouped by
        their position to create new variations. For example, all first images
        will create the first variation set, all second images will create the
        second variation set, and so on. Resolutions without images will be
        marked as disabled in their respective variations.
      </p>
      <div className={styles.resolutionsContainer}>
        {allResolutions.map((res, index) => (
          <div key={`${res}-${index}`} className={styles.resolution}>
            <div className={styles.resolutionName}>{res}</div>
            <div className={styles.uploadedFiles}>
              {uploadedFiles[res]?.map((file, fileIndex) => (
                <div key={fileIndex} className={styles.uploadedFile}>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${fileIndex}`}
                    className={styles.preview}
                  />
                  <Button
                    size={ButtonSizeEnum.XS}
                    variant={ButtonVariantEnum.TEXT}
                    onClick={() => removeFile(res, fileIndex)}
                    className={styles.removeButton}
                  >
                    <X />
                  </Button>
                </div>
              ))}
            </div>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={(e) => handleFileUpload(res, e.target.files)}
              style={{ display: 'none' }}
              id={`file-input-${res}`}
            />
            <div className={styles.resolutionActions}>
              <Button
                size={ButtonSizeEnum.S}
                variant={ButtonVariantEnum.TEXT}
                leftIcon={<CloudUpload />}
                onClick={() =>
                  document.getElementById(`file-input-${res}`).click()
                }
              >
                Upload images
              </Button>
              {uploadedFiles[res]?.length > 0 && (
                <Button
                  size={ButtonSizeEnum.S}
                  variant={ButtonVariantEnum.TEXT}
                  leftIcon={<Trash />}
                  onClick={() => handleClearResolution(res)}
                  className={styles.clearButton}
                >
                  Clear all
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.actions}>
        <Button
          size={ButtonSizeEnum.S}
          variant={ButtonVariantEnum.PRIMARY}
          onClick={handleConfirm}
        >
          Add variations
        </Button>
        <Button
          size={ButtonSizeEnum.S}
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => toast.dismiss(toastId)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default BulkUploadModalBody;
