import { useCallback, useEffect, useState } from 'react';
import fetchInstance from '../utils/fetchInstance';

/**
 * @typedef {Object} UseFetchOptions
 * @property {boolean} [lazy] - Whether to fetch data on mount
 * @property {`GET` | `POST` | `PUT` | `PATCH` | `DELETE`} [method] - HTTP method
 */

/**
 * @typedef {Object} FetchDataParams
 * @property {Record<string, any>} [queryParams] - Query parameters
 * @property {Record<string, any>} [body] - Request body
 */

/**
 * @typedef {object} FetchDataReturnType
 * @property {function(param?: FetchDataParams)} fetchDataFn - Function for fetching data
 * @property {any} data - Fetched data
 * @property {boolean} isLoading - Whether data is being fetched
 * @property {any} error - Error message
 */

/**
 * Hook for fetching data from an API
 * @param {string} url - URL to fetch data from
 * @param {UseFetchOptions} [options] - Options for fetching data
 * @returns {FetchDataReturnType}
 */

export const useFetch = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * @param {FetchDataParams} [params]
   */
  const fetchDataFn = useCallback(
    async ({ queryParams = {}, data } = {}) => {
      setIsLoading(true);

      try {
        const searchParams = new URLSearchParams(queryParams || {});

        const urlWithParams = `${url}?${decodeURIComponent(searchParams.toString())}`;

        const response = await fetchInstance(urlWithParams, {
          ...options,
          ...(data && { body: JSON.stringify(data) }),
        });

        if (!response.ok) throw new Error(response.statusText);

        const json = await response.json();

        setData(json);

        setError(null);

        return json;
      } catch (error) {
        setError(`${error} Could not Fetch Data `);
      } finally {
        setIsLoading(false);
      }
    },
    [url],
  );

  useEffect(() => {
    if (!options.lazy) {
      fetchDataFn().then();
    }
  }, [fetchDataFn, url]);

  return { fetchDataFn, data, isLoading, error };
};
