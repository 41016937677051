import styled from 'styled-components';

export default styled.form`
  @media (max-width: 1300px) {
    font-size: 1.5rem;
  }
  @media (max-width: 700px) {
    padding: 0;
    border: none;
    grid-template-columns: 1fr;
  }
  fieldset {
    border: none;
    background: white;
    padding: 1rem 1rem;
  }

  input[type='checkbox'] {
    display: none;
    & + label {
      width: 66px;
      height: 2.3rem;
      cursor: pointer;
      border: solid 2px ${(props) => props.theme.lightGrey};
      display: block;
      position: relative;
      transform: skew(-7deg);
      &:before {
        content: '';
        position: absolute;
        border: solid 2px white;
        left: 0;
        width: 32px;
        height: calc(2.3rem - 4px);
        background: ${(props) => props.theme.lightGrey};
        transition: all 0.2s ease-out;
      }
    }
  }
  input[type='checkbox']:checked {
    & + label {
      &:before {
        background: ${(props) => props.theme.lightBlue};
        left: 30px;
      }
    }
  }
  .fancyInput {
    position: relative;
    width: 100%;
    border-bottom: solid 2px ${(props) => props.theme.lightGrey};
    margin-bottom: 2rem;
    label {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 1rem;
      font-size: 1.2em;
      transition: all 0.2s ease-out;
    }
    span: {
      position: relative;
      width: 100%;
      display: block;
    }
    span:before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      height: 3px;
      background: ${(props) => props.theme.lightBlue};
      transition: all 0.2s ease-out;
      width: 0;
    }
    span:after {
      content: '';
      position: absolute;
      bottom: -2px;
      right: 50%;
      height: 3px;
      background: ${(props) => props.theme.lightBlue};
      transition: all 0.2s ease-out;
      width: 0;
    }
    input:focus ~ label,
    input:valid ~ label {
      top: -1rem;
      font-size: 1em;
    }
    input:focus ~ span:before {
      width: 50%;
    }
    input:focus ~ span:after {
      width: 50%;
    }
  }

  input[type='date'] {
    border: solid 2px ${(props) => props.theme.lightGrey};
  }
  input,
  textarea,
  select {
    padding: 1rem 1rem;
    font-size: 1em;
    font-family: 'Open Sans';
    border: none;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }

  textarea {
    border: solid 2px ${(props) => props.theme.lightGrey};
    &:active,
    &:focus {
      outline: solid 2px ${(props) => props.theme.lightBlue};
    }
  }
  a,
  button {
    font-family: 'Open Sans';
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    box-shadow: none;
    padding: 1rem 2rem;
    border: none;
    color: ${(props) => props.theme.black};
    border: solid 2px ${(props) => props.theme.lightGrey};
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    transition: all 0.2s ease-out;
    &:hover,
    &:active {
      /* color: white; */
      background: ${(props) => props.theme.lightGrey};
      border: solid 2px white;
    }
  }

  button[type='submit'],
  input[type='submit'] {
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;

    &:disabled {
      background: ${(props) => props.theme.lightGrey};
    }
    &:enabled {
      background: ${(props) => props.theme.lightBlue};
    }
    &:hover,
    &:active {
      background: ${(props) => props.theme.lightBlue};
      &:disabled {
        background: ${(props) => props.theme.lightGrey};
      }
    }
  }
`;
