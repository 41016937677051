import React, { useRef, useEffect } from 'react';
import Moveable from 'react-moveable';
import ParserStyle from 'style-to-object';

const BannerImageItem = (props) => {
  const moveableRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(props.id);
      if (element) {
        if (moveableRef.current) {
          moveableRef.current.updateRect();
        }
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [props.id, props.currentResolution]);

  const {
    id,
    options,
    locked,
    container,
    offsetH,
    offsetW,
    disabledElementActions,
    onClick,
    handleDrag,
    handleRotate,
    handleScale,
    handleResize,
    handleEnd,
  } = props;

  const elDefaultStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    ...ParserStyle(options.style),
  };

  const elDefaultClass = `${id}`;
  const elClass = `${elDefaultClass} ${options.class}`;

  if (disabledElementActions) {
    return (
      <div id={id} className="moveable banner-item image-item">
        <img
          id={`${id}_el`}
          className={elClass}
          style={elDefaultStyle}
          src={options.src || '/transparent.gif'}
          alt=""
        />
      </div>
    );
  }

  return (
    <>
      {props.target && (
        <Moveable
          ref={moveableRef}
          key={id}
          target={props.target}
          container={container}
          snappable={true}
          snapCenter={true}
          bounds={{ left: 0, top: 0, bottom: offsetH, right: offsetW }}
          draggable={!locked}
          scalable={!locked}
          resizable={!locked}
          rotatable={!locked}
          origin={false}
          throttleDrag={1}
          throttleRotate={0.2}
          throttleResize={1}
          throttleScale={0.01}
          onDrag={handleDrag}
          onRotate={handleRotate}
          onResize={handleResize}
          onScale={handleScale}
          onDragEnd={handleEnd}
          onResizeEnd={handleEnd}
          onRotateEnd={handleEnd}
          onScaleEnd={handleEnd}
          keepRatio={true}
        />
      )}

      <div
        id={id}
        onClick={onClick}
        className="moveable banner-item image-item"
      >
        <img
          id={`${id}_el`}
          className={elClass}
          style={elDefaultStyle}
          src={options.src}
          alt=""
        />
      </div>
    </>
  );
};

export default BannerImageItem;
