import { useCallback } from 'react';
import fetchInstance from '../utils/fetchInstance';

const useFileUpload = () => {
  const uploadFile = useCallback(async (file, options = {}) => {
    const { persistent = null, multiple = false, original = false } = options;

    const form = new FormData();
    form.append('files', file);
    form.append('persistent', persistent);
    form.append('multiple', multiple);
    form.append('original', original);

    const response = await fetchInstance('/store', {
      method: 'POST',
      body: form,
    });

    const result = await response.json();

    if (!result.files || result.files.length === 0) {
      throw new Error('No files uploaded');
    }

    return result.files;
  }, []);

  return { uploadFile };
};

export default useFileUpload;
