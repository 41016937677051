import React from 'react';
import { Upload } from 'react-bootstrap-icons';
import cx from 'classnames';

import Button from '../../../../../../Components/FormComponents/Button';
import ColorPickerBox from '../ColorPickerBox';
import Input from '../../../../../../Components/FormComponents/Input';
import Select from '../../../../../../Components/FormComponents/Select';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../../shared/enums/buttonEnum';
import styles from './ContentSection.module.css';

const ContentSection = ({
  currentOptions,
  fontsList,
  onFontChange,
  onSizeChange,
  colorProps,
  content,
  onChange,
  error,
  isDisabled,
  textTags,
  onTagChange,
  onPositionChange,
  textPositions,
  onUploadFont,
  onLineHeightChange,
}) => {
  const tags = textTags.map((tag) => ({
    label: tag,
    value: tag,
  }));

  const positions = textPositions.map((position) => ({
    label: position,
    value: position.toLowerCase(),
  }));

  const fonts = [
    ...Object.keys(fontsList).map((font) => ({
      label: font,
      value: font,
    })),
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      onUploadFont(file);
    }
  };

  return (
    <div className={cx(styles.root, isDisabled && styles.disabled)}>
      <Input
        label="Content"
        value={content || ''}
        onChange={onChange}
        name="content"
        required
        isTextarea
        textareaProps={{ rows: 2 }}
        error={error}
      />
      <div className={styles.sectionTitle}>Styling</div>
      <div className={styles.fontSection}>
        <Select
          label="Font family"
          labelAction={
            <>
              <Button
                onClick={() => document.getElementById('font-upload').click()}
                variant={ButtonVariantEnum.TEXT}
                size={ButtonSizeEnum.S}
              >
                <Upload />
              </Button>
              <input
                id="font-upload"
                type="file"
                accept=".ttf,.otf,.woff,.woff2"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
            </>
          }
          options={fonts}
          value={currentOptions?.font || ''}
          onChange={onFontChange}
          name="font"
        />
        <Input
          label="Font size"
          value={currentOptions?.size || '20px'}
          onChange={onSizeChange}
          name="size"
        />
        <Input
          label="Line height"
          value={currentOptions?.lineHeight || '1.1'}
          onChange={onLineHeightChange}
          name="lineHeight"
        />
        <ColorPickerBox {...colorProps} />
      </div>
      <div className={styles.doubleSection}>
        <Select
          label="Text tag"
          options={tags}
          value={currentOptions?.tag || ''}
          onChange={onTagChange}
          name="tag"
        />
        <Select
          label="Text position"
          options={positions}
          value={currentOptions?.position || ''}
          onChange={onPositionChange}
          name="position"
        />
      </div>
    </div>
  );
};

export default ContentSection;
