import styled from 'styled-components';

export default styled.div`
  box-shadow:
    0 4px 15px rgb(0 0 0 / 7%),
    0 1px 3px rgb(0 0 0 / 7%);
  width: 480px;
  margin: 0 auto;
  min-width: 300px;
  padding: 3rem;
  text-align: left;
  border-radius: 10px;
  background-color: #fff;
  border: ${(props) => props.theme.border};
  border-radius: ${(props) => props.theme.borderRadius};
  margin-top: 10px;

  h1 {
    text-align: left;
  }
`;
