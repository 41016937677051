import toast from 'react-hot-toast';
import { useState } from 'react';
import fetchInstance from '../../../../utils/fetchInstance';
import { useGalleryContext } from '../../Gallery.context';

export const useGroupManagementFormApi = () => {
  const { campaignId, selectedBannersIds, bannersList, refetchBannerGroups } =
    useGalleryContext();

  const [isAddSelectedLoading, setIsAddSelectedLoading] = useState(false);
  const [isRemoveSelectedLoading, setIsRemoveSelectedLoading] = useState(false);
  const [isDownloadSelectedLoading, setIsDownloadSelectedLoading] =
    useState(false);

  const handleAddSelected = async (event, selectedGroup) => {
    event.stopPropagation();

    if (!selectedBannersIds.length) {
      toast.error(`Please select at least one banner to add`);
      return;
    }

    if (!selectedGroup?.value || isAddSelectedLoading) {
      return;
    }

    const currentGroupBannersIds = selectedGroup?.rawGroup?.banners || [];

    const updateBannerGroupData = {
      banners: [...new Set(currentGroupBannersIds.concat(selectedBannersIds))],
    };

    if (updateBannerGroupData.banners.length) {
      setIsAddSelectedLoading(true);

      try {
        await fetchInstance(`/updateBannerGroup/${selectedGroup?.value}`, {
          method: 'PUT',
          body: JSON.stringify({ bannerGroup: updateBannerGroupData }),
        });

        refetchBannerGroups();

        toast.success(
          `${selectedBannersIds.length} banner(s) successfully added to ${selectedGroup.label}`,
        );
      } catch (err) {
        toast.error(err.message);
      } finally {
        setIsAddSelectedLoading(false);
      }
    } else {
      toast.error('Selected banners are already in this group.');
    }
  };

  const handleRemoveSelected = async (event, selectedGroup) => {
    event.stopPropagation();

    if (!selectedBannersIds.length) {
      toast.error(`Please select at least one banner to remove`);
      return;
    }

    if (!selectedGroup?.value || isRemoveSelectedLoading) {
      return;
    }

    setIsRemoveSelectedLoading(true);

    const updateBannerGroupData = {
      banners:
        selectedGroup?.rawGroup?.banners?.filter(
          (bannerId) => !selectedBannersIds.includes(bannerId),
        ) || [],
    };

    try {
      await fetchInstance(`/updateBannerGroup/${selectedGroup?.value}`, {
        method: 'PUT',
        body: JSON.stringify({ bannerGroup: updateBannerGroupData }),
      });

      refetchBannerGroups();

      toast.success(
        `${selectedBannersIds.length} banner(s) successfully removed from ${selectedGroup.label}`,
      );
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsRemoveSelectedLoading(false);
    }
  };

  const handleDownloadSelected = async (event) => {
    event.stopPropagation();

    if (!selectedBannersIds.length) {
      toast.error(`Please select at least one banner to download`);
      return;
    }

    if (isDownloadSelectedLoading) {
      return;
    }

    setIsDownloadSelectedLoading(true);

    const imagesToDownload = bannersList
      .filter((banner) => selectedBannersIds.includes(banner._id))
      .flatMap((banner) =>
        Object.values(banner.createdImgs).map((imageObj) => imageObj.src),
      );

    try {
      const response = await fetchInstance('/pls4toarchive', {
        method: 'POST',
        body: JSON.stringify({
          projectID: campaignId,
          imagesToArchive: imagesToDownload,
        }),
      });

      if (!response.ok) {
        const responseJson = await response.json();

        toast.error(responseJson.message);
        return;
      }

      const blobFile = await response.blob();

      const file = window.URL.createObjectURL(blobFile);
      const link = document.createElement('a');
      link.href = file;
      link.download = 'archive.zip';
      link.click();

      toast.success('Selected banners downloaded successfully');
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsDownloadSelectedLoading(false);
    }
  };

  return {
    handleAddSelected,
    handleRemoveSelected,
    handleDownloadSelected,
  };
};
