import styled from 'styled-components';

export const ClientDetailsStyle = styled.div`
  .rule-wrapper {
    display: flex;
    align-items: start;
    margin-bottom: 10px;

    .rule-key {
      line-height: 22px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.5px;
    }

    .rule-value {
      margin-left: 10px;
      flex-grow: 1;
      line-height: 20px;
    }

    .button-delete {
      margin-top: -5px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
  }

  select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.7rem center;
    background-size: 1em;
    padding-right: calc(0.7rem + 1em + 4px);
  }
`;
