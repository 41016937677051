import React, { useMemo } from 'react';
import { Stack } from 'react-bootstrap-icons';
import cx from 'classnames';
import FieldWrapper from '../FieldWrapper';
import styles from './TextSet.module.css';
import OptionsList from './OptionsList';

const TextSet = ({
  variations,
  notEditable,
  label,
  onRemove,
  setId,
  onPreview,
  isActive,
  onEdit,
}) => {
  const onPreviewClick = () => {
    onPreview(setId);
  };

  const onVariantRemove = () => {
    onRemove(setId, variations);
  };

  const onVariantEdit = () => {
    onEdit({ variantId: setId, variations });
  };

  const differences = useMemo(() => {
    const resolutions = Object.entries(variations);
    if (resolutions.length === 0) return {};

    const [, baseData] = resolutions[0];
    const diff = {};

    resolutions.slice(1).forEach(([resolution, data]) => {
      const resolutionDiff = {};

      if (data.status !== baseData.status) {
        resolutionDiff.status = data.status;
      }

      Object.entries(data.options).forEach(([key, value]) => {
        if (value !== baseData.options[key]) {
          resolutionDiff[key] = value;
        }
      });

      if (Object.keys(resolutionDiff).length > 0) {
        diff[resolution] = resolutionDiff;
      }
    });

    return diff;
  }, [variations]);

  return (
    <div className={styles.root}>
      <FieldWrapper
        label={label}
        whiteBox
        onPreviewClick={onPreview && onPreviewClick}
        onEdit={!notEditable && onEdit && onVariantEdit}
        onRemove={!notEditable && onVariantRemove}
        isActive={isActive}
      >
        <div className={cx(styles.resolution, styles.base)}>
          <div className={styles.leftContent}>
            <div className={styles.verticalBar} />
            <Stack className={styles.stackIcon} />
          </div>
          <div className={styles.rightContent}>
            {variations[Object.keys(variations)[0]]?.options?.content && (
              <div className={styles.textContent}>
                {variations[Object.keys(variations)[0]]?.options?.content}
              </div>
            )}
            <OptionsList
              options={variations[Object.keys(variations)[0]].options}
            />
          </div>
        </div>
        {Object.entries(differences).map(([resolution, diff]) => (
          <div key={resolution} className={styles.resolution}>
            <div className={styles.leftContent}>
              <div className={styles.verticalBar} />
              <div className={styles.halfRoundBox} />
            </div>
            <div className={styles.rightContent}>
              <h4 className={styles.resolutionLabel}>{resolution}</h4>
              {diff.content && (
                <div className={styles.textContent}>{diff.content}</div>
              )}
              <OptionsList options={diff} />
            </div>
          </div>
        ))}
      </FieldWrapper>
    </div>
  );
};

export default TextSet;
