import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import Select from '../../../Components/FormComponents/Select';
import { useDestinationCard as useFacebookDestinationCard } from '../hooks/facebook/useDestinationCard';
import { useDestinationCard as useGoogleDestinationCard } from '../hooks/google/useDestinationCard';
import styles from '../styles/Cards.module.scss';

const FACEBOOK_INPUTS = [
  {
    name: 'destination.account',
    label: 'Ad account',
    placeholder: 'Select Ad Account',
  },
  {
    name: 'destination.campaign',
    label: 'Campaign',
    placeholder: 'Select Campaign',
  },
  {
    name: 'destination.adSet',
    label: 'Ad Set',
    placeholder: 'Select Ad Set',
  },
  {
    name: 'destination.page',
    label: 'Page',
    placeholder: 'Select Page',
  },
];

const GOOGLE_INPUTS = [
  {
    name: 'destination.account',
    label: 'Ad account',
    placeholder: 'Select Ad Account',
  },
  {
    name: 'destination.campaign',
    label: 'Campaign',
    placeholder: 'Select Campaign',
  },
  {
    name: 'destination.adGroup',
    label: 'Ad Group',
    placeholder: 'Select Ad Group',
  },
];

const DestinationCard = ({ disabled, onComplete, platform = 'google' }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const isFacebook = platform === 'facebook';

  const {
    loading,
    handleAccountChange,
    handleCampaignChange,
    handleAdSetChange,
    handleAdGroupChange,
    handlePageChange,
    accountOptions,
    campaignOptions,
    adSetOptions,
    adGroupOptions,
    pageOptions,
  } = isFacebook
    ? useFacebookDestinationCard({ setFieldValue })
    : useGoogleDestinationCard({ setFieldValue });

  const handleChange = (name, value) => {
    if (!value) return;

    const field = name.split('.')[1];

    switch (field) {
      case 'account':
        handleAccountChange(value);
        break;
      case 'campaign':
        handleCampaignChange(value);
        break;
      case 'adGroup':
        handleAdGroupChange(value);
        break;
      case 'adSet':
        handleAdSetChange(value);
        break;
      case 'page':
        handlePageChange(value);
        break;
      default:
        break;
    }
  };

  const getOptions = (name) => {
    switch (name) {
      case 'destination.account':
        return accountOptions;
      case 'destination.campaign':
        return campaignOptions;
      case 'destination.adSet':
        return adSetOptions;
      case 'destination.adGroup':
        return adGroupOptions;
      case 'destination.page':
        return pageOptions;
      default:
        return [];
    }
  };

  const getValue = (values, input, isFacebook) => {
    const field = input.name.split('.')[1];
    const value = values?.destination?.[field];

    if (!value) return '';

    if (!isFacebook) {
      switch (field) {
        case 'account':
          return value.customerId || '';
        case 'campaign':
        case 'adGroup':
          return value.id || '';
        default:
          return '';
      }
    }

    return value.id || '';
  };

  useEffect(() => {
    let isSubscribed = true;

    const isComplete = isFacebook
      ? values?.destination?.account &&
        values?.destination?.campaign &&
        values?.destination?.adSet &&
        values?.destination?.page
      : values?.destination?.account &&
        values?.destination?.campaign &&
        values?.destination?.adGroup;

    if (isComplete && isSubscribed) {
      const timeoutId = setTimeout(() => {
        if (isSubscribed) {
          onComplete(values.destination);
        }
      }, 100);

      return () => {
        clearTimeout(timeoutId);
        isSubscribed = false;
      };
    }

    return () => {
      isSubscribed = false;
    };
  }, [
    values?.destination?.account,
    values?.destination?.campaign,
    values?.destination?.adGroup,
    values?.destination?.adSet,
    values?.destination?.page,
    isFacebook,
    onComplete,
  ]);

  return (
    <div className={styles.card}>
      <h2 className={styles.title}>Destination</h2>
      <div className={styles.content}>
        {(isFacebook ? FACEBOOK_INPUTS : GOOGLE_INPUTS).map((input) => (
          <Select
            key={input.name}
            name={input.name}
            label={input.label}
            placeholder={input.placeholder}
            value={getValue(values, input, isFacebook)}
            onChange={(e) => handleChange(input.name, e.target.value)}
            options={getOptions(input.name)}
            disabled={disabled || loading}
            error={
              touched?.destination?.[input.name.split('.')[1]] &&
              errors?.destination?.[input.name.split('.')[1]]
            }
          />
        ))}
      </div>
    </div>
  );
};

export default DestinationCard;
