import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function EditableSelect({ options, initialValue, name, onChange }) {
  const [value, setValue] = useState(initialValue || '');
  const [menuOptions, setMenuOptions] = useState(options);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    // Ensure that the initialValue is part of the options if it's not already
    if (initialValue && !menuOptions.includes(initialValue)) {
      setMenuOptions([...options, initialValue]);
    }
    // Set the initial value as the selected value
    setValue(initialValue);
  }, [initialValue, menuOptions]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    onChange(name, newInputValue);
  };

  const handleChange = (event, newValue) => {
    const v = newValue;
    if (v && !menuOptions.includes(v)) {
      setMenuOptions([...options, v]);
    }
    setValue(v);
    onChange(name, v);
  };

  return (
    <Autocomplete
      style={{ width: '100%' }}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={menuOptions}
      freeSolo
      name={name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select or type a CSS property"
          variant="standard"
          fullWidth
        />
      )}
    />
  );
}

export default EditableSelect;
