import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Input.module.css';

/**
 * @typedef {Object} TextareaProps
 * @property {number} [rows=3] - Number of visible text lines
 * @property {boolean} [resizable=true] - Whether textarea can be resized
 */

/**
 * @typedef {Object} InputProps
 * @property {string} [type='text'] - Input type (text, number, email, etc.)
 * @property {string} [name] - Input name attribute and id
 * @property {string|number} [value] - Input value
 * @property {(event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => void} [onChange] - Change event handler
 * @property {string} [placeholder] - Input placeholder text
 * @property {string} [label] - Label text for the input
 * @property {boolean} [required=false] - Whether the input is required
 * @property {string} [className] - Additional CSS class names
 * @property {string} [error] - Error message to display
 * @property {boolean} [disabled=false] - Whether the input is disabled
 * @property {boolean} [isTextarea=false] - Whether to render a textarea instead of input
 * @property {TextareaProps} [textareaProps] - Additional props for textarea
 */

/**
 * Reusable Input component with label and error handling
 * @param {InputProps} props
 * @returns {JSX.Element}
 */
const Input = ({
  type = 'text',
  name,
  value,
  onChange,
  placeholder,
  label,
  required = false,
  className = '',
  error,
  disabled = false,
  isTextarea = false,
  textareaProps = {},
}) => {
  /**
   * Combines root class with any additional classes
   * @type {string}
   */
  const rootClassName = cx(styles.root, className);

  /**
   * Combines input class with error class if present
   * @type {string}
   */
  const inputClassName = cx(
    styles.input,
    isTextarea && styles.textarea,
    error && styles.error,
  );

  /**
   * Common props for both input and textarea
   * @type {Object}
   */
  const commonProps = {
    id: name,
    name,
    value,
    onChange,
    placeholder,
    required,
    disabled,
    className: inputClassName,
  };

  /**
   * Get textarea specific styles
   * @type {Object}
   */
  const textareaStyles = {
    resize: textareaProps.resizable === false ? 'none' : 'vertical',
  };

  return (
    <div className={rootClassName}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {required && <span className={styles.requiredIndicator}>*</span>}
        </label>
      )}
      {isTextarea && (
        <textarea
          {...commonProps}
          rows={textareaProps.rows || 3}
          style={textareaStyles}
        />
      )}
      {!isTextarea && <input {...commonProps} type={type} />}
      {Boolean(error?.trim()) && (
        <span className={styles.errorMessage}>{error}</span>
      )}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  isTextarea: PropTypes.bool,
  textareaProps: PropTypes.shape({
    rows: PropTypes.number,
    resizable: PropTypes.bool,
  }),
};

export default Input;
