import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Switch.module.scss';

/**
 * @typedef {Object} SwitchProps
 * @property {boolean} checked - Whether the switch is checked
 * @property {(checked: boolean) => void} [onChange] - Click handler function
 * @property {string} [className] - Additional CSS class names
 * @property {boolean} [disabled=false] - Whether the button is disabled
 */

/**
 * Button component with various sizes, variants and icon support
 * @param {SwitchProps} props
 * @returns {Element}
 */
const Switch = ({ checked, onChange, className, disabled = false }) => {
  const [checkedState, setCheckedState] = useState(checked);

  return (
    <div className={classNames(styles.container, className)}>
      <label>
        <input
          disabled={disabled}
          type="checkbox"
          checked={checkedState}
          onChange={() => {
            setCheckedState(!checkedState);
            onChange(!checkedState);
          }}
          className={styles.input}
        />
        <span className={styles.label} />
      </label>
    </div>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

/**
 * @type {SwitchProps} defaultProps
 */
Switch.defaultProps = {
  label: '',
  checked: false,
  onChange: () => {},
  className: '',
  disabled: false,
};

export default Switch;
