import React from 'react';
import { useMixer } from '../MixerContext';
import { DefaultElements, MixerElement } from '../Consts/DefaultElements';
import BannerElementType from '../../../../shared/enums/bannerElementType';
import styles from './ElementOptions.module.css';
import RootOptions from './FieldSets/Root';
import BackgroundSets from './FieldSets/BackgroundSets';
import ImageElementOptions from './FieldSets/ImageElementOptions';
import TextElementOptions from './FieldSets/TextElementOptions';
import FinalOverview from './FinalOverview';

const ElementOptions = () => {
  const { selectedElement } = useMixer();

  return (
    <div className={styles.root}>
      {selectedElement.id === DefaultElements.root.id && <RootOptions />}
      {selectedElement.id === DefaultElements.background.id && (
        <BackgroundSets />
      )}
      {selectedElement.type === BannerElementType.IMAGE && (
        <ImageElementOptions id={selectedElement.id} />
      )}
      {selectedElement.type === BannerElementType.TEXT && (
        <TextElementOptions id={selectedElement.id} />
      )}
      {selectedElement.id === MixerElement.id && <FinalOverview />}
    </div>
  );
};

export default ElementOptions;
