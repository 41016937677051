import React from 'react';
import PropTypes from 'prop-types';
import config from '../../../../config';
import styles from './DebugInfo.module.css';

const DebugInfo = ({ identifier }) => {
  return (
    <div className={styles.root}>
      <p>Version: {config.bannerCreatorVersion}</p>
      <p>Identifier: {identifier}</p>
    </div>
  );
};

DebugInfo.propTypes = {
  identifier: PropTypes.string.isRequired,
};

export default DebugInfo;
