import React, { useMemo } from 'react';
import { LayoutWtf, Collection } from 'react-bootstrap-icons';
import { useMixer } from '../MixerContext';
import Button from '../../../../Components/FormComponents/Button';
import { ButtonVariantEnum } from '../../../../shared/enums/buttonEnum';
import styles from './FinalOverview.module.css';

const FinalOverview = () => {
  const {
    bannerSet,
    customBackgroundVariants,
    elementVariants,
    rootOptions,
    handleStartMixing,
  } = useMixer();

  const combinationsPerResolution = useMemo(() => {
    const baseBackgroundCount = 1 + customBackgroundVariants.length;

    const elementCombinations = bannerSet.banners[0].elements.reduce(
      (count, baseElement) => {
        const variantsCount = elementVariants.filter(
          (v) => v.baseId === baseElement._id,
        ).length;
        return count * (variantsCount + 1);
      },
      1,
    );

    const rootOptionsCombinations = Object.entries(rootOptions)
      .filter(([key]) => !['id', '_id'].includes(key))
      .reduce((count, [key, values]) => {
        if (!Array.isArray(values)) return count;
        if (['borderStyle', 'borderColor', 'borderThickness'].includes(key)) {
          return count;
        }
        return count * (values.length || 1);
      }, 1);

    let borderCombinations = 1;
    if (
      rootOptions.borderStyle?.length &&
      rootOptions.borderColor?.length &&
      rootOptions.borderThickness?.length
    ) {
      borderCombinations =
        rootOptions.borderStyle.length *
        rootOptions.borderColor.length *
        rootOptions.borderThickness.length;
    }

    return (
      baseBackgroundCount *
      elementCombinations *
      rootOptionsCombinations *
      borderCombinations
    );
  }, [bannerSet, customBackgroundVariants, elementVariants, rootOptions]);

  const totalCombinations =
    combinationsPerResolution * bannerSet.resolutions.length;

  const infoArray = [
    {
      label: 'Total images',
      value: totalCombinations,
      icon: <LayoutWtf />,
    },
    {
      label: 'Variations',
      value: combinationsPerResolution,
      icon: <Collection />,
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.title}>Ready to mix</div>
      <div className={styles.infoArray}>
        {infoArray.map((info) => (
          <div key={info.label} className={styles.infoElement}>
            {info.icon}
            {info.value} {info.label}
          </div>
        ))}
      </div>
      <Button variant={ButtonVariantEnum.PRIMARY} onClick={handleStartMixing}>
        Start mixing
      </Button>
    </div>
  );
};

export default FinalOverview;
