import React from 'react';
import toast from 'react-hot-toast';
import cx from 'classnames';
import Button from '../../Components/FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/enums/buttonEnum';
import styles from './ConfirmModal.module.css';

const ConfirmModalBody = ({
  title,
  message,
  subMessage,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  toastId,
  isDanger,
}) => {
  const handleConfirm = () => {
    toast.dismiss(toastId);
    onConfirm();
  };

  return (
    <div className={styles.confirmModal}>
      {title && <h3 className={styles.title}>{title}</h3>}
      {message && <p className={styles.message}>{message}</p>}
      {subMessage && <small className={styles.subMessage}>{subMessage}</small>}
      <div className={styles.actions}>
        <Button
          size={ButtonSizeEnum.S}
          className={cx(isDanger && styles.dangerButton)}
          variant={ButtonVariantEnum.PRIMARY}
          onClick={handleConfirm}
        >
          {confirmText}
        </Button>
        <Button
          size={ButtonSizeEnum.S}
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => toast.dismiss(toastId)}
        >
          {cancelText}
        </Button>
      </div>
    </div>
  );
};

// Funcție helper pentru a afișa modalul
export const ConfirmModal = ({
  title,
  message,
  subMessage,
  confirmText,
  cancelText,
  onConfirm,
  isDanger = false,
}) => {
  toast(
    (t) => (
      <ConfirmModalBody
        title={title}
        message={message}
        subMessage={subMessage}
        confirmText={confirmText}
        cancelText={cancelText}
        onConfirm={onConfirm}
        toastId={t.id}
        isDanger={isDanger}
      />
    ),
    {
      duration: Infinity,
      position: 'top-center',
    },
  );
};

export default ConfirmModal;
