export const ButtonSizeEnum = {
  XS: 'XS',
  S: 'S',
  M: 'M',
  L: 'L',
};

export const ButtonVariantEnum = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

export const ButtonTypeEnum = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
};
