import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Search, FolderPlus } from 'react-bootstrap-icons';
import { FolderFill } from 'react-bootstrap-icons';
import { IoArrowBack } from 'react-icons/io5';
import DefaultLayout from '../../Components/DefaultLayout';
import Title from '../../Components/styles/TitleStyle';
import FlexContainer from '../../Components/styles/FlexContainer';
import CampaignCard from '../Campaign/CampaignCard';
import Pagination from '../../ComponentsV2/Pagination/Pagination';
import { GeneralModalContext } from '../../Components/GeneralModal';
import { ClientSearchBarStyle } from '../../Components/styles/ClientSearchBarStyle';
import fetchInstance from '../../utils/fetchInstance';
import DeleteModal from '../../Components/Modals/DeleteModal';
import CampaignDetails from '../../Components/Campaigns/CampaignDetails';
import GridContainer from '../../Components/styles/GridContainer';
import { useDebounce } from '../../hooks/useDebounce';
import config from '../../config';
import styles from './GroupMain.module.scss';

export const CampaignSearchBar = ({
  handleSearchCampaignInput,
  openCampaignModal,
  campaign = null,
  isCampaignGroup = false,
}) => {
  return (
    <ClientSearchBarStyle style={{ textAlign: 'right', gap: '10px' }}>
      <span className="search-client-wrapper">
        <Search />
        <input
          onChange={(e) => handleSearchCampaignInput(e)}
          id="search-client"
          type="text"
          placeholder="Search Group"
        />
      </span>
      <div className="filter-add-client">
        <button
          className="confirm-button"
          onClick={() => openCampaignModal(campaign, true)}
        >
          <FolderPlus />
          New Campaign
        </button>
      </div>
    </ClientSearchBarStyle>
  );
};

const GroupsMain = ({ match, setBanner, extractCSRF, user }) => {
  const [group, setGroup] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [visibleCampaigns, setVisibleCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [limit] = useState(config.ITEMS_PER_PAGE);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [modalType, setModalType] = useState(null);

  const { handleShowModal, handleCloseModal, showModal } =
    useContext(GeneralModalContext);
  const groupId = match.params.id;

  const fetchGroupDetails = async () => {
    try {
      const res = await fetchInstance(`/getGroup/${groupId}`);
      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();
      if (data.error) throw new Error(data.message);
      setGroup(data);
      return data;
    } catch (err) {
      setBanner(true, 'bad', err.message);
      throw err;
    }
  };

  const fetchGroupCampaigns = async (page = 1, groupData, name = '') => {
    const currentGroup = groupData || group;
    if (!currentGroup?.client?._id) {
      console.error('No client ID available');
      return;
    }

    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: page.toString(),
        limit: limit.toString(),
        groupId: groupId,
        clientId: currentGroup.client._id,
        ...(name && { name }),
      });

      const res = await fetchInstance(`/getCampaigns?${queryParams}`);
      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();
      if (data.error) throw new Error(data.message);

      setCampaigns(data.campaigns);
      setVisibleCampaigns(data.campaigns);
      setTotalPages(data.totalPages);
      setTotalCampaigns(data.totalCampaigns);
      setCurrentPage(page);
    } catch (err) {
      setBanner(true, 'bad', err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      try {
        const groupData = await fetchGroupDetails();
        await fetchGroupCampaigns(1, groupData);
      } catch (err) {
        console.error('Error initializing data:', err);
      }
    };

    if (groupId) {
      initializeData();
    }
  }, [groupId]);

  const debouncedFetchCampaigns = useDebounce((searchTerm) => {
    fetchGroupCampaigns(1, group, searchTerm);
  }, 500);

  const handleSearchCampaignInput = (ev) => {
    const searchTerm = ev.target.value.toLowerCase();
    setCurrentPage(1);
    debouncedFetchCampaigns(searchTerm);
  };

  const handlePageChange = (page) => {
    fetchGroupCampaigns(page, group);
  };

  const openShareModal = (campaign) => {
    setSelectedCampaign(campaign);
    setModalType('share');
    handleShowModal();
  };

  const openEditModal = (campaign) => {
    setSelectedCampaign(campaign);
    setModalType('campaign-modal');
    handleShowModal();
  };

  const openDeleteModal = (campaign) => {
    setSelectedCampaign(campaign);
    setModalType('delete');
    handleShowModal();
  };

  const openNotesModal = (campaign) => {
    setSelectedCampaign(campaign);
    setModalType('show-notes');
    handleShowModal();
  };

  const deleteCampaign = (ev) => {
    const campaign = { ...selectedCampaign };
    fetchInstance(`/deleteCampaign/${campaign._id}`, {
      method: 'delete',
      body: JSON.stringify({ campaign }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.error) throw new Error(data.message);

        const newCampaigns = campaigns.filter((c) => c._id !== campaign._id);
        setCampaigns(newCampaigns);
        setVisibleCampaigns(newCampaigns);
        setSelectedCampaign(null);
        handleCloseModal();
        setBanner(true, 'good', 'Campaign deleted successfully');
      })
      .catch((err) => setBanner(true, 'bad', err.message));
  };

  const updateCampaign = async (updatedCampaign) => {
    try {
      const { _id, ...campaignData } = updatedCampaign;

      if (campaignData.startDate) {
        campaignData.startDate = new Date(campaignData.startDate).toISOString();
      }
      if (campaignData.endDate) {
        campaignData.endDate = new Date(campaignData.endDate).toISOString();
      }

      const requestBody = {
        campaign: {
          ...campaignData,
          client: campaignData.group?.client || campaignData.client,
          group: campaignData.group?._id || campaignData.group || null,
        },
      };

      const endpoint = _id ? `/updateCampaign/${_id}` : '/campaign';
      const method = _id ? 'PUT' : 'POST';

      const res = await fetchInstance(endpoint, {
        method,
        body: JSON.stringify(requestBody),
      });

      const responseData = await res.json();

      if (!res.ok) throw new Error('Failed to save campaign');

      await fetchGroupCampaigns(currentPage);
      setSelectedCampaign(null);
      handleCloseModal();
      setBanner(
        true,
        'good',
        _id ? 'Campaign updated successfully' : 'Campaign created successfully',
      );
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const openCampaignModal = () => {
    const newCampaign = {
      name: '',
      notes: '',
      group: group,
      client: group.client,
      parent: group,
    };

    setSelectedCampaign(newCampaign);
    setModalType('campaign-modal');
    handleShowModal();
  };

  let DeleteModalUx, editModal, shareModal, ShowNotesModal;

  if (showModal && modalType === 'delete') {
    DeleteModalUx = (
      <DeleteModal
        acceptFunction={deleteCampaign}
        showModal={showModal}
        message={[
          'Do you really want to delete ',
          <b>{selectedCampaign?.name}</b>,
          '?',
        ]}
        handleCloseModal={handleCloseModal}
      />
    );
  }

  if (showModal && modalType === 'campaign-modal') {
    editModal = (
      <CampaignDetails
        extractCSRF={extractCSRF}
        selectedCampaign={selectedCampaign}
        handleCloseModal={handleCloseModal}
        updateCampaign={updateCampaign}
        setBanner={setBanner}
        showModal={showModal}
        clientId={group?.client?._id}
        userId={user._id}
      />
    );
  }

  return (
    <DefaultLayout>
      <FlexContainer direction="column" gap="30px">
        <FlexContainer direction="column" gap="20px">
          <div>
            <Link
              to={`/client/${group?.client?._id}`}
              style={{ border: '0', fontSize: '20px', fontWeight: 'bold' }}
            >
              <IoArrowBack style={{ transform: 'translateY(3px)' }} />
              Back
            </Link>
          </div>
          <FlexContainer
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap="0"
          >
            <div className={styles.clientName}>{group?.client?.name} /</div>
            <FlexContainer alignItems="center" gap="5px">
              <FolderFill width={22} height={22} color="#2B3990" />
              <Title style={{ marginBottom: '0px' }}> {group?.name}</Title>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer direction="column" gap="20px">
          <FlexContainer direction="row" justifyContent="space-between">
            <Title style={{ fontSize: '20px', marginBottom: '0' }}>
              Campaigns ({totalCampaigns})
            </Title>
            <CampaignSearchBar
              handleSearchCampaignInput={handleSearchCampaignInput}
              openCampaignModal={openCampaignModal}
            />
          </FlexContainer>

          <GridContainer columns="repeat(4, 1fr)" gap="20px">
            {visibleCampaigns.length !== 0 &&
              visibleCampaigns.map((campaign) => (
                <CampaignCard
                  key={campaign._id}
                  campaign={campaign}
                  openShareModal={openShareModal}
                  openEditModal={openEditModal}
                  openDeleteModal={openDeleteModal}
                  openNotesModal={openNotesModal}
                />
              ))}
            {!loading && visibleCampaigns.length === 0 && (
              <p className={styles.noCampaigns}>No campaigns found</p>
            )}
          </GridContainer>

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </FlexContainer>
      </FlexContainer>
      {DeleteModalUx}
      {editModal}
      {shareModal}
      {ShowNotesModal}
    </DefaultLayout>
  );
};

export default GroupsMain;
