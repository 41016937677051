import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Frame } from 'scenejs';
import ConfirmModal from '../../../../ComponentsV2/ConfirmModal/ConfirmModal';
import { Dispatcher, Events } from '../../../../Components/Events';
import '../styles.scss';
import normalizeTransform from '../../Helpers/NormalizeTransform';
import BannerTextItem from './BannerText/BannerTextItem';
import BannerImageItem from './BannerImage/BannerImageItem';
import { useContextMenu } from './ContextMenu/CustomContextMenu';

const BannerElement = ({
  id,
  type,
  options,
  variations,
  currentResolution,
  frame: initialFrame,
  zindex,
  locked,
  onBringToFront,
  onSendToBack,
  disabledElementActions,
}) => {
  const { openMenu, closeMenu } = useContextMenu();
  const [target, setTarget] = useState(null);
  const [frame, setFrame] = useState(null);

  useEffect(() => {
    const targetElement = document.getElementById(id);
    const newFrame = new Frame(initialFrame);

    setTarget(targetElement);
    setFrame(newFrame);

    if (targetElement && newFrame) {
      setTransform(targetElement);
    }
  }, [id, initialFrame, currentResolution]);

  useEffect(() => {
    if (target && frame) {
      frame.set('z-index', zindex);
      setTransform(target);
    }
  }, [zindex, target, frame]);

  const setTransform = (target) => {
    if (!target || !frame) return;
    const css = frame.toCSS();
    target.style.cssText = css;
  };

  const handleDrag = ({ target, top, left }) => {
    if (!frame) return;
    frame.set('left', `${left}px`);
    frame.set('top', `${top}px`);

    setTransform(target);
  };

  const handleRotate = ({ target, beforeDelta }) => {
    if (!frame) return;
    const currentRotation = parseFloat(frame.get('transform', 'rotate')) || 0;
    const newRotation = currentRotation + beforeDelta;
    frame.set('transform', 'rotate', `${newRotation}deg`);
    setTransform(target);
  };

  const handleScale = ({ target, delta }) => {
    if (!frame) return;
    const scaleX = frame.get('transform', 'scaleX') * delta[0];
    const scaleY = frame.get('transform', 'scaleY') * delta[1];

    frame.set('transform', 'scaleX', scaleX);
    frame.set('transform', 'scaleY', scaleY);

    setTransform(target);
  };

  const handleResize = ({ target, width, height }) => {
    if (!frame) return;
    frame.set('width', `${width}px`);
    frame.set('height', `${height}px`);

    setTransform(target);
  };

  const handleEnd = () => {
    if (!frame) return;

    const currentTransform = {
      rotate: frame.get('transform', 'rotate'),
      scaleX: frame.get('transform', 'scaleX'),
      scaleY: frame.get('transform', 'scaleY'),
      matrix3d: frame.get('transform', 'matrix3d'),
      translate: frame.get('transform', 'translate') || [0, 0],
    };

    const frameProperties = {
      width: frame.get('width'),
      height: frame.get('height'),
      left: frame.get('left'),
      top: frame.get('top'),
      transform: normalizeTransform(currentTransform),
      'z-index': frame.get('z-index'),
    };

    Dispatcher.dispatch({
      event: Events.UPDATE_BANNER_ELEMENT,
      payload: {
        id,
        type,
        frame: frameProperties,
        options,
      },
    });
  };

  const onToggleLocked = () => {
    Dispatcher.dispatch({
      event: Events.UPDATE_BANNER_ELEMENT,
      payload: {
        id,
        type,
        options,
        variations: {
          ...variations,
          [currentResolution]: {
            ...variations[currentResolution],
            locked: !locked,
          },
        },
        frame: frame.get(),
      },
    });

    toast.success(`Element ${!locked ? 'locked' : 'unlocked'} successfully`);
  };

  const onEdit = () => {
    Dispatcher.dispatch({
      event: Events.EDIT_BANNER_ELEMENT,
      payload: {
        id,
        type,
        options: options || {},
        variations: variations || {},
        selectedResolution: currentResolution,
      },
    });
  };

  const onTrash = () => {
    ConfirmModal({
      title: 'Are you sure you want to delete this element?',
      subMessage: (
        <>
          This will remove all data associated with this element on all
          resolutions. <br /> Element id: {id}
        </>
      ),
      confirmText: 'Delete',
      isDanger: true,
      onConfirm: () => {
        Dispatcher.dispatch({
          event: Events.REMOVE_BANNER_ELEMENT,
          payload: { id },
        });
        toast.success('Element deleted successfully');
      },
    });
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const menuItems = [
      { label: id, isId: true },
      { isDivider: true },
      {
        label: locked ? 'Unlock' : 'Lock',
        onClick: () => {
          onToggleLocked();
          closeMenu();
        },
      },
      {
        label: 'Bring to front',
        onClick: () => {
          onBringToFront(id);
          closeMenu();
        },
      },
      {
        label: 'Send to back',
        onClick: () => {
          onSendToBack(id);
          closeMenu();
        },
      },
      { isDivider: true },
      {
        label: 'Edit',
        onClick: () => {
          onEdit();
          closeMenu();
        },
      },
      {
        label: 'Trash',
        onClick: () => {
          onTrash();
          closeMenu();
        },
      },
    ];

    openMenu(e.clientX, e.clientY, menuItems);
  };

  const moveableProps = {
    target,
    handleDrag,
    handleRotate,
    handleScale,
    handleResize,
    handleEnd,
  };

  const elementProps = {
    id,
    type,
    options,
    variations,
    currentResolution,
    frame: initialFrame,
    zindex,
    locked,
    disabledElementActions,
    ...moveableProps,
  };

  const Component =
    type === 'BannerTextItem' ? BannerTextItem : BannerImageItem;

  if (disabledElementActions) {
    return <Component {...elementProps} />;
  }

  return (
    <div className="banner-element-holder" onContextMenu={handleContextMenu}>
      <Component {...elementProps} />
    </div>
  );
};

export default BannerElement;
