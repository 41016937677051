import React, { useMemo } from 'react';
import BannerPreview from '../../Preview/Preview';
import { useMixer } from '../MixerContext';
import styles from './Preview.module.css';

const Preview = () => {
  const {
    bannerSet,
    selectedResolution,
    selectedElement,
    rootOptions,
    previewOptions,
  } = useMixer();

  const [width, height] = selectedResolution?.split('x').map(Number) || [0, 0];

  const currentElements = useMemo(() => {
    if (!bannerSet?.banners[0]?.elements || !selectedResolution) return [];

    return bannerSet?.banners[0]?.elements
      .map((element) => {
        const activeVariation = previewOptions.elements?.[element._id];

        const variation = activeVariation
          ? {
              options: {
                src: activeVariation.variations[selectedResolution].src,
                ...activeVariation.variations[selectedResolution].options,
              },
              status: activeVariation.variations[selectedResolution].status,
              frame: element.variations[selectedResolution].frame,
            }
          : element.variations[selectedResolution] ||
            element.variations[Object.keys(element.variations)[0]];

        if (!variation || variation.status === 'disabled') return null;

        return {
          id: element._id,
          type: element.type,
          options: {
            ...variation.options,
            class:
              element._id === selectedElement.id ? styles.selectedElement : '',
          },
          frame: variation.frame,
          locked: true,
          variations: element.variations,
          currentResolution: selectedResolution,
        };
      })
      .filter(Boolean)
      .sort((a, b) => (a.frame['z-index'] || 0) - (b.frame['z-index'] || 0));
  }, [
    bannerSet,
    selectedResolution,
    selectedElement.id,
    previewOptions.elements,
  ]);

  const customStyles = useMemo(() => {
    const baseStyles = {
      backgroundColor: '#ffffff',
      borderStyle: 'none',
      borderColor: 'transparent',
      borderWidth: '0px',
      padding: '0px',
    };

    Object.entries(previewOptions).forEach(([key, value]) => {
      switch (key) {
        case 'backgroundColor':
          baseStyles.backgroundColor = value.value;
          break;
        case 'borderStyle':
          baseStyles.borderStyle = value;
          break;
        case 'borderColor':
          baseStyles.borderColor = value.value;
          break;
        case 'borderThickness':
          baseStyles.borderWidth = `${value}`;
          break;
        case 'innerPadding':
          baseStyles.padding = `${value}`;
          break;
      }
    });

    return baseStyles;
  }, [
    rootOptions.backgroundColor,
    rootOptions.borderStyle,
    rootOptions.borderColor,
    rootOptions.borderThickness,
    rootOptions.innerPadding,
    previewOptions,
  ]);

  if (!selectedResolution || !bannerSet?.banners[0]?.elements) return null;

  return (
    <BannerPreview
      width={width}
      height={height}
      backgroundSrc={
        previewOptions.backgroundImages
          ? previewOptions.backgroundImages[selectedResolution]?.src
          : bannerSet?.banners[0]?.backgrounds[selectedResolution]?.src
      }
      elements={currentElements}
      elementProps={{
        container: document.querySelector('#banner-view'),
        offsetH: height,
        offsetW: width,
        disabledElementActions: true,
      }}
      customStyles={customStyles}
      className={styles.preview}
    />
  );
};

export default Preview;
