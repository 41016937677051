import React, { useState, useRef, useEffect } from 'react';
import Moveable from 'react-moveable';
import ParserStyle from 'style-to-object';
import { useFonts } from '../../../../../context/FontsContext';

const BannerTextItem = (props) => {
  const [fontFamily, setFontFamily] = useState(props.options.font ?? null);
  const moveableRef = useRef();
  const { loadFont } = useFonts();

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(props.id);
      if (element && moveableRef.current) {
        moveableRef.current.updateRect();
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [props.id, props.currentResolution]);

  useEffect(() => {
    if (props.options.font) {
      loadFont(props.options.font).then((loadedFont) => {
        if (fontFamily !== loadedFont) {
          setFontFamily(loadedFont);
          if (moveableRef.current) {
            moveableRef.current.updateRect();
          }
        }
      });
    }
  }, [fontFamily, props.options.font, loadFont]);

  const {
    id,
    options,
    locked,
    container,
    offsetH,
    offsetW,
    disabledElementActions,
  } = props;

  const elDefaultStyle = {
    fontSize: options.size,
    textAlign: options.position,
    color: options.color,
    fontFamily: `"${fontFamily || 'Arial'}"`,
    whiteSpace: 'pre-line',
    display: 'block',
    position: 'absolute',
    lineHeight: options.lineHeight,
    width: '100%',
    ...ParserStyle(props.options.style),
  };

  const elDefaultClass = [`${id}`];
  const ElTag = `${options.tag}`;
  const elClass = `${elDefaultClass} ${options.class}`;

  if (disabledElementActions) {
    return (
      <div id={id} className="moveable banner-item text-item">
        <ElTag id={`${id}_el`} className={elClass} style={elDefaultStyle}>
          {options.content}
        </ElTag>
      </div>
    );
  }

  return (
    <>
      {props.target && (
        <Moveable
          ref={moveableRef}
          key={`${id}-${props.zindex}`}
          target={props.target}
          container={container}
          draggable={!locked}
          rotatable={true}
          resizable={true}
          scalable={false}
          edge={true}
          snappable={true}
          snapCenter={true}
          bounds={{ left: 0, top: 0, bottom: offsetH, right: offsetW }}
          origin={false}
          throttleDrag={1}
          throttleRotate={0.2}
          throttleResize={1}
          throttleScale={0.01}
          onRenderStart={props.onRenderStart}
          onDrag={props.handleDrag}
          onRotate={props.handleRotate}
          onResize={props.handleResize}
          onDragEnd={props.handleEnd}
          onResizeEnd={props.handleEnd}
          onRotateEnd={props.handleEnd}
        />
      )}

      <div
        id={id}
        onClick={props.onClick}
        className="moveable banner-item text-item"
      >
        <ElTag id={`${id}_el`} className={elClass} style={elDefaultStyle}>
          {options.content}
        </ElTag>
      </div>
    </>
  );
};

export default BannerTextItem;
