import React, { useEffect, useMemo, useState } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import Grid from '@material-ui/core/Grid';
import Container from '../styles/GlobalContainerStyle';
import FlexContainer from '../styles/FlexContainer';
import { useFetch } from '../../hooks/useFetch';
import BackLink from '../styles/BackLink';
import SocialIcon from '../../ComponentsV2/SocialIcon/SocialIcon';
import { capitalizeString } from '../../utils/capitalizeString';
import { GalleryList } from './GalleryList/GalleryList';
import { GallerySidebar } from './CallerySidebar/GallerySidebar';

import styles from './Gallery.module.scss';
import {
  AllBannersGroup,
  GalleryContextProvider,
  UngroupedBannersGroup,
  useGalleryContext,
} from './Gallery.context';
import { generateBannerGroupStartElement } from './utils/generateBannerGroupsStartElement';

const GalleryLayout = ({ match }) => {
  const [bannerSetId] = useState(match.params.bannerSetId);

  const {
    filters: { setNetworkResolutions, setBannerGroups: setFilterBannerGroups },
    setBannerGroupsMap,
    setBannerGroups,
    setBannersList,
    setBannerSetId,
    setBannerSet,
    refetchBannerGroupsSymbol,
    setCurrentCampaign,
  } = useGalleryContext();

  const { fetchDataFn: fetchBannerGroups, data: bannerGroupsData } = useFetch(
    `/getBannerGroups/${bannerSetId}`,
    { lazy: true },
  );

  const { fetchDataFn: fetchBanners, data: bannersData } = useFetch(
    `/getBanners/${bannerSetId}`,
    { lazy: true },
  );

  const bannerSet = useMemo(
    () => bannersData?.banners?.[0]?.bannerSet,
    [bannersData],
  );

  const { fetchDataFn: fetchCampaign, data: currentCampaign } = useFetch(
    `/getCampaign/${bannerSet?.campaign}`,
    { lazy: true },
  );

  useEffect(() => {
    if (currentCampaign) {
      setCurrentCampaign(currentCampaign);
    }
  }, [currentCampaign]);

  useEffect(() => {
    if (refetchBannerGroupsSymbol) {
      void fetchBannerGroups();
    }
  }, [refetchBannerGroupsSymbol]);

  useEffect(() => {
    setBannerSetId(bannerSetId);
  }, [bannerSetId]);

  useEffect(() => {
    void fetchBanners({
      queryParams: {
        page: 1,
        limit: 100,
        populate: true,
        additional: 'gallery,elements',
      },
    });
    void fetchBannerGroups();
  }, []);

  useEffect(() => {
    if (bannersData?.banners?.length) {
      void fetchCampaign();

      setBannerSet(bannersData?.banners[0]?.bannerSet);
      setNetworkResolutions(
        bannerSet?.resolutions?.map((res) => `${res.width}x${res.height}`) ||
          [],
      );
    }

    setBannersList(
      bannersData?.banners?.filter((banner) => Boolean(banner.createdImgs)) ||
        [],
    );
  }, [bannersData, bannerSet]);

  useEffect(() => {
    const { bannerGroupsMap, customBannerGroups } =
      bannerGroupsData?.bannerGroups?.reduce(
        (acc, group) => {
          const { bannerGroupsMap, customBannerGroups } = acc;

          group.banners.forEach((bannerId) => {
            if (bannerGroupsMap[bannerId]) {
              bannerGroupsMap[bannerId].push(group);
              return;
            }

            bannerGroupsMap[bannerId] = [group];
          });

          customBannerGroups.push({
            value: group._id,
            label: group.name,
            color: group.color,
            startReactElement: generateBannerGroupStartElement(group),
          });

          return acc;
        },
        {
          bannerGroupsMap: {},
          customBannerGroups: [],
        },
      ) || {
        bannerGroupsMap: {},
        customBannerGroups: [],
      };

    setBannerGroupsMap(bannerGroupsMap);

    setBannerGroups(bannerGroupsData?.bannerGroups || []);
    setFilterBannerGroups([
      AllBannersGroup,
      UngroupedBannersGroup,
      ...customBannerGroups,
    ]);
  }, [bannerGroupsData, bannersData]);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FlexContainer direction="column" gap="15px">
            <div>
              <BackLink to={`/campaign/${currentCampaign?._id}`}>
                <IoArrowBack />
                Back
              </BackLink>
            </div>
            <FlexContainer alignItems="center" gap="30px">
              <h1 className={styles.title}>
                {currentCampaign?.client && (
                  <>
                    <span>{currentCampaign?.client?.name}</span>&nbsp;-&nbsp;
                  </>
                )}
                {currentCampaign?.group && (
                  <>
                    <span>{currentCampaign?.group?.name}</span>&nbsp;-&nbsp;
                  </>
                )}
                {currentCampaign && (
                  <>
                    <span>{currentCampaign?.name}</span>&nbsp;-&nbsp;
                  </>
                )}
                {bannerSet && <span>{bannerSet?.name}</span>}
              </h1>
              <FlexContainer direction="row" alignItems="center" gap="5px">
                <FlexContainer gap="5px">
                  <SocialIcon
                    networks={currentCampaign?.platforms || ['']}
                    size={16}
                  />
                </FlexContainer>
                {currentCampaign?.platforms?.length === 1 && (
                  <div className={styles.platform}>
                    {capitalizeString(currentCampaign?.platforms[0])}
                  </div>
                )}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Grid>

        <Grid item xs={8}>
          <GalleryList></GalleryList>
        </Grid>

        <Grid item xs={4}>
          <GallerySidebar></GallerySidebar>
        </Grid>
      </Grid>
    </Container>
  );
};

export const GalleryPage = (props) => {
  return (
    <GalleryContextProvider>
      <GalleryLayout {...props} />
    </GalleryContextProvider>
  );
};
