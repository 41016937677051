import React, { createContext, useContext, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styles from './CustomContextMenu.module.css';

export const ContextMenuContext = createContext(null);

export const ContextMenuProvider = ({ children }) => {
  const [menuProps, setMenuProps] = useState({
    isOpen: false,
    x: 0,
    y: 0,
    items: [],
  });

  const openMenu = useCallback((x, y, items) => {
    setMenuProps({ isOpen: true, x, y, items });
  }, []);

  const closeMenu = useCallback(() => {
    setMenuProps({ isOpen: false, x: 0, y: 0, items: [] });
  }, []);

  return (
    <ContextMenuContext.Provider value={{ openMenu, closeMenu }}>
      {children}
      {menuProps.isOpen && (
        <ContextMenuPortal {...menuProps} onClose={closeMenu} />
      )}
    </ContextMenuContext.Provider>
  );
};

const MenuItem = ({ label, onClick, isDivider, disabled, isId }) => {
  if (isId) {
    return <div className={`${styles.menuItem} ${styles.id}`}>{label}</div>;
  }

  if (isDivider) {
    return <div className={styles.divider} />;
  }

  return (
    <div
      className={`${styles.menuItem} ${disabled ? styles.disabled : ''}`}
      onClick={!disabled ? onClick : undefined}
    >
      {label}
    </div>
  );
};

const ContextMenuPortal = ({ x, y, items, onClose }) => {
  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(`.${styles.contextMenu}`)) {
        onClose();
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('contextmenu', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('contextmenu', handleClickOutside);
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <div
      className={styles.contextMenu}
      style={{
        left: `${x}px`,
        top: `${y}px`,
      }}
    >
      {items.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
    </div>,
    document.body,
  );
};

export const useContextMenu = () => {
  const context = useContext(ContextMenuContext);
  if (!context) {
    throw new Error('useContextMenu must be used within a ContextMenuProvider');
  }
  return context;
};
