import React, { Component } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

class ColorPickerBox extends Component {
  state = {
    color: this.props.color || '#000',
    displayColorPicker: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.color !== this.props.color) {
      this.setState({ color: this.props.color || '#000000' });
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: true });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
    this.props.onChange(color.hex);
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '100%',
          height: '100%',
          borderRadius: '2px',
          background: this.state.color,
        },
        swatch: {
          padding: '4px',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          width: '33px',
          height: '33px',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        label: {
          marginBottom: '5px',
          fontWeight: 400,
          fontSize: '14px',
          color: '#000',
        },
      },
    });

    return (
      <div>
        {this.props.label && <div style={styles.label}>{this.props.label}</div>}
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>

        {this.state.displayColorPicker && (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
              disableAlpha={true}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ColorPickerBox;
