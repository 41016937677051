import React from 'react';
import { Ban, ExclamationTriangle } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

/**
 * @typedef {Object} ItemType
 * @property {string | number} value - Value of the item
 * @property {string} label - Label of the item
 * @property {boolean} disabled - Whether the item is disabled
 * @property {JSX.Element=} startReactElement - JSX element to display before the label
 * @property {JSX.Element=} endReactElement - JSX element to display after the label
 */

/**
 * @typedef {Object} ButtonsGroupProps
 * @property {ItemType[]} items - Array of available items
 * @property {ItemType[]} selectedItems - Array of currently selected items
 * @property {(items: ItemType[]) => void} onChange - Callback when item is toggled
 * @property {boolean} [darkerButtons] - Whether to use darker button styling
 * @property {boolean} [multiple] - Whether to allow multiple selections
 * @property {string} [error] - Error message to display
 *
 */

/**
 * Buttons component for toggling values
 * @param {ButtonsGroupProps} props
 * @returns {JSX.Element}
 */
const ButtonsGroup = ({
  items,
  selectedItems,
  onChange,
  darkerButtons = false,
  multiple = false,
  error,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.buttons}>
        {items.map((item) => {
          const { value, label, disabled, startReactElement, endReactElement } =
            item;

          const isSelected = selectedItems.some((item) => item.value === value);
          const isDisabled = disabled;

          return (
            <button
              key={value}
              type="button"
              className={cx(styles.button, {
                [styles.active]: isSelected,
                [styles.darker]: darkerButtons,
                [styles.disabled]: isDisabled,
              })}
              onClick={() => {
                if (!multiple) {
                  onChange([item]);
                  return;
                }

                if (isSelected) {
                  onChange(
                    selectedItems.filter((item) => item.value !== value),
                  );
                } else {
                  onChange([...selectedItems, item]);
                }
              }}
            >
              {startReactElement && startReactElement}
              <div className={styles.overIcons}>
                {isDisabled && <Ban className={styles.disabledIcon} />}
                {error && <ExclamationTriangle className={styles.errorIcon} />}
              </div>
              {label || value}
              {endReactElement && endReactElement}
            </button>
          );
        })}
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

const ItemPropType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
});

ButtonsGroup.propTypes = {
  items: PropTypes.arrayOf(ItemPropType).isRequired,
  selectedItems: PropTypes.arrayOf(ItemPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  darkerButtons: PropTypes.bool,
  multiple: PropTypes.bool,
  error: PropTypes.string,
};

export default ButtonsGroup;
