import React from 'react';
import toast from 'react-hot-toast';
import html2canvas from 'html2canvas';
import { Dispatcher, Events } from '../../../Components/Events';
import BannerPreview from '../Preview/Preview';
import './Styles/menu.scss';
import {
  ContextMenuProvider,
  useContextMenu,
} from './Elements/ContextMenu/CustomContextMenu';

const BannerCreatorContent = ({
  elements,
  background,
  resolution,
  onRemoveElements,
  onRemoveBackground,
  onReset,
  onBringToFront,
  onSendToBack,
}) => {
  const { openMenu } = useContextMenu();

  const [width, height] = resolution
    ? resolution.split('x').map(Number)
    : [0, 0];

  const handleSendToMixer = () => {
    Dispatcher.dispatch({
      event: Events.OPEN_MIXER_MODAL,
      payload: { elements, background, resolution },
    });
  };

  const handleSaveAsPNG = () => {
    const element = document.getElementById('banner-view');

    html2canvas(element, {
      useCORS: true,
      imageTimeout: 2000,
      removeContainer: true,
      backgroundColor: null,
      scale: 2,
    })
      .then((canvas) => {
        const a = document.createElement('a');
        a.href = canvas.toDataURL('image/png');
        a.download = `banner_${resolution}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error('Error generating PNG:', error);
        toast.error('Failed to generate PNG');
      });
  };

  const handleContextMenu = (e) => {
    if (e.target.closest('.banner-element-holder')) {
      return;
    }

    e.preventDefault();
    const menuItems = [
      { label: 'Send to Mixer', onClick: handleSendToMixer },
      { label: 'Save as PNG', onClick: handleSaveAsPNG },
      { isDivider: true },
      { label: 'Remove elements', onClick: onRemoveElements },
      { label: 'Remove background', onClick: onRemoveBackground },
      { isDivider: true },
      { label: 'Reset', onClick: onReset },
    ];

    openMenu(e.clientX, e.clientY, menuItems);
  };

  return (
    <div onContextMenu={handleContextMenu}>
      <BannerPreview
        width={width}
        height={height}
        backgroundSrc={background?.src}
        elements={elements}
        elementProps={{
          currentResolution: resolution,
          onBringToFront,
          onSendToBack,
        }}
      />
    </div>
  );
};

const BannerCreatorPreview = (props) => {
  return (
    <ContextMenuProvider>
      <BannerCreatorContent {...props} />
    </ContextMenuProvider>
  );
};

export default BannerCreatorPreview;
