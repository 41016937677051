import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMixer } from '../../MixerContext';
import config from '../../../../../config';
import { useFonts } from '../../../../../context/FontsContext';
import styles from './OverviewBanners.module.css';
import generateBannerHTML from './generateBannerHtml';

const OverviewBanners = () => {
  const containerRef = useRef(null);
  const {
    bannerSet,
    customBackgroundVariants,
    elementVariants,
    rootOptions,
    selectedResolution,
  } = useMixer();
  const { fonts } = useFonts();

  const [width, height] = selectedResolution.split('x').map(Number);
  const [scale, setScale] = useState(1);
  const [scaledHeight, setScaledHeight] = useState(height);

  const combinations = useMemo(() => {
    if (!selectedResolution) return [];

    const backgrounds = [
      bannerSet.banners[0].backgrounds[selectedResolution],
      ...customBackgroundVariants.map((v) => v.variations[selectedResolution]),
    ];

    const elementsByType = bannerSet.banners[0].elements.reduce(
      (acc, baseElement) => {
        const baseVariation = {
          ...baseElement.variations[selectedResolution],
          frame: baseElement.variations[selectedResolution]?.frame,
          isVisible:
            baseElement.variations[selectedResolution]?.status === 'enabled',
        };

        const variants = elementVariants
          .filter((v) => v.baseId === baseElement._id)
          .map((v) => ({
            ...v.variations[selectedResolution],
            frame: baseElement.variations[selectedResolution]?.frame,
            isVisible: v.variations[selectedResolution]?.status === 'enabled',
          }));

        acc[baseElement._id] = [baseVariation, ...variants];

        return acc;
      },
      {},
    );

    const rootCombinations = [];

    const hasValidRootOptions = Object.entries(rootOptions)
      .filter(([key]) => !['id', '_id'].includes(key))
      .some(([, values]) => Array.isArray(values) && values.length > 0);

    if (hasValidRootOptions) {
      Object.entries(rootOptions)
        .filter(([key]) => !['id', '_id'].includes(key))
        .forEach(([key, values]) => {
          if (!Array.isArray(values)) return;

          if (['borderStyle', 'borderColor', 'borderThickness'].includes(key)) {
            if (!rootCombinations.borderGroup) {
              rootCombinations.borderGroup = {
                borderStyle: rootOptions.borderStyle || [],
                borderColor: rootOptions.borderColor || [],
                borderThickness: rootOptions.borderThickness || [],
              };
            }
          } else if (values.length > 0) {
            rootCombinations.push(...values.map((value) => ({ [key]: value })));
          }
        });
    }

    if (rootCombinations.length === 0 && !rootCombinations.borderGroup) {
      rootCombinations.push({});
    }

    if (rootCombinations.borderGroup) {
      const { borderStyle, borderColor, borderThickness } =
        rootCombinations.borderGroup;

      if (borderStyle.length && borderColor.length && borderThickness.length) {
        const borderCombinations = borderStyle.flatMap((style) =>
          borderColor.flatMap((color) =>
            borderThickness.map((thickness) => ({
              borderStyle: style,
              borderColor: color,
              borderThickness: thickness,
            })),
          ),
        );

        const finalRootCombinations = rootCombinations
          .filter((opt) => !opt.borderGroup)
          .flatMap((baseOpt) =>
            borderCombinations.map((borderOpt) => ({
              ...baseOpt,
              ...borderOpt,
            })),
          );

        rootCombinations.length = 0;
        rootCombinations.push(...finalRootCombinations);
      }

      delete rootCombinations.borderGroup;
    }

    const allCombinations = [];

    const generateCombinations = (current, elements, depth = 0) => {
      if (depth === Object.keys(elements).length) {
        allCombinations.push(current);
        return;
      }

      const elementId = Object.keys(elements)[depth];
      const variants = elements[elementId];

      variants.forEach((variant) => {
        generateCombinations(
          {
            ...current,
            elements: { ...current.elements, [elementId]: variant },
          },
          elements,
          depth + 1,
        );
      });
    };

    backgrounds.forEach((bg) => {
      rootCombinations.forEach((rootOpt) => {
        generateCombinations(
          { background: bg, rootOptions: rootOpt, elements: {} },
          elementsByType,
        );
      });
    });

    return allCombinations;
  }, [
    bannerSet,
    customBackgroundVariants,
    elementVariants,
    rootOptions,
    selectedResolution,
  ]);

  useEffect(() => {
    if (!containerRef.current || !width) return;

    const calculateScale = () => {
      const availableWidth = containerRef.current.parentElement.offsetWidth;
      const maxHeight = config.bannerWizard.maxHeight;

      const widthScale = availableWidth / width;
      const heightScale = maxHeight / height;

      const newScale = Math.min(
        1,
        Math.max(0.1, Math.min(widthScale, heightScale)),
      );
      setScale(newScale);
      setScaledHeight(height * newScale);
    };

    calculateScale();

    const resizeObserver = new ResizeObserver(calculateScale);
    resizeObserver.observe(containerRef.current.parentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [width, height]);

  return (
    <div className={styles.root} ref={containerRef}>
      <div className={styles.grid}>
        {combinations.map((combination, index) => {
          return (
            <div
              key={index}
              className={styles.previewWrapper}
              style={{ height: `${scaledHeight}px` }}
            >
              <iframe
                className={styles.preview}
                srcDoc={generateBannerHTML(
                  combination,
                  selectedResolution,
                  fonts,
                )}
                width={width}
                height={height}
                style={{
                  width: `${width}px`,
                  height: `${height}px`,
                  transform: `scale(${scale})`,
                  transformOrigin: 'center center',
                }}
                frameBorder="0"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OverviewBanners;
