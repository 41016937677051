import React from 'react';
import { Trash3 } from 'react-bootstrap-icons';
import ResolutionButtons from '../../../../Components/FormComponents/ResolutionButtons';
import Button from '../../../../Components/FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../shared/enums/buttonEnum';
import styles from './PlacementBox.module.css';

const PlacementBox = ({
  placement,
  availableResolutions,
  selectedResolutions,
  onResolutionToggle,
  onRemove,
}) => (
  <div className={styles.root}>
    <div className={styles.header}>
      <div className={styles.info}>
        <h3 className={styles.name}>{placement.name}</h3>
        <div className={styles.subCategories}>
          {placement.subCategories.join(' • ')}
        </div>
      </div>
      <Button
        className={styles.removeButton}
        size={ButtonSizeEnum.S}
        variant={ButtonVariantEnum.TEXT}
        onClick={onRemove}
      >
        <Trash3 />
      </Button>
    </div>
    <ResolutionButtons
      networkResolutions={availableResolutions}
      selectedResolutions={selectedResolutions}
      onResolutionToggle={onResolutionToggle}
      hideLabel
      smaller
    />
  </div>
);

export default PlacementBox;
