import { useState, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import fetchFbInstance from '../../../../utils/fetchFbInstance';

export const useDestinationCard = ({ setFieldValue }) => {
  const [loading, setLoading] = useState(false);
  const [adAccounts, setAdAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [adSets, setAdSets] = useState([]);
  const [pages, setPages] = useState([]);

  const fetchAdAccounts = async () => {
    try {
      setLoading(true);
      const response = await fetchFbInstance('/getFacebookAdAccounts', {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ad accounts');
      }

      const data = await response.json();
      if (Array.isArray(data)) {
        setAdAccounts(data);
      } else if (data.error) {
        throw new Error(data.message);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      toast.error(error.message);
      setAdAccounts([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaigns = async (accountId) => {
    try {
      setLoading(true);
      const response = await fetchFbInstance('/getFacebookAdCampaigns', {
        method: 'POST',
        body: JSON.stringify({ adAccountId: accountId }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch campaigns');
      }

      const data = await response.json();
      if (Array.isArray(data)) {
        setCampaigns(data);
      } else if (data.error) {
        throw new Error(data.message);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      toast.error(error.message);
      setCampaigns([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdSets = async (campaignId) => {
    try {
      setLoading(true);
      const response = await fetchFbInstance('/getFacebookAdSets', {
        method: 'POST',
        body: JSON.stringify({ campaignId }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ad sets');
      }

      const data = await response.json();
      if (Array.isArray(data)) {
        setAdSets(data);
      } else if (data.error) {
        throw new Error(data.message);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      toast.error(error.message);
      setAdSets([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchPages = async () => {
    try {
      setLoading(true);
      const response = await fetchFbInstance('/getFacebookUserPages', {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch pages');
      }

      const data = await response.json();
      if (data.error) throw new Error(data.message);
      setPages(data || []);
    } catch (error) {
      toast.error(error.message);
      setPages([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdAccounts();
    fetchPages();
  }, []);

  const handleAccountChange = async (accountId) => {
    const account = adAccounts.find((acc) => acc.id === accountId);
    setFieldValue('destination.account', account);
    setFieldValue('destination.campaign', null);
    setFieldValue('destination.adSet', null);

    if (account) {
      await fetchCampaigns(accountId);
    }
  };

  const handleCampaignChange = async (campaignId) => {
    const campaign = campaigns.find((camp) => camp.id === campaignId);
    setFieldValue('destination.campaign', campaign);
    setFieldValue('destination.adSet', null);

    if (campaign) {
      await fetchAdSets(campaignId);
    }
  };

  const handleAdSetChange = (adSetId) => {
    const adSet = adSets.find((set) => set.id === adSetId);
    setFieldValue('destination.adSet', adSet);
  };

  const handlePageChange = (pageId) => {
    const page = pages.find((p) => p.id === pageId);
    setFieldValue('destination.page', page);
  };

  const accountOptions = useMemo(
    () =>
      adAccounts?.map((account) => ({
        value: account.id,
        label: account.name,
      })) || [],
    [adAccounts],
  );

  const campaignOptions = useMemo(
    () =>
      campaigns?.map((campaign) => ({
        value: campaign.id,
        label: campaign.name,
      })) || [],
    [campaigns],
  );

  const adSetOptions = useMemo(
    () =>
      adSets?.map((adSet) => ({
        value: adSet.id,
        label: adSet.name,
      })) || [],
    [adSets],
  );

  const pageOptions = useMemo(
    () =>
      pages?.map((page) => ({
        value: page.id,
        label: page.name,
      })) || [],
    [pages],
  );

  return {
    loading,
    adAccounts,
    campaigns,
    adSets,
    pages,
    handleAccountChange,
    handleCampaignChange,
    handleAdSetChange,
    handlePageChange,
    accountOptions,
    campaignOptions,
    adSetOptions,
    pageOptions,
  };
};
