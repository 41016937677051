import React from 'react';
import {
  Fonts,
  BracesAsterisk,
  TextareaT,
  JustifyLeft,
  CodeSlash,
  ArrowsExpand,
} from 'react-bootstrap-icons';
import styles from './OptionsList.module.css';

const OptionIcon = ({ optionKey, value }) => {
  switch (optionKey) {
    case 'font':
      return <Fonts />;

    case 'size':
      return <TextareaT />;

    case 'position':
      return <JustifyLeft />;

    case 'tag':
      return <CodeSlash />;

    case 'lineHeight':
      return <ArrowsExpand />;

    case 'content':
      return null;

    case 'color':
      return (
        <div className={styles.color} style={{ backgroundColor: value }} />
      );

    default:
      return <BracesAsterisk />;
  }
};

const OptionsList = ({ options }) => {
  return (
    <div className={styles.root}>
      {Object.entries(options)
        .filter(([key, value]) => key !== 'content' && Boolean(value))
        .map(([key, value]) => (
          <div key={key} className={styles.option}>
            <OptionIcon optionKey={key} value={value} />
            {value}
          </div>
        ))}
    </div>
  );
};

export default OptionsList;
