import React, { useState, useCallback } from 'react';
import { ArrowRightShort } from 'react-bootstrap-icons';
import { toast } from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import DefaultLayout from '../../Components/DefaultLayout';
import TopBar from '../TopBar/TopBar';
import DestinationCard from './cards/DestinationCard';
import AdDetailsCard from './cards/AdDetailsCard';
import { useFacebookCampaign } from './hooks/facebook/useFacebookCampaign';

import styles from './styles/FacebookCampaignPage.module.scss';
import BannersPlacementsCard from './cards/PlacementResolutions/BannersPlacementsCard';

const validationSchema = Yup.object().shape({
  destination: Yup.object().shape({
    account: Yup.object().nullable().required('Ad Account is required'),
    campaign: Yup.object().nullable().required('Campaign is required'),
    adSet: Yup.object().nullable().required('Ad Set is required'),
    page: Yup.object().nullable().required('Page is required'),
  }),
  banners: Yup.object().shape({
    group: Yup.object().nullable().required('Group is required'),
    placements: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
          resolutions: Yup.array().min(
            1,
            'At least one resolution is required',
          ),
        }),
      )
      .min(1, 'At least one placement is required'),
  }),
  adDetails: Yup.object().shape({
    headline: Yup.string()
      .required('Headline is required')
      .max(40, 'Headline must be 40 characters or less'),
    primaryText: Yup.string()
      .required('Primary text is required')
      .max(125, 'Primary text must be 125 characters or less'),
    shortDescription: Yup.string(),
    utmSource: Yup.string(),
    utmMedium: Yup.string(),
    utmCampaign: Yup.string(),
    landingPageUrl: Yup.string(),
  }),
});

const FacebookCampaignPage = () => {
  const [unlockedSteps, setUnlockedSteps] = useState([1]);

  const {
    bannerSet,
    handleSubmit,
    loading,
    handleStepComplete,
    validateSubmission,
    availableResolutions,
  } = useFacebookCampaign({
    unlockedSteps,
    setUnlockedSteps,
  });

  const initialValues = {
    destination: {
      account: null,
      campaign: null,
      adSet: null,
      page: null,
    },
    banners: {
      group: null,
      placements: [],
    },
    adDetails: {
      name: '',
      finalUrl: '',
      headline: '',
      primaryText: '',
      shortDescription: '',
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
      landingPageUrl: '',
    },
  };

  const handleFormSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        if (!validateSubmission(values)) {
          toast.error('Please fill in all required fields');
          return;
        }
        await handleSubmit(values);
      } catch (error) {
        console.error('Submission error:', error);
      } finally {
        setSubmitting(false);
      }
    },
    [handleSubmit, validateSubmission],
  );

  return (
    <DefaultLayout>
      <div className={styles.wrapper}>
        <TopBar title={bannerSet?.name} network={bannerSet?.platform} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className={styles.cardsGrid}>
                  <DestinationCard
                    platform="facebook"
                    disabled={false}
                    onComplete={(data) => {
                      setFieldValue('destination', data);
                      handleStepComplete('destination', values);
                    }}
                  />

                  <BannersPlacementsCard
                    platform="facebook"
                    disabled={!unlockedSteps.includes(2)}
                    onComplete={async (data) => {
                      await setFieldValue('banners', data);
                      const updatedValues = { ...values, banners: data };
                      handleStepComplete('banners', updatedValues);
                    }}
                    bannerSet={bannerSet}
                    availableResolutions={availableResolutions}
                  />
                </div>

                <AdDetailsCard
                  platform="facebook"
                  disabled={!unlockedSteps.includes(3)}
                  bannerSet={bannerSet}
                />

                <div className={styles.submitFooter}>
                  <button
                    type="submit"
                    className={styles.submitButton}
                    disabled={!unlockedSteps.includes(3) || loading}
                  >
                    {loading ? 'Sending to Facebook...' : 'Send to Facebook'}
                    <ArrowRightShort />
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </DefaultLayout>
  );
};

export default FacebookCampaignPage;
