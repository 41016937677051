import { useState, useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import fetchFbInstance from '../../../../utils/fetchFbInstance';
import fetchInstance from '../../../../utils/fetchInstance';

export const useFacebookCampaign = ({ unlockedSteps, setUnlockedSteps }) => {
  const { id } = useParams();
  const [bannerSet, setBannerSet] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [availableResolutions, setAvailableResolutions] = useState([]);

  useEffect(() => {
    const fetchBannerSet = async () => {
      try {
        const response = await fetchInstance(`/getBannerSet/${id}`);

        if (!response.ok) {
          throw new Error('Failed to fetch banner set');
        }

        const data = await response.json();
        setBannerSet(data);
        if (data.resolutions) {
          const resolutions = data.resolutions.map(
            (res) => `${res.width}x${res.height}`,
          );
          setAvailableResolutions(resolutions);
        }
      } catch (error) {
        console.error('Error fetching banner set:', error);
        toast.error('Failed to fetch banner set.');
        setBannerSet(null);
        setAvailableResolutions([]);
      }
    };

    fetchBannerSet();
  }, [id]);

  const validateSubmission = (values) => {
    const hasValidDestination =
      values.destination.account &&
      values.destination.campaign &&
      values.destination.adSet &&
      values.destination.page;

    const hasValidBanners =
      values.banners.group &&
      values.banners.placements?.length > 0 &&
      values.banners.placements.some(
        (placement) => placement.resolutions?.length > 0,
      );

    const hasValidAdDetails =
      values.adDetails.headline && values.adDetails.primaryText;

    return hasValidDestination && hasValidBanners && hasValidAdDetails;
  };

  const handleStepComplete = useCallback(
    (step, values) => {
      switch (step) {
        case 'destination':
          if (
            values.destination.account &&
            values.destination.campaign &&
            values.destination.adSet &&
            values.destination.page
          ) {
            setCurrentStep(2);
            if (!unlockedSteps.includes(2)) {
              setUnlockedSteps((prev) => [...prev, 2]);
            }
          }
          break;

        case 'banners':
          const hasValidPlacement = values.banners.placements?.some(
            (placement) => placement.resolutions?.length > 0,
          );

          if (values.banners.group && hasValidPlacement) {
            setCurrentStep(3);
            if (!unlockedSteps.includes(3)) {
              setUnlockedSteps((prev) => [...prev, 3]);
            }
          }
          break;

        case 'adDetails':
          if (
            values.adDetails.name &&
            values.adDetails.finalUrl &&
            values.adDetails.headline &&
            values.adDetails.primaryText
          ) {
            setCurrentStep(4);
            if (!unlockedSteps.includes(4)) {
              setUnlockedSteps((prev) => [...prev, 4]);
            }
          }
          break;

        default:
          break;
      }
    },
    [unlockedSteps, setUnlockedSteps],
  );

  const handleSubmit = useCallback(async (values) => {
    try {
      setLoading(true);

      if (!validateSubmission(values)) {
        throw new Error('Please complete all required fields');
      }

      if (!values.banners.placements.some((p) => p.resolutions.length > 0)) {
        throw new Error(
          'At least one placement must have a resolution selected',
        );
      }

      const payload = {
        ...values.adDetails,
        destinationData: values.destination,
        bannerData: {
          group: values.banners?.group?._id,
          placements: values.banners.placements.map((p) => ({
            id: p.id,
            resolutions: p.resolutions,
          })),
        },
      };

      if (!payload.blahBlah) {
        console.log(payload);
        throw new Error('Not implemented yet');
      }

      const response = await fetchFbInstance('/createFacebookAds', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to create Facebook ad');
      }

      const data = await response.json();
      toast.success('Facebook ad created successfully');
      return data;
    } catch (error) {
      toast.error(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    bannerSet,
    availableResolutions,
    currentStep,
    setCurrentStep,
    handleSubmit,
    loading,
    handleStepComplete,
    validateSubmission,
  };
};
