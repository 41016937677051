import { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import fetchInstance from '../../../utils/fetchInstance';
import BannerSetStatusEnum from '../../../shared/enums/bannerSetStatus';

const useMixing = (id) => {
  const [bannerSet, setBannerSet] = useState(null);
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    let intervalId = null;

    const fetchBannerSet = async () => {
      try {
        setIsLoading(true);

        const response = await fetchInstance(
          `/getBannerSet/${id}?aggregate=true`,
        );
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch banner set');
        }

        if (data.status === BannerSetStatusEnum.GALLERY) {
          clearInterval(intervalId);
          history.replace(`/gallery/${id}`);
          return;
        }

        if (data.status !== BannerSetStatusEnum.MIXING) {
          throw new Error('Banner set is not in mixing mode');
        }

        setBannerSet(data);
      } catch (error) {
        toast.error('Error fetching mixer data. Please try again.');
        console.error('Error fetching mixer:', error);
        setError('A apărut o eroare în timpul procesării.');
        clearInterval(intervalId);
      } finally {
        setIsLoading(false);
      }
    };

    const checkStatus = async () => {
      try {
        const response = await fetchInstance(`/checkBannerSetStatus/${id}`);
        const data = await response.json();

        if (data.status === BannerSetStatusEnum.GALLERY) {
          clearInterval(intervalId);
          history.replace(`/gallery/${id}`);
          return;
        }

        if (data.error) {
          setError(data.error);
          clearInterval(intervalId);
          return;
        }

        if (data.status === BannerSetStatusEnum.MIXING) {
          let calculatedProgress = Math.floor(
            (data.mixedBanners / data.totalBanners) * 100,
          );
          if (calculatedProgress < 0 || calculatedProgress === Infinity) {
            calculatedProgress = 0;
          }
          setProgress(calculatedProgress);
          setStats({
            totalBanners: data.totalBanners,
            mixedBanners: data.mixedBanners,
          });
        }
      } catch (err) {
        setError('A apărut o eroare în timpul procesării.');
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(checkStatus, 1000);

    fetchBannerSet();

    return () => {
      clearInterval(intervalId);
    };
  }, [id, history]);

  const variationsCount = useMemo(() => {
    return stats?.totalBanners / bannerSet?.resolutions?.length;
  }, [stats?.totalBanners, bannerSet?.resolutions?.length]);

  return {
    bannerSet,
    stats,
    isLoading,
    progress,
    error,
    variationsCount,
  };
};

export default useMixing;
