import React from 'react';
// import PropTypes from "prop-types";

export default class Image extends React.Component {
  render() {
    return (
      <div className="photoContainer">
        <div
          data-image={this.props.url}
          onClick={this.props.pickImage}
          className="selectCheckbox"
        >
          {this.props.picked ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="rgb(82, 186, 213)"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
              viewBox="0 0 34 34"
            >
              <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z" />
            </svg>
          )}
        </div>
        <div
          data-image={this.props.url}
          onClick={this.props.openModal}
          className={`photo ${this.props.rejected ? 'rejected' : ''}`}
          style={{ backgroundImage: `url("${this.props.url}")` }}
          alt=""
        />
        {this.props.rejected ? (
          <div>❌</div>
        ) : this.props.colors ? (
          this.props.colors.map((color, i) => (
            <div
              key={i}
              className="colorIcon"
              style={{
                backgroundColor: color,
                width: '2rem',
                height: '2rem',
                margin: '3px',
                borderRadius: '25%',
              }}
            />
          ))
        ) : null}
      </div>
    );
  }
}
