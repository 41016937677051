import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import Button from '../../../../Components/FormComponents/Button';
import { useMixer } from '../MixerContext';
import { ButtonVariantEnum } from '../../../../shared/enums/buttonEnum';
import styles from './Navigation.module.css';

const Navigation = ({ isPreviousDisabled = false, isNextDisabled = false }) => {
  const { handleNextElement, handlePrevElement } = useMixer();

  return (
    <div className={styles.root}>
      <Button
        variant={ButtonVariantEnum.SECONDARY}
        leftIcon={<ArrowLeft />}
        onClick={handlePrevElement}
        disabled={isPreviousDisabled}
        className={styles.button}
      >
        Previous
      </Button>
      <Button
        variant={ButtonVariantEnum.SECONDARY}
        rightIcon={<ArrowRight />}
        onClick={handleNextElement}
        disabled={isNextDisabled}
        className={styles.button}
      >
        Next
      </Button>
    </div>
  );
};

export default Navigation;
