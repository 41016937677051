import React from 'react';
import cx from 'classnames';

import styles from '../styles/Cards.module.scss';

import Select from '../../../Components/FormComponents/Select';
import ResolutionButtons from '../../../Components/FormComponents/ResolutionButtons';
import { ReactComponent as ResolutionsIcon } from '../../../images/resolutions.svg';
import { useBannersCard } from '../hooks/google/useBannersCard';

const BannersCard = ({ disabled, onComplete }) => {
  const {
    loading,
    selectedGroup,
    selectedResolutions,
    showIndividual,
    setShowIndividual,
    availableResolutions,
    handleGroupChange,
    handleAllResolutions,
    handleResolutionToggle,
    groupOptions,
  } = useBannersCard({ onComplete });

  return (
    <div className={cx(styles.card, { [styles.disabled]: disabled })}>
      <h2 className={styles.title}>Banners</h2>
      <div className={styles.content}>
        <Select
          name="banners.group"
          value={selectedGroup?._id || ''}
          onChange={handleGroupChange}
          options={groupOptions}
          label="Banner group"
          disabled={disabled || loading}
        />

        <div className={styles.formGroup}>
          <label>Resolutions</label>
          <div className={styles.resolutionsWrapper}>
            {!showIndividual && (
              <>
                <button
                  type="button"
                  className={`${styles.allResolutionsButton} ${
                    selectedResolutions.length === availableResolutions.length
                      ? styles.active
                      : ''
                  }`}
                  onClick={handleAllResolutions}
                  disabled={disabled || loading}
                >
                  <ResolutionsIcon />
                  <span>All resolutions</span>
                </button>

                <button
                  type="button"
                  className={styles.individualResolutions}
                  onClick={() => setShowIndividual(true)}
                  disabled={disabled || loading}
                >
                  + Select individual
                </button>
              </>
            )}
          </div>

          {showIndividual && (
            <ResolutionButtons
              networkResolutions={availableResolutions}
              selectedResolutions={selectedResolutions}
              onResolutionToggle={handleResolutionToggle}
              disabled={disabled || loading}
              hideLabel
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BannersCard;
