import { Dispatcher as FluxDispatcher } from 'flux';

const Dispatcher = new FluxDispatcher();

Dispatcher.listen = function (event, callback) {
  this.register((payload) => {
    if (payload.event === event) {
      callback(payload.payload, event);
    }
  });
};

const Events = {
  // Banner Creator
  ADD_BANNER_ELEMENT: 'ADD_BANNER_ELEMENT',
  REMOVE_BANNER_ELEMENT: 'REMOVE_BANNER_ELEMENT',
  EDIT_BANNER_ELEMENT: 'EDIT_BANNER_ELEMENT',
  UPDATE_BANNER_ELEMENT: 'UPDATE_BANNER_ELEMENT',
  SET_BANNER_ELEMENT_INDEX: 'SET_BANNER_ELEMENT_INDEX',
  LOCK_BANNER_ELEMENT: 'LOCK_BANNER_ELEMENT',
  CHANGE_BANNER_BACKGROUND: 'CHANGE_BANNER_BACKGROUND',
  UPDATE_BANNER_LAYERS: 'UPDATE_BANNER_LAYERS',
  CAMPAIGN_SELECT: 'CAMPAIGN_SELECT',
  SEND_TO_MIXER: 'SEND_TO_MIXER',

  // Other events here
  // OPEN_MODAL_DIALOG: "OPEN_MODAL_DIALOG"
};

export { Dispatcher, Events };
