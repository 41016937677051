import React from 'react';
import { Floppy, BootstrapReboot, ArrowRepeat } from 'react-bootstrap-icons';
import { FontsProvider } from '../../../context/FontsContext';
import DefaultLayout from '../../../Components/DefaultLayout';
import TopBar from '../../TopBar/TopBar';
import Button from '../../../Components/FormComponents/Button';
import { ButtonVariantEnum } from '../../../shared/enums/buttonEnum';
import ResolutionButtons from '../../../Components/FormComponents/ResolutionButtons';
import { useMixer } from './MixerContext';
import Preview from './Preview/Preview';
import styles from './MixerContent.module.css';
import Navigation from './Components/Navigation';
import SelectedElement from './Components/SelectedElement';
import PreviewTopBar from './Components/PreviewTopBar';
import ElementOptions from './Components/ElementOptions';
import { MixerElement } from './Consts/DefaultElements';
import OverviewBanners from './Components/OverviewBanners/OverviewBanners';

const MixerContent = () => {
  const {
    bannerSet,
    isLoading,
    error,
    selectedResolution,
    allResolutions,
    handleResolutionToggle,
    resetPreview,
    handleSaveData,
    selectedElement,
    handleRestartButton,
  } = useMixer();

  if (isLoading) return <DefaultLayout>Loading...</DefaultLayout>;
  if (error) return <DefaultLayout>Error: {error}</DefaultLayout>;

  return (
    <DefaultLayout>
      <FontsProvider clientId={bannerSet?.campaign?.client?._id}>
        <div className={styles.root}>
          <TopBar
            title="Banner mixer"
            network={bannerSet?.platform}
            actions={
              <>
                <Button
                  leftIcon={<ArrowRepeat />}
                  variant={ButtonVariantEnum.TEXT}
                  onClick={resetPreview}
                >
                  Reset preview
                </Button>
                <Button
                  leftIcon={<BootstrapReboot />}
                  variant={ButtonVariantEnum.TEXT}
                  onClick={handleRestartButton}
                >
                  Restart
                </Button>
                <Button
                  leftIcon={<Floppy />}
                  variant={ButtonVariantEnum.TEXT}
                  onClick={handleSaveData}
                >
                  Save draft
                </Button>
              </>
            }
          />
          <div className={styles.contentWrapper}>
            <div className={styles.leftColumn}>
              <Navigation />
              <SelectedElement />
              <ElementOptions />
            </div>
            <div className={styles.rightPreviewWrapper}>
              <PreviewTopBar>
                <ResolutionButtons
                  networkResolutions={allResolutions}
                  selectedResolutions={
                    selectedResolution ? [selectedResolution] : []
                  }
                  onResolutionToggle={handleResolutionToggle}
                  hideLabel
                  darkerButtons
                />
              </PreviewTopBar>
              {selectedElement?.id === MixerElement.id ? (
                <OverviewBanners />
              ) : (
                <Preview />
              )}
            </div>
          </div>
        </div>
      </FontsProvider>
    </DefaultLayout>
  );
};

export default MixerContent;
