import React from 'react';
import MixerModal from './MixerModal';
import DefaultLayout from './DefaultLayout';
import ExtendedWrapperFromModalStyles from './styles/ExtendedWrapperFromModalStyles';

const Mixer = ({ setBanner, extractCSRF }) => {
  return (
    <DefaultLayout>
      <ExtendedWrapperFromModalStyles style={{ width: '700px' }}>
        <MixerModal
          setBanner={setBanner}
          extractCSRF={extractCSRF}
          cid={false}
        />
      </ExtendedWrapperFromModalStyles>
    </DefaultLayout>
  );
};

export default Mixer;
