import styled from 'styled-components';

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  aspect-ratio: ${(props) => props.aspectRatio};
  justify-content: center;
  align-content: center;
`;
