import React from 'react';
import { PlusSquare } from 'react-bootstrap-icons';
import Button from '../../../../../../Components/FormComponents/Button';
import styles from './TextElementSets.module.css';
import TextSet from './TextSet';

const TextElementSets = ({
  baseSet,
  customVariants,
  onRemoveSet,
  onAddNewSet,
  onPreviewClick,
  activeSet,
  handleEditVariant,
}) => {
  return (
    <div className={styles.root}>
      <TextSet variations={baseSet} notEditable label="Base" />
      {customVariants.map((textSet, index) => {
        return (
          <TextSet
            key={textSet.id}
            setId={textSet.id}
            variations={textSet.variations}
            label={`Variant ${index + 1}`}
            onRemove={() => onRemoveSet(textSet.id)}
            onPreview={onPreviewClick}
            isActive={activeSet === textSet.id}
            onEdit={handleEditVariant}
          />
        );
      })}
      <div>
        <Button onClick={onAddNewSet} leftIcon={<PlusSquare />}>
          Add new variation
        </Button>
      </div>
    </div>
  );
};

export default TextElementSets;
