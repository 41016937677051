import React from 'react';
import { XCircleFill } from 'react-bootstrap-icons';
import GeneralModalStyles from '../styles/GeneralModalStyles';

const DeleteModal = ({
  showModal,
  handleCloseModal,
  message,
  acceptFunction,
}) => {
  return (
    <GeneralModalStyles
      className="modal-wrapper"
      style={{ opacity: showModal ? 1 : 0 }}
    >
      <div className="modal-body" style={{ width: 'auto', maxWidth: '80vw' }}>
        <button
          type="button"
          onClick={handleCloseModal}
          className="modal-close-button"
        >
          <XCircleFill color="black" width={34} height={34} />
        </button>
        <h1 style={{ marginTop: '10px', textAlign: 'center' }}>
          Are you sure?
        </h1>
        <p style={{ fontSize: '16px', margin: '10px', marginBottom: '30px' }}>
          {message}
        </p>

        <div style={{ textAlign: 'right' }}>
          <button
            style={{ padding: '0.8rem 3rem', marginRight: '10px' }}
            onClick={handleCloseModal}
            className="inactive-button"
          >
            Cancel
          </button>
          <button
            onClick={acceptFunction}
            style={{ padding: '0.8rem 3rem' }}
            className="warning-button"
          >
            Yes
          </button>
        </div>
      </div>
    </GeneralModalStyles>
  );
};

export default DeleteModal;
