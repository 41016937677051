import React from 'react';
import { ReactComponent as FacebookIcon } from '../../images/logos/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../images/logos/instagram.svg';
import { ReactComponent as GoogleAdsIcon } from '../../images/logos/googleAds.svg';
import NetworkEnum from '../../shared/enums/networkEnum';
import styles from './SocialIcon.module.scss';

const networkIcons = {
  [NetworkEnum.FACEBOOK]: FacebookIcon,
  [NetworkEnum.INSTAGRAM]: InstagramIcon,
  [NetworkEnum.GOOGLE]: GoogleAdsIcon,
};

const SocialIcon = ({
  networks = [],
  useButton = false,
  onClick,
  size = 22,
}) => {
  return (
    <div className={styles.networks}>
      {networks.map((network) => {
        const NetworkIcon = networkIcons[network.toLowerCase()];

        return (
          NetworkIcon &&
          (useButton ? (
            <button
              key={network}
              type="button"
              className={styles.networkButton}
              onClick={() => onClick && onClick(network)}
              style={{ background: 'transparent', border: 'none', padding: 0 }}
            >
              <NetworkIcon
                width={size}
                height={size}
                className={styles.networkIcon}
              />
            </button>
          ) : (
            <NetworkIcon
              key={network}
              width={size}
              height={size}
              className={styles.networkIcon}
            />
          ))
        );
      })}
    </div>
  );
};

export default SocialIcon;
