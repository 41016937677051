export const prepareIdFromMongo = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => prepareIdFromMongo(item));
  }

  if (obj && typeof obj === 'object') {
    const newObj = { ...obj };

    if (obj._id) {
      newObj.id = obj._id;
    }

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object') {
        newObj[key] = prepareIdFromMongo(value);
      }
    }

    return newObj;
  }

  return obj;
};
