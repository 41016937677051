import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import fetchInstance from '../../../utils/fetchInstance';
import { useGalleryContext } from '../Gallery.context';

export const useGalleryListApi = () => {
  const {
    aiScores: { setAiScoresData },
    bannerSetId,
    bannerSet,
    selectedBannersIds,
    bannersList,
  } = useGalleryContext();

  const [aiBatchId, setAiBatchId] = useState(null);
  const [fetchingAiScores, setFetchingAiScores] = useState(false);

  useEffect(() => {
    if (bannerSet?.aiBatchId) {
      setAiBatchId(bannerSet.aiBatchId);
      setFetchingAiScores(true);
    }
  }, [bannerSet]);

  useEffect(() => {
    const getAiScores = async () => {
      try {
        let params = { batch: [aiBatchId] };
        params.per_page = 1000;

        const response = await fetchInstance('/getFilesAI', {
          method: 'POST',
          body: JSON.stringify(params),
        });

        if (!response.ok) {
          toast.error(response.statusText);
          return;
        }

        const responseJson = await response.json();

        setFetchingAiScores(Boolean(responseJson.processing));

        if (responseJson.images.length) {
          for (const image of responseJson.images) {
            setAiScoresData((prevState) => {
              const newMap = new Map(prevState);

              newMap.set(image.external_location, image.average_grade || 0);

              return newMap;
            });
          }
        }
      } catch (err) {
        toast.error(err.message);
      }
    };

    if (aiBatchId && fetchingAiScores) {
      void getAiScores();

      const interval = setInterval(() => {
        void getAiScores();
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [aiBatchId, fetchingAiScores]);

  const handleGenerateAiScores = async () => {
    let generatedBatchId;

    try {
      const response = await fetchInstance(`/generateBatchId/${bannerSetId}`, {
        method: 'POST',
      });

      if (!response.ok) {
        toast.error(response.statusText);
        return;
      }

      const responseJson = await response.json();

      if (responseJson.error) {
        toast.error(responseJson.message);
        return;
      }

      generatedBatchId = responseJson.aiBatchId;
      setAiBatchId(responseJson.aiBatchId);
    } catch (err) {
      toast.error(err.message);
    }

    try {
      const data = bannersList
        .filter(
          (selected) =>
            (selectedBannersIds.length &&
              selectedBannersIds.includes(selected._id)) ||
            !selectedBannersIds.length,
        )
        .flatMap((banner) => {
          return Object.values(banner.createdImgs).map((image) => ({
            path: image.src,
          }));
        });

      const response = await fetchInstance('/sendFilesAI', {
        method: 'POST',
        body: JSON.stringify({
          files: data,
          batch_id: generatedBatchId,
        }),
      });

      if (!response.ok) {
        toast.error(response.statusText);
        return;
      }

      const responseJson = await response.json();

      if (responseJson.success) {
        toast.success(
          `Campaign images successfully sent to A.I. for processing`,
        );
        setFetchingAiScores(true);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return { fetchingAiScores, handleGenerateAiScores };
};
