import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import fetchInstance from '../utils/fetchInstance';
import FormStyles from './styles/FormStyles';
import Title from './styles/TitleStyle';
import ExtendedWrapperFromModalStyles from './styles/ExtendedWrapperFromModalStyles';
import DefaultLayout from './DefaultLayout';
import FileUploader from './FileUploader/FileUploader';

class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      signup: {
        name: '',
        email: '',
        password: '',
        password_confirm: '',
      },
      busy: false,
      strength: 0,

      file: null,
      uploader: null,
      size: 'cover',
    };
  }

  handleInput = (e) => {
    const field = e.target.name;
    const section = { ...this.state.signup };
    section[field] = e.target.value;

    // Password strength checker
    let strength = this.state.strength;
    if (field === 'password') {
      strength = zxcvbn(e.target.value).score;
    }

    return this.setState({ signup: section, strength });
  };

  signup = (e) => {
    e.preventDefault();
    const user = { ...this.state.signup };

    if (user.password !== user.password_confirm) {
      return this.props.setBanner(true, 'bad', "Passwords don't match");
    }

    if (this.state.strength < 2) {
      return this.props.setBanner(true, 'bad', 'Password is too weak.');
    }

    this.setState({ busy: true });
    delete user.busy;
    fetchInstance('/signup', {
      method: 'post',
      body: JSON.stringify({ user }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.error) throw new Error(data.message);
        if (data.success) {
          this.setState({ busy: false });
          this.props.history.push('/login');
          return this.props.setBanner(
            true,
            'good',
            "Account created. You'll receive an email once it's approved by an admin",
            false,
          );
        }
      })
      .catch((err) => {
        this.setState({ busy: false });
        return this.props.setBanner(true, 'bad', err.message, false);
      });
  };

  setSelectedFile = (uploader, e) => {
    const signup = { ...this.state.signup };
    signup['profileImage'] = uploader.uploads[0].path;

    this.setState({
      signup: signup,

      file: uploader.uploads[0],
      uploader: uploader,
      bgName: uploader.uploads[0].filename,
    });
  };

  render() {
    const bgDimensions = {
      width: '100%',
      height: '250px',
      objectFit: 'cover',
    };

    return (
      <DefaultLayout>
        <ExtendedWrapperFromModalStyles>
          <FormStyles onSubmit={this.signup}>
            <div className="back-button">
              <Link to="/login">Back</Link>
            </div>

            <Title>Sign up</Title>

            <label htmlFor="name">Name</label>
            <div className="group">
              <input
                type="text"
                name="name"
                value={this.state.signup.name}
                onChange={this.handleInput}
                required
              />
              <span />
            </div>

            <div className="group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={this.state.signup.email}
                onChange={this.handleInput}
                required
              />
              <span />
            </div>

            <div className="group">
              <label>Profile Picture</label>
              <FileUploader
                dimensions={bgDimensions}
                uploads={[{ path: this.state.signup.profileImage }]}
                onChange={this.setSelectedFile}
              />
            </div>

            <div className="group">
              <div style={{ display: 'flex' }}>
                <label htmlFor="password">Password</label>
                <b style={{ marginLeft: 'auto' }}>
                  {`Strength: ${this.state.strength} / 4 ${
                    this.state.signup.password
                      ? this.state.strength < 2
                        ? '❌'
                        : '✔'
                      : ''
                  }`}
                </b>
              </div>
              <input
                type="password"
                name="password"
                value={this.state.signup.password}
                onChange={this.handleInput}
                required
              />
              <span />
            </div>

            <div className="group">
              <label htmlFor="password_confirm">Confirm password</label>
              <input
                type="password"
                name="password_confirm"
                value={this.state['password_confirm']}
                onChange={this.handleInput}
                required
              />
              <span />
            </div>

            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              <button type="submit" className="confirm-button">
                Sign up
              </button>
            </div>
          </FormStyles>
        </ExtendedWrapperFromModalStyles>
      </DefaultLayout>
    );
  }
}

export default withRouter(SignUp);
