import React, { Fragment, useMemo, useRef, useState } from 'react';
import { ArrowsFullscreen, Robot } from 'react-bootstrap-icons';
import classNames from 'classnames';
import galleryStyles from '../Gallery.module.scss';
import { useGalleryContext } from '../Gallery.context';
import FlexContainer from '../../styles/FlexContainer';
import { StackedResolution } from '../../FormComponents/ResolutionButtons';
import { generateBannerGroupStartElement } from '../utils/generateBannerGroupsStartElement';
import { useOutsideClick } from '../../../hooks/useClickOutside';
import styles from './GalleryCard.module.scss';
import { StackedImages } from './StackedImages';
import { ImageContainer } from './styled/ImageContainer';

/**
 * @param {*} banner
 * @returns {Element}
 */

export const GalleryCard = ({ banner }) => {
  const {
    aiScores: { showAiScores, aiScoresData },
    filters: { selectedResolution: selectedResolutionString },
    bannerGroupsMap,
    selectedBannersIds,
    setSelectedBannersIds,
  } = useGalleryContext();

  const [previewOpen, setPreviewOpen] = useState(false);

  const isBannerSelected = useMemo(
    () => selectedBannersIds.includes(banner._id),
    [selectedBannersIds],
  );

  const selectedResolution = useMemo(() => {
    if (selectedResolutionString === StackedResolution) {
      const width = 260;
      const height = 140;

      return {
        width,
        height,
        aspectRatio: width / height,
      };
    }

    const [widthStr, heightStr] = selectedResolutionString.split('x');

    return {
      width: Number(widthStr),
      height: Number(heightStr),
    };
  }, [selectedResolutionString]);

  const selectedResolutionImage = useMemo(
    () => banner.createdImgs?.[selectedResolutionString],
    [banner, selectedResolutionString],
  );

  const allImages = useMemo(
    () => Object.values(banner.createdImgs || {}).filter(Boolean),
    [banner],
  );

  const previewImages = useMemo(() => {
    if (selectedResolutionString === StackedResolution) {
      return allImages;
    }

    return [selectedResolutionImage];
  }, [allImages, selectedResolutionImage, selectedResolutionString]);

  const togglePreviewRef = useRef(null);

  const ref = useOutsideClick(() => {
    if (
      togglePreviewRef.current &&
      !togglePreviewRef.current.contains(event.target)
    ) {
      setPreviewOpen(false);
    }
  });

  /**
   * Variable that stores the banner set groups icons
   * @type {ReactElement[]}
   */
  const currentBannerGroupsIcons = useMemo(() => {
    const currentBannerGroups = bannerGroupsMap[banner._id];

    if (!currentBannerGroups?.length) {
      return [];
    }

    return currentBannerGroups.map((group) =>
      generateBannerGroupStartElement(group),
    );
  }, [bannerGroupsMap]);

  const toggleBannerSelection = (event) => {
    event.stopPropagation();

    setSelectedBannersIds((prev) => {
      if (prev.includes(banner._id)) {
        return prev.filter((id) => id !== banner._id);
      }

      return [...prev, banner._id];
    });
  };

  const togglePreview = (event) => {
    event.stopPropagation();
    setPreviewOpen((prev) => !prev);
  };

  const BannerAiScore = () => {
    if (!showAiScores) {
      return null;
    }

    const aiScores =
      selectedResolutionString === StackedResolution
        ? allImages.map((image) => aiScoresData.get(image.src))
        : [aiScoresData.get(selectedResolutionImage.src)];

    const filteredAiScores = aiScores.filter(Boolean);

    if (!filteredAiScores.length) {
      return null;
    }

    if (
      selectedResolutionString === StackedResolution &&
      filteredAiScores.length !== allImages.length
    ) {
      return null;
    }

    const sum = filteredAiScores.reduce((a, b) => a + b, 0);
    const averageScore = (sum / filteredAiScores.length).toFixed(4) || 0;

    return (
      <FlexContainer gap="5px" alignItems="center">
        <Robot size={16} className="color-primary" />
        <span className={styles.aiScoreText}>
          {aiScores.length > 1 ? `~ ${averageScore}` : averageScore}
        </span>
      </FlexContainer>
    );
  };

  const Preview = () => {
    return (
      <div
        ref={ref}
        className={styles.previewContainer}
        onClick={(event) => event.stopPropagation()}
      >
        <FlexContainer direction="column" justifyContent="space-between">
          {previewImages.map((image, idx) => {
            const aiScore = aiScoresData.get(image.src);

            return (
              <div
                key={`image-${idx}`}
                className={styles.previewImageContainer}
              >
                <img
                  src={image.src}
                  alt="gallery image"
                  className={styles.previewImage}
                />

                {Boolean(aiScore) && (
                  <FlexContainer gap="5px" className={styles.aiScoreContainer}>
                    <Robot size={16} />
                    <span className={styles.aiScoreText}>{aiScore}</span>
                  </FlexContainer>
                )}
              </div>
            );
          })}
        </FlexContainer>
      </div>
    );
  };

  return (
    <>
      <FlexContainer
        direction="column"
        gap="10px"
        className={classNames(
          galleryStyles.card,
          styles.card,
          isBannerSelected && styles.selected,
        )}
        onClick={toggleBannerSelection}
      >
        <FlexContainer justifyContent="space-between">
          <FlexContainer direction="row">
            {currentBannerGroupsIcons.length > 0 && (
              <FlexContainer gap="5px" direction="row" alignItems="center">
                {currentBannerGroupsIcons.map((iconElement, idx) => (
                  <Fragment key={`group-icon-${idx}`}>{iconElement}</Fragment>
                ))}
              </FlexContainer>
            )}
            <BannerAiScore />
          </FlexContainer>
          <ArrowsFullscreen
            ref={togglePreviewRef}
            cursor="pointer"
            height={16}
            width={16}
            onClick={togglePreview}
          />
        </FlexContainer>

        <ImageContainer aspectRatio={selectedResolution.aspectRatio}>
          {selectedResolutionString === StackedResolution ? (
            <StackedImages images={allImages} initialHeight={140} />
          ) : (
            <img
              src={selectedResolutionImage?.src}
              alt="gallery image"
              className={styles.image}
            />
          )}
        </ImageContainer>

        {previewOpen && <Preview />}
      </FlexContainer>
    </>
  );
};
