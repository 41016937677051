import React, { useRef } from 'react';
import {
  Ban,
  CloudUpload,
  X,
  Shuffle,
  ArrowBarDown,
} from 'react-bootstrap-icons';
import cx from 'classnames';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../../shared/enums/buttonEnum';
import Button from '../../../../../../Components/FormComponents/Button';
import styles from './ImageElement.module.css';

const calculateAspectRatioDimensions = (resolution) => {
  const [width, height] = resolution.split('x').map(Number);

  if (isNaN(width) || isNaN(height)) {
    return { width: 0, height: 0 };
  }

  const maxSize = 110;
  const aspectRatio = width / height;

  let calculatedWidth, calculatedHeight;

  if (width > height) {
    calculatedWidth = Math.min(maxSize, width);
    calculatedHeight = calculatedWidth / aspectRatio;
  } else {
    calculatedHeight = Math.min(maxSize, height);
    calculatedWidth = calculatedHeight * aspectRatio;
  }

  if (calculatedWidth > maxSize) {
    calculatedWidth = maxSize;
    calculatedHeight = calculatedWidth / aspectRatio;
  } else if (calculatedHeight > maxSize) {
    calculatedHeight = maxSize;
    calculatedWidth = calculatedHeight * aspectRatio;
  }

  return {
    width: calculatedWidth,
    height: calculatedHeight,
  };
};

const ImageElement = ({
  setId,
  resolution,
  data,
  preserveResolutionAspect,
  notEditable,
  isBaseSet,
  isActivePreviewSet,
  onUpload,
  onRemove,
  onDisable,
  onCopyMainToAll,
  onCopyFromMain,
}) => {
  const { width, height } = preserveResolutionAspect
    ? calculateAspectRatioDimensions(resolution)
    : { width: 110, height: 110 };
  const isDisabled = isBaseSet ? !data.src : data.status !== 'enabled';
  const showActions = (notEditable && isDisabled) ?? true;
  const fileInputRef = useRef(null);

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      onUpload(files, { resolution, setId, isActivePreviewSet });
    }
  };

  const handleDisable = () => {
    onDisable({ resolution, setId, isDisabled, isActivePreviewSet });
  };

  const handleRemove = () => {
    onRemove({ resolution, setId, isActivePreviewSet });
  };

  const handleCloudUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const overlayMenu = [
    ...(data.src
      ? [
          {
            label: 'Replace',
            icon: CloudUpload,
            onClick: handleCloudUploadClick,
          },
          {
            label: 'Remove',
            icon: X,
            onClick: handleRemove,
          },
        ]
      : [
          {
            label: 'Upload',
            icon: CloudUpload,
            onClick: handleCloudUploadClick,
          },
        ]),
    {
      label: isDisabled ? 'Enable' : 'Disable',
      icon: Ban,
      onClick: handleDisable,
    },
  ];

  return (
    <div className={cx(styles.root, isDisabled && styles.disabled)}>
      <div className={styles.top}>
        <div className={styles.resolutionLabel}>{resolution}</div>
        <div className={styles.actions}>
          {showActions && onCopyMainToAll && (
            <Button
              type="button"
              variant={ButtonVariantEnum.TEXT}
              onClick={onCopyMainToAll}
              size={ButtonSizeEnum.XS}
            >
              <Shuffle />
            </Button>
          )}
          {showActions && onCopyFromMain && (
            <Button
              type="button"
              variant={ButtonVariantEnum.TEXT}
              onClick={() => onCopyFromMain(resolution)}
              size={ButtonSizeEnum.XS}
            >
              <ArrowBarDown />
            </Button>
          )}
          {showActions && isDisabled && (
            <Button
              className={styles.disableButton}
              variant={ButtonVariantEnum.TEXT}
              size={ButtonSizeEnum.XS}
              onClick={handleDisable}
            >
              <Ban />
            </Button>
          )}
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <div className={styles.imageContainer}>
          {!data.src && (
            <>
              <div className={styles.aspectRatioContainer}>
                <div
                  className={styles.aspectRatioWrapper}
                  style={{ width: `${width}px`, height: `${height}px` }}
                >
                  <div className={styles.aspectRatioIcon} />
                </div>
              </div>
              <CloudUpload
                className={styles.cloudUpload}
                size={24}
                onClick={handleCloudUploadClick}
              />
            </>
          )}
          {data.src && (
            <img
              src={data.src}
              alt={`Background ${resolution}`}
              className={styles.image}
              style={
                preserveResolutionAspect && {
                  width: `${width}px`,
                  height: `${height}px`,
                }
              }
            />
          )}
        </div>
        <div className={styles.overlayMenu}>
          {overlayMenu.map((item) => (
            <div key={item.label}>
              <Button
                variant={ButtonVariantEnum.TEXT}
                size={ButtonSizeEnum.S}
                onClick={item.onClick}
                leftIcon={<item.icon />}
                className={styles.overlayMenuItem}
              >
                {item.label}
              </Button>
            </div>
          ))}
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept=".png,.jpg,.jpeg,.svg,.gif"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
      </div>
    </div>
  );
};

export default ImageElement;
