import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Title from './styles/TitleStyle';
import DefaultLayout from './DefaultLayout';

class PrivacyPolicy extends Component {
  render() {
    return (
      <DefaultLayout>
        <div className="privacyPolicyContainer">
          <Title>Privacy Policy for Omniperform App</Title>
          <p>Effective Date: 29.10.2024</p>
          <h2>1. Introduction</h2>
          <p>
            Omniperform is committed to protecting your privacy. This Privacy
            Policy explains how we collect, use, share, and protect your
            personal information when you use our services, including our
            website, mobile applications, and any other products or services
            that link to this policy (collectively, the "Services").
          </p>
          <h2>2. Information We Collect</h2>
          <p>We may collect the following types of information:</p>
          <ul>
            <li>
              <p>
                Personal Information: Information that identifies you as an
                individual, such as your name, email address, phone number, and
                payment details.
              </p>
            </li>
            <li>
              <p>
                Usage Data: Information about how you interact with our
                Services, including your IP address, browser type, operating
                system, device information, and pages visited.
              </p>
            </li>
            <li>
              <p>
                Cookies and Tracking Technologies: We use cookies and similar
                technologies to collect information about your use of our
                Services and to provide a personalized experience.
              </p>
            </li>
          </ul>
          <h2>3. How We Use Your Information</h2>
          <p>We may use your information for the following purposes:</p>
          <ul>
            <li>
              <p>
                To Provide and Improve Our Services: To operate, maintain, and
                enhance our Services, including analyzing usage and improving
                functionality.
              </p>
            </li>
            <li>
              <p>
                To Communicate with You: To send you updates, newsletters,
                promotional materials, and other information related to our
                Services.
              </p>
            </li>
            <li>
              <p>
                For Security and Fraud Prevention:** To protect our Services and
                users from fraudulent activity and security risks.
              </p>
            </li>
            <li>
              <p>
                For Legal Compliance: To comply with legal obligations, resolve
                disputes, and enforce our agreements.
              </p>
            </li>
          </ul>
          <h2>4. How We Share Your Information</h2>
          <p>
            We do not sell your personal information. We may share your
            information with:
          </p>
          <ul>
            <li>
              <p>
                Service Providers: Third-party vendors who assist us in
                providing our Services, such as payment processors, analytics
                providers, and marketing partners.
              </p>
            </li>
            <li>
              <p>
                Legal Authorities: If required by law, or if we believe in good
                faith that such action is necessary to comply with legal
                obligations, protect our rights, or prevent fraud.
              </p>
            </li>
          </ul>
          <h2>5. Your Choices</h2>
          <p>
            You have the following choices regarding your personal information:
          </p>
          <ul>
            <li>
              <p>
                Access and Update: You can access and update your personal
                information by logging into your account or contacting us
                directly.
              </p>
            </li>
            <li>
              <p>
                Opt-Out: You can opt out of receiving promotional emails by
                following the unsubscribe instructions in those emails. Please
                note that you may still receive non-promotional communications
                related to your account or our Services.
              </p>
            </li>
            <li>
              <p>
                Cookies: You can adjust your browser settings to refuse cookies
                or to alert you when cookies are being sent. However, some
                features of our Services may not function properly without
                cookies.
              </p>
            </li>
          </ul>
          <h2>6. Security</h2>
          <p>
            We implement reasonable security measures to protect your personal
            information. However, no method of transmission over the internet or
            electronic storage is 100% secure, and we cannot guarantee absolute
            security.
          </p>
          <h2>7. Children's Privacy</h2>
          <p>
            Our Services are not intended for children under the age of 13. We
            do not knowingly collect personal information from children under
            13. If you believe we have collected such information, please
            contact us so we can delete it.
          </p>
          <h2>8. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new policy on our website and
            updating the effective date. Your continued use of the Services
            after such changes constitutes your acceptance of the updated
            Privacy Policy.
          </p>
          <h2>9. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <p>
            Omniperform
            <br />
            <a href="mailto:eu.office@omniperform.com">
              eu.office@omniperform.com
            </a>
            <br />
            <a href="mailto:us.office@omniperform.com">
              us.office@omniperform.com
            </a>
          </p>
        </div>
        <style type="text/css">
          {`
            .privacyPolicyContainer {
              max-width: 800px;
              margin: 50px auto;
            }
            .privacyPolicyContainer h1,
            .privacyPolicyContainer h2,
            .privacyPolicyContainer h3,
            .privacyPolicyContainer h4,
            .privacyPolicyContainer h5,
            .privacyPolicyContainer h6,
            .privacyPolicyContainer p {
              margin: 0 0 10px;
            }
            .privacyPolicyContainer h2 {
              font-size: 2rem;
            }
            .privacyPolicyContainer ul {
              margin: 0 0 10px;
              padding-inline-start: 15px;
            }
            .privacyPolicyContainer a {
              color: blue;
              text-decoration: underline;
            }
            .privacyPolicyContainer li p {
              margin: 0 0 10px;
            }
            .privacyPolicyContainer li p:last-child {
              margin-bottom: 0;
            }
          `}
        </style>
      </DefaultLayout>
    );
  }
}

export default withRouter(PrivacyPolicy);
