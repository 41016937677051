import React, { Component } from 'react';
import CampaignPageStyles from './styles/CampaignPageStyles';
import CampaignNav from './CampaignNav';

export default class CampaignPage extends Component {
  render() {
    return (
      <CampaignPageStyles>
        <CampaignNav />
        {this.props.children}
      </CampaignPageStyles>
    );
  }
}
