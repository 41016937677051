import React from 'react';
import { LayoutWtf } from 'react-bootstrap-icons';
import toast from 'react-hot-toast';
import Button from '../../../../../Components/FormComponents/Button';
import { ButtonVariantEnum } from '../../../../../shared/enums/buttonEnum';
import { useMixer } from '../../MixerContext';
import styles from './BulkUpload.module.css';
import BulkUploadModalBody from './ModalBody';

export const BulkUpload = ({ isBackground, elementId }) => {
  const {
    allResolutions,
    handleAddCustomBackgroundVariant,
    handleAddElementVariant,
  } = useMixer();

  const handleAddVariant = (variations) => {
    if (isBackground) {
      handleAddCustomBackgroundVariant(variations);
    } else {
      handleAddElementVariant({
        ...variations,
        baseId: elementId,
      });
    }
  };

  const onBulkButton = () => {
    toast(
      (t) => (
        <BulkUploadModalBody
          toastId={t.id}
          allResolutions={allResolutions}
          handleAddVariant={handleAddVariant}
          isBackground={isBackground}
        />
      ),
      {
        className: styles.modal,
        duration: Infinity,
        position: 'top-center',
      },
    );
  };

  return (
    <div className={styles.root}>
      <Button
        variant={ButtonVariantEnum.SECONDARY}
        leftIcon={<LayoutWtf />}
        onClick={onBulkButton}
      >
        Bulk Upload
      </Button>
    </div>
  );
};
