import React, { Component } from 'react';
import CampaignPageStyles from './styles/CampaignPageStyles';
import ClientNav from './ClientNav';
import ClientContext from './ClientContext';

export default class ClientPage extends Component {
  render() {
    return (
      <ClientContext.Consumer>
        {(context) => (
          <CampaignPageStyles>
            <ClientNav
              activeClient={context.activeClient}
              addClient={context.addClient}
              setActiveClient={context.setActiveClient}
              visibleClients={context.visibleClients}
              handleSearchCampaigns={context.handleSearchCampaignsInput}
              InputshowingCampaignDrawer={true}
            />
            {this.props.children}
          </CampaignPageStyles>
        )}
      </ClientContext.Consumer>
    );
  }
}
