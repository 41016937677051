import React from 'react';
import { Close } from '@material-ui/icons';
import styles from '../Gallery.module.scss';
import { GroupColorBox } from '../GalleryCard/styled/GroupColorBox';

export const generateBannerGroupStartElement = (group) => {
  const groupName = group.name;

  if (groupName.toLowerCase() === 'rejected') {
    return <Close className={styles.closeIcon} />;
  }

  return <GroupColorBox color={group.color} />;
};
