import React from 'react';
import { Shuffle, ArrowBarDown, ChevronDown } from 'react-bootstrap-icons';
import cx from 'classnames';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../../shared/enums/buttonEnum';
import Button from '../../../../../../Components/FormComponents/Button';
import Input from '../../../../../../Components/FormComponents/Input';
import styles from './AdvancedSection.module.css';

const AdvancedSection = ({
  isOpen,
  onToggle,
  isMainResolution,
  selectedResolution,
  onCopyToAll,
  onCopyFromMain,
  currentOptions,
  onClassChange,
  onStyleChange,
  isDisabled,
  hideButton = false,
}) => {
  return (
    <div className={cx(styles.root, isDisabled && styles.disabled)}>
      {!hideButton && (
        <div>
          <Button
            variant={ButtonVariantEnum.TEXT}
            onClick={onToggle}
            rightIcon={<ChevronDown />}
            size={ButtonSizeEnum.S}
            className={cx(
              styles.advancedButton,
              isOpen && styles.toggledCustomCss,
            )}
          >
            Advanced options
          </Button>
        </div>
      )}
      {isOpen && (
        <>
          {(onCopyToAll || onCopyFromMain) && (
            <div>
              {isMainResolution && (
                <Button
                  type="button"
                  variant={ButtonVariantEnum.TEXT}
                  onClick={onCopyToAll}
                  size={ButtonSizeEnum.XS}
                  leftIcon={<Shuffle />}
                >
                  Copy to all resolutions
                </Button>
              )}
              {!isMainResolution && (
                <Button
                  variant={ButtonVariantEnum.TEXT}
                  onClick={() => onCopyFromMain(selectedResolution)}
                  size={ButtonSizeEnum.XS}
                  leftIcon={<ArrowBarDown />}
                >
                  Copy from main
                </Button>
              )}
            </div>
          )}
          <Input
            label="Mixer Identifier"
            value={currentOptions?.class || ''}
            onChange={onClassChange}
            className={styles.input}
            name="class"
          />
          <Input
            label="Custom CSS"
            value={currentOptions?.style || ''}
            onChange={onStyleChange}
            isTextarea
            textareaProps={{
              rows: 5,
            }}
            className={styles.input}
            name="style"
          />
        </>
      )}
    </div>
  );
};

export default AdvancedSection;
