import styled from 'styled-components';

const StyledHeader = styled.header`
  border-bottom: 1px solid ${(props) => props.theme.lightGrey};
  margin-bottom: 46px;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  padding: 0 2rem;
  /* align-items: stretch; */
  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  background: #fff;
`;

const Logo = styled.span`
  margin: 2rem 0;
  height: 40px;
  img {
    height: 100%;
  }
  @media (max-width: 1300px) {
    margin: 0;
    text-align: center;
  }
`;

const StyledNav = styled.ul`
  font-size: 2rem;
  margin: 0;
  padding: 0;
  justify-self: end;
  display: flex;

  .active {
    color: ${(props) => props.theme.lightBlue}!important;
    position: relative;
  }

  a:not(.sublink),
  .submenu-link-wrapper {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Open Sans';
    line-height: 24px;
    text-transform: uppercase;
    padding: 1rem 4rem;
    background: none;
    color: ${(props) => props.theme.black};
    cursor: pointer;
    border: none;
    /* border: solid 1px black; */
    display: flex;
    align-items: center;
    position: relative;
    transition: color 0.1s;

    &:hover {
      color: ${(props) => props.theme.lightBlue};
    }

    text-transform: capitalize;

    @media (max-width: 700px) {
      font-size: 1.5rem;
      padding: 0 10px;
    }

    &:before {
      content: '';
      width: 2px;
      height: 13px;
      background: ${(props) => props.theme.black};
      position: absolute;
      bottom: 0;
      left: 0%;
      top: 50%;
      -webkit-transform: translate(-0%, -50%);
      transform: translate(-0%, -50%);
    }

    &:hover,
    &:focus {
      outline: none;
      &:after {
        width: calc(100% - 60px);
      }
    }

    &:first-child {
      &:before {
        content: none;
      }
    }
  }
  @media (max-width: 1300px) {
    border-top: 1px solid ${(props) => props.theme.lightGrey};
    width: 100%;
    justify-content: center;
    font-size: 1.7rem;
  }
`;

const SubMenu = styled.ul`
  background-color: #fff;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 999999;
  padding: 1rem 2rem;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  box-shadow:
    0 4px 15px rgb(0 0 0 / 7%),
    0 1px 3px rgb(0 0 0 / 7%);
  width: 80%;
  margin-left: 10%;

  a {
    font-size: 14px;
    display: block;
    margin: 4px 0;

    &:hover {
      color: ${(props) => props.theme.lightBlue};
    }
  }
`;

export { Logo, StyledHeader, StyledNav, SubMenu };
