import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import fetchInstance from '../utils/fetchInstance';
import defaultFonts from '../shared/defaults/fontFamilies';

export const FontsContext = createContext(null);

export const FontsProvider = ({ children, clientId }) => {
  const [fonts, setFonts] = useState(defaultFonts);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadFont = useCallback(
    async (name) => {
      const font = fonts[name];
      if (!font) return name;

      if (font.isSystem) return name;

      if (font.loaded) return name;

      try {
        if (font.isGoogle) {
          const link = document.createElement('link');
          link.href = font.url;
          link.rel = 'stylesheet';
          document.head.appendChild(link);

          await document.fonts.ready;
        } else {
          const fontFace = new FontFace(name, `url(${font.path})`);
          const loadedFace = await fontFace.load();
          document.fonts.add(loadedFace);
        }

        setFonts((prev) => ({
          ...prev,
          [name]: { ...prev[name], loaded: true },
        }));
      } catch (error) {
        console.error('Error loading font face:', error);
      }

      return name;
    },
    [fonts],
  );

  const uploadFont = useCallback(
    async (file) => {
      try {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('persistent', 'true');
        formData.append('multiple', 'false');

        const uploadResponse = await fetchInstance('/store', {
          method: 'POST',
          body: formData,
        });

        const uploadData = await uploadResponse.json();

        if (!uploadResponse.ok || !uploadData.files?.[0]) {
          throw new Error('Failed to upload file');
        }

        const uploadedFile = uploadData.files[0];

        const fontResponse = await fetchInstance('/font', {
          method: 'POST',
          body: JSON.stringify({
            font: {
              client: clientId,
              name: file.name.split('.')[0],
              filename: file.name,
              path: uploadedFile.path,
              size: file.size,
              type: file.type || 'application/octet-stream',
            },
          }),
        });

        const fontData = await fontResponse.json();

        if (!fontResponse.ok) {
          throw new Error(fontData.error || 'Failed to create font');
        }

        setFonts((prev) => ({
          ...prev,
          [fontData.font.name]: {
            ...fontData.font,
            loaded: false,
          },
        }));

        return fontData.font;
      } catch (error) {
        console.error('Error uploading font:', error);
        throw error;
      }
    },
    [clientId],
  );

  const deleteFont = useCallback(async (fontId) => {
    try {
      const response = await fetchInstance(`/deleteFont/${fontId}`, {
        method: 'DELETE',
      });
      const data = await response.json();

      if (!response.ok) throw new Error(data.error);

      setFonts((prev) => {
        const newFonts = { ...prev };
        delete newFonts[data.font.name];
        return newFonts;
      });

      return data.font;
    } catch (error) {
      console.error('Error deleting font:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    const fetchFonts = async () => {
      if (!clientId) return;

      try {
        setIsLoading(true);
        const response = await fetchInstance(`/getFonts/${clientId}`);
        const data = await response.json();

        if (!response.ok) throw new Error(data.error);

        const customFonts = data.reduce(
          (acc, font) => ({
            ...acc,
            [font.name]: { ...font, loaded: false },
          }),
          {},
        );

        setFonts((prev) => ({
          ...prev,
          ...customFonts,
        }));
      } catch (error) {
        console.error('Error fetching fonts:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFonts();
  }, [clientId]);

  return (
    <FontsContext.Provider
      value={{
        fonts,
        isLoading,
        error,
        loadFont,
        uploadFont,
        deleteFont,
      }}
    >
      {children}
    </FontsContext.Provider>
  );
};

export const useFonts = () => {
  const context = useContext(FontsContext);
  if (!context) {
    throw new Error('useFonts must be used within a FontsProvider');
  }
  return context;
};
