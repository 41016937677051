import toast from 'react-hot-toast';
import BannerElementType from '../../../../shared/enums/bannerElementType';

const hasInvalidVariations = (variations) => {
  return Object.values(variations).some(
    (variation) => !variation.src && variation.status !== 'disabled',
  );
};

const validateElementVariations = ({
  element,
  DefaultElements,
  customBackgroundVariants,
  elementVariants,
}) => {
  if (element.id === DefaultElements.background.id) {
    const invalidBackground = customBackgroundVariants.some((variant) =>
      hasInvalidVariations(variant.variations),
    );

    if (invalidBackground) {
      toast.error(
        'Please add images for all enabled background variations or disable the empty ones',
      );
      return false;
    }
  }

  if (element.type === BannerElementType.IMAGE) {
    const elementVariationsForCurrentElement = elementVariants.filter(
      (variant) => variant.baseId === element.id,
    );

    const invalidElement = elementVariationsForCurrentElement.some((variant) =>
      hasInvalidVariations(variant.variations),
    );

    if (invalidElement) {
      toast.error(
        'Please add images for all enabled variations or disable the empty ones',
      );
      return false;
    }
  }

  return true;
};

export default validateElementVariations;
