import React, { useState } from 'react';
import { X } from 'react-bootstrap-icons';
import cx from 'classnames';
import { useMixer } from '../../MixerContext';
import Button from '../../../../../Components/FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../shared/enums/buttonEnum';
import styles from './Tags.module.css';
import FieldWrapper from './FieldWrapper';

const Tag = ({ tag, onRemove, index, name, isPreview }) => {
  const { handlePreviewOption } = useMixer();

  const handleClick = (e) => {
    e.stopPropagation();
    handlePreviewOption({ name, value: tag });
  };

  return (
    <div
      className={cx(styles.tag, isPreview && styles.previewActive)}
      onClick={handleClick}
    >
      <span className={styles.tagText}>{tag}</span>
      <Button
        className={styles.removeButton}
        onClick={() => onRemove(index)}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.XS}
      >
        <X />
      </Button>
    </div>
  );
};

export const TagsField = ({ label, name }) => {
  const { handleOptionChange, rootOptions, previewOptions } = useMixer();
  const [inputValue, setInputValue] = useState('');

  const values = rootOptions[name] || [];

  const addNewTag = (tag) => {
    if (tag.trim()) {
      handleOptionChange(name, [...values, tag.trim()]);
    }
    setInputValue('');
  };

  const removeTag = (indexToRemove) => {
    handleOptionChange(
      name,
      values.filter((_, index) => index !== indexToRemove),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addNewTag(inputValue);
    }
  };

  const handleBlur = () => {
    addNewTag(inputValue);
  };

  return (
    <FieldWrapper label={label}>
      <div className={styles.root}>
        {values.length > 0 && (
          <div className={styles.tagsList}>
            {values.map((tag, index) => (
              <Tag
                key={`${tag}-${index}`}
                tag={tag}
                index={index}
                name={name}
                onRemove={removeTag}
                isPreview={previewOptions[name] === tag}
              />
            ))}
          </div>
        )}
        <input
          type="text"
          className={styles.input}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          placeholder={`Type and press Enter to add ${label}...`}
        />
      </div>
    </FieldWrapper>
  );
};

export default TagsField;
