import { useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import fetchInstance from '../../../../utils/fetchInstance';

export const useGoogleAds = ({ unlockedSteps, setUnlockedSteps }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const validateSubmission = (values) => {
    const isValid =
      values.destination.account &&
      values.destination.campaign &&
      values.destination.adGroup &&
      values.banners.group &&
      values.banners.resolutions?.length > 0 &&
      values.adDetails.namePrefix &&
      values.adDetails.displayUrl &&
      values.adDetails.finalUrl;

    return isValid;
  };

  const handleStepComplete = useCallback(
    (step, values) => {
      switch (step) {
        case 'destination':
          if (
            values.destination.account &&
            values.destination.campaign &&
            values.destination.adGroup
          ) {
            setCurrentStep(2);
            if (!unlockedSteps.includes(2)) {
              setUnlockedSteps((prev) => [...prev, 2]);
            }
          }
          break;
        case 'banners':
          if (values.banners.group && values.banners.resolutions?.length > 0) {
            setCurrentStep(3);
            if (!unlockedSteps.includes(3)) {
              setUnlockedSteps((prev) => [...prev, 3]);
            }
          }
          break;
        case 'adDetails':
          if (
            values.adDetails.name &&
            values.adDetails.finalUrl &&
            values.adDetails.headline &&
            values.adDetails.primaryText
          ) {
            setCurrentStep(4);
          }
          break;
        default:
          break;
      }
    },
    [unlockedSteps],
  );

  const handleSubmit = useCallback(async (values) => {
    try {
      setLoading(true);

      if (!validateSubmission(values)) {
        throw new Error('Please fill in all required fields');
      }

      const payload = {
        ...values.adDetails,
        destinationData: values.destination,
        bannerData: values.banners,
      };

      const response = await fetchInstance('/createGoogleAd', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to create Google ad');
      }

      await response.json();
      toast.success('Google ad created successfully');
    } catch (error) {
      toast.error(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    currentStep,
    setCurrentStep,
    handleSubmit,
    loading,
    handleStepComplete,
    validateSubmission,
  };
};
