module.exports = {
  apiUrl: process.env.REACT_APP_API_BACKEND_URL || 'http://localhost:3000',
  apiFbUrl: process.env.REACT_APP_API_FB_URL || 'http://localhost:8000',
  apiGoogleUrl: process.env.REACT_APP_API_GOOGLE_URL || 'http://localhost:8001',
  proxyApiRoute: '/api',
  bannerCreatorVersion: '2.0.0',
  ITEMS_PER_PAGE: 8,
  appRoutes: {
    bannerCreator: '/banner-creator',
    bannerMixer: '/mixer',
    bannerMixing: '/mixing',
    bannerSet: '/banner-set',
    gallery: '/gallery',
  },
  bannerWizard: {
    maxHeight: 800,
  },
};
