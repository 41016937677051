import { createGlobalStyle } from 'styled-components';

const globalTheme = {
  green: '#6dd92bdb',
  black: '#393939',
  grey: '#3A3A3A',
  lightGrey: ' #E1E1E1',
  lightBlue: '#52bad5',
  deepBlue: '#2B3990',
  lightBlueHover: '#349cb7',
  facebookBlue: '#4267b2',
  boxShadow: '0 1px 3px 0 #393939',

  // newest
  red: '#e64c0d',
  blue: 'rgb(82, 186, 213)',
  borderColor: '#e0e0e0',
  borderRadius: '5px',
  border: '1px solid #e0e0e0',
};

const GlobalStyles = createGlobalStyle`
    html{
        box-sizing: border-box;
        font-size: 9px;
    }
    
    *, *:before, *:after {
        box-sizing: inherit;
    }
    
    body{
        padding: 0;
        margin: 0;
        font-size: 1.5rem;
        line-height: 2;
        font-family: "Open Sans";
        background-color: var(--bg-primary-soft);
    }
    
    a{
        text-decoration: none;
        color: ${globalTheme.black};
    }
    
    .confirm-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        line-height:24px;
        color: white;
        padding: 7.5px 20px;
        border-radius: ${globalTheme.borderRadius};
        background: ${globalTheme.deepBlue};
        // font-size: 14px;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.1px;
        font-family: 'Open Sans';
        // text-transform: uppercase;
        border: none;
        cursor: pointer;
        transition: 0.3s;
        &:hover,
        &:active,
        &:focus {
            border: none;
            outline: none;
            
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }
    
    .inactive-button{
        color: #cecece;
        padding: 1rem 2rem;
        border-radius: ${globalTheme.borderRadius};
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid #cecece;
        background: #fff;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            border: 1px solid #000;
            outline: none;
            color:#000;
            transition: all .2s;
        }
    }
    
    .warning-button {
        color: white;
        padding: 7.5px 20px;
        border-radius: ${globalTheme.borderRadius};
        font-size: 18px;
        text-transform: uppercase;
        border: none;
        background: ${globalTheme.red};
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            border: none;
            outline: none;
        }
    }
    
    .action-button {
        display: inline-flex;
        background: #fff;
        align-items: center;
        border-radius: ${globalTheme.borderRadius};
        font-size: 2rem;
        outline: none;
        border: 1px solid #e0e0e0;
        padding: 0.5rem 1rem;
        cursor: pointer;
        margin-left: 1rem;
        
        svg {
            transition: transform 0.3s;
            font-size: 2rem;
            color: #000;
        }
    
        &:hover svg {
            transform: rotate(40deg);
        }
        
        &:hover.button-settings svg {
            color: ${globalTheme.blue};
        }
            
        &:hover.button-settings {
            border-color: ${globalTheme.blue};
        }
        
        &:hover.button-delete svg {
            color: ${globalTheme.red};
        }
        
        &:hover.button-delete {
            border-color: ${globalTheme.red};
        }
    }
    
    .secondary-button{
        line-height: 16px;
        background: #fff;
        padding: 8px 10px;
        color: #000;
        border-color: black;
        text-transform: capitalize;
        display: inline-flex;
        border-radius: ${globalTheme.borderRadius};
        border: 1px solid #e0e0e0;
        cursor: pointer;
        transition: transform 0.3s;
        
        &:focus {
            outline: none;
        }
        
        &:hover {
            border-color: ${globalTheme.blue};
            color: ${globalTheme.blue};
        }
    }

    .btn-default {
        font-family: "Open Sans";
        font-size: inherit;
        font-weight: 600;
        text-align: center;
        display:inline-flex;
        align-items:center;
        height:40px;
        width: fit-content;
    
        text-transform: uppercase;
        background: white;
        cursor: pointer;
        box-shadow: none;
        padding: 1rem 2rem;
        border: none;
        color: black;
        border: solid 2px lightgray;
        align-items: center;
        justify-content:center;

        transition: all 0.2s ease-out;
        &:hover,
        &:active {
        color: white;
        background: #52bad5;
        border: solid 2px white;
    }
}
`;

export default GlobalStyles;
export { globalTheme };
