import React from 'react';
import styles from './PreviewTopBar.module.css';

const PreviewTopBar = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>Preview</div>
      <div className={styles.rightSection}>{children}</div>
    </div>
  );
};

export default PreviewTopBar;
