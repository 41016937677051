import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.scss';

const Dropdown = ({ trigger, items, onSelect, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleTriggerClick = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    if (!item.disabled) {
      onSelect(item);
      setIsOpen(false);
    }
  };

  const triggerElement = React.cloneElement(trigger, {
    onClick: handleTriggerClick,
  });

  return (
    <div className={`${styles.dropdown} ${className || ''}`} ref={dropdownRef}>
      {triggerElement}
      {isOpen && (
        <div className={styles.content}>
          {items.map((item) => (
            <button
              key={item.id}
              className={`${styles.item} ${item.disabled ? styles.disabled : ''}`}
              onClick={() => handleItemClick(item)}
              disabled={item.disabled}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  trigger: PropTypes.element.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Dropdown;
