import styled from 'styled-components';

export default styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 17.5px;
  font-weight: bold;
  line-height: 31px;
  text-align: center;
  background-color: rgb(0, 0, 0);
  border: 2px solid transparent;
  position: absolute;
  top: -10px;
  right: -10px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
  }
`;
