import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import fetchInstance from '../utils/fetchInstance';
import FormStyles from './styles/FormStyles';
import Title from './styles/TitleStyle';
import ExtendedWrapperFromModalStyles from './styles/ExtendedWrapperFromModalStyles';
import DefaultLayout from './DefaultLayout';

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      resetPass: {
        email: '',
      },
      busy: false,
    };
  }

  handleInput = (e) => {
    const field = e.target.name;
    const section = { ...this.state.login };
    section[field] = e.target.value;

    return this.setState({ resetPass: section });
  };

  resetPassword = (e) => {
    e.preventDefault();

    if (!this.state.resetPass.email) {
      return this.props.setBanner(true, 'bad', 'Please fill out email field');
    }

    return fetchInstance('/startPasswordReset', {
      method: 'POST',
      body: JSON.stringify({ email: this.state.resetPass.email }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.success) this.props.history.push('/login');
        return this.props.setBanner(
          true,
          'good',
          'Success. Check your email for reset link',
          false,
        );
        // throw new Error(data.message)
      })
      .catch((err) => {
        this.props.setBanner(true, 'bad', err.message);
      });
  };

  render() {
    return (
      <DefaultLayout>
        <ExtendedWrapperFromModalStyles>
          <FormStyles onSubmit={this.resetPassword}>
            <div className="back-button">
              <Link to="/login">Back</Link>
            </div>

            <Title>Reset password</Title>

            <label htmlFor="email">Email</label>
            <div className="group">
              <input
                type="email"
                name="email"
                value={this.state.resetPass.email}
                onChange={this.handleInput}
                required
              />
              <span />
            </div>

            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              <button type="submit" className="confirm-button">
                Reset
              </button>
            </div>
          </FormStyles>
        </ExtendedWrapperFromModalStyles>
      </DefaultLayout>
    );
  }
}

export default withRouter(Signup);
