import React from 'react';
import { Ban, ExclamationTriangle } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './ResolutionButtons.module.css';

export const StackedResolution = 'Stacked';

/**
 * @typedef {Object} AspectRatio
 * @property {number} width - Width in pixels
 * @property {number} height - Height in pixels
 */

/**
 * @typedef {Object} ResolutionButtonsProps
 * @property {string[]} networkResolutions - Array of available network resolutions
 * @property {string[]} selectedResolutions - Array of currently selected resolutions
 * @property {string[]} [disabledResolutions=[]] - Array of disabled resolutions
 * @property {string[]} [errorResolutions=[]] - Array of resolutions with errors
 * @property {(resolution: string) => void} onResolutionToggle - Callback when resolution is toggled
 * @property {boolean} [darkerButtons] - Whether to use darker button styling
 * @property {boolean} [showStacked] - Whether to show the "Stacked" resolution
 * @property {boolean} [hideLabel] - Whether to hide the "Resolutions" label
 * @property {boolean} [smaller] - Whether to use smaller buttons
 * @property {string} [error] - Error message to display
 * @property {boolean} [brighterButtons] - Whether to use a white background
 */

/**
 * Calculates the aspect ratio for a resolution string
 * @param {string} resolution - Resolution in format "widthxheight"
 * @returns {AspectRatio | null} Calculated aspect ratio dimensions
 */
const calculateAspectRatio = (resolution, smaller) => {
  const [width, height] = resolution.split('x').map(Number);
  const baseSize = smaller ? 15 : 22;

  if (isNaN(width) || isNaN(height)) {
    return null;
  }

  const ratio = height / width;

  if (ratio > 1) {
    return {
      width: Math.round(baseSize / ratio),
      height: baseSize,
    };
  } else {
    return {
      width: baseSize,
      height: Math.round(baseSize * ratio),
    };
  }
};

/**
 * Resolution buttons component for selecting banner resolutions
 * @param {ResolutionButtonsProps} props
 * @returns {JSX.Element}
 */
const ResolutionButtons = ({
  networkResolutions,
  selectedResolutions,
  disabledResolutions = [],
  errorResolutions = [],
  showStacked = false,
  onResolutionToggle,
  brighterButtons = false,
  darkerButtons = false,
  hideLabel = false,
  smaller = false,
  error,
}) => {
  /** @type {string[]} */
  const allResolutions = [
    ...(showStacked ? [StackedResolution] : []),
    ...networkResolutions,
    ...selectedResolutions.filter(
      (resolution) =>
        !networkResolutions.includes(resolution) &&
        resolution !== StackedResolution,
    ),
  ];

  return (
    <div className={styles.root}>
      {!hideLabel && <label className={styles.label}>Resolutions</label>}
      <div className={cx(styles.resolutionButtons, smaller && styles.smaller)}>
        {allResolutions.map((resolution) => {
          const aspectRatio = calculateAspectRatio(resolution, smaller);
          const isDisabled = disabledResolutions.includes(resolution);
          const isError = errorResolutions.includes(resolution);
          const isStacked = resolution === StackedResolution;

          return (
            <button
              key={resolution}
              type="button"
              className={cx(styles.resolutionButton, {
                [styles.active]: selectedResolutions.includes(resolution),
                [styles.darker]: darkerButtons,
                [styles.brighter]: brighterButtons,
                [styles.disabled]: isDisabled,
                [styles.error]: isError,
              })}
              onClick={() => onResolutionToggle(resolution)}
            >
              {isStacked && (
                <div className={styles.aspectRatioWrapper}>
                  <div
                    className={styles.aspectRatioIcon}
                    style={{
                      width: `12px`,
                      height: `22px`,
                    }}
                  />
                  <div
                    className={styles.aspectRatioIcon}
                    style={{
                      position: 'relative',
                      left: '-1px',
                      width: `8px`,
                      height: `18px`,
                    }}
                  />
                </div>
              )}
              {aspectRatio && (
                <div className={styles.aspectRatioWrapper}>
                  <div
                    className={styles.aspectRatioIcon}
                    style={{
                      width: `${aspectRatio.width}px`,
                      height: `${aspectRatio.height}px`,
                    }}
                  />
                </div>
              )}
              <div className={styles.overIcons}>
                {isDisabled && <Ban className={styles.disabledIcon} />}
                {isError && (
                  <ExclamationTriangle className={styles.errorIcon} />
                )}
              </div>
              {resolution}
            </button>
          );
        })}
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

ResolutionButtons.propTypes = {
  networkResolutions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedResolutions: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledResolutions: PropTypes.arrayOf(PropTypes.string),
  onResolutionToggle: PropTypes.func.isRequired,
  error: PropTypes.string,
  hideLabel: PropTypes.bool,
  darkerButtons: PropTypes.bool,
};

export default ResolutionButtons;
