import React from 'react';
import { MixerProvider } from './MixerContext';
import MixerContent from './MixerContent';

const Mixer = () => {
  return (
    <MixerProvider>
      <MixerContent />
    </MixerProvider>
  );
};

export default Mixer;
