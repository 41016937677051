import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useFormikContext } from 'formik';
import fetchInstance from '../../../../utils/fetchInstance';

export const useBannersCard = ({ onComplete }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedResolutions, setSelectedResolutions] = useState([]);
  const [showIndividual, setShowIndividual] = useState(false);
  const [availableResolutions, setAvailableResolutions] = useState([]);
  const { setFieldValue } = useFormikContext();

  const fetchBannerSet = async () => {
    try {
      setLoading(true);
      const response = await fetchInstance(`/getBannerSet/${id}`);

      if (!response.ok) {
        throw new Error('Failed to fetch banner set');
      }

      const data = await response.json();
      if (data.resolutions) {
        const resolutions = data.resolutions.map(
          (res) => `${res.width}x${res.height}`,
        );
        setAvailableResolutions(resolutions);
        setFieldValue('banners.resolutions', []);
      }
    } catch (error) {
      console.error('Error fetching banner set:', error);
      toast.error('Failed to fetch banner resolutions');
      setAvailableResolutions([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchBannerGroups = async () => {
    try {
      setLoading(true);
      const response = await fetchInstance(`/getBannerGroups/${id}`);

      if (!response.ok) {
        throw new Error('Failed to fetch banner groups');
      }

      const data = await response.json();
      if (data.bannerGroups) {
        setGroups(data.bannerGroups.map((g) => ({ _id: g._id, name: g.name })));
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.error('Error fetching banner groups:', error);
      toast.error('Failed to fetch banner groups');
      setGroups([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBannerSet();
      fetchBannerGroups();
    }
  }, [id]);

  const handleGroupChange = (event) => {
    const groupId = event.target.value;
    const group = groups.find((g) => g._id === groupId);
    setSelectedGroup(group);
    setFieldValue('banners.group', group);

    if (group && selectedResolutions.length > 0) {
      onComplete({ group, resolutions: selectedResolutions });
    }
  };

  const handleResolutionToggle = async (resolution) => {
    const newResolutions = selectedResolutions.includes(resolution)
      ? selectedResolutions.filter((r) => r !== resolution)
      : [...selectedResolutions, resolution];

    setSelectedResolutions(newResolutions);
    await setFieldValue('banners.resolutions', newResolutions);

    if (selectedGroup) {
      onComplete({
        group: selectedGroup,
        resolutions: newResolutions,
      });
    }
  };

  const handleAllResolutions = () => {
    const newResolutions =
      selectedResolutions.length === availableResolutions.length
        ? []
        : availableResolutions;

    setSelectedResolutions(newResolutions);
    setFieldValue('banners.resolutions', newResolutions);

    if (selectedGroup && newResolutions.length > 0) {
      onComplete({ group: selectedGroup, resolutions: newResolutions });
    }
  };

  const handleShowIndividual = () => {
    setShowIndividual(true);
  };

  return {
    loading,
    selectedGroup,
    selectedResolutions,
    showIndividual,
    setShowIndividual: handleShowIndividual,
    availableResolutions,
    handleGroupChange,
    handleAllResolutions,
    handleResolutionToggle,
    groupOptions: [
      { value: '', label: 'Select banners group' },
      ...groups.map((group) => ({
        value: group._id,
        label: group.name,
      })),
    ],
  };
};
