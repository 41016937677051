import styled from 'styled-components';

const CampaignNavStyles = styled.header`
  display: grid;
  grid-template-columns: auto 1fr min-content;
  font-size: 2rem;
  font-family: 'Open Sans';
  justify-content: stretch;
  align-items: stretch;
  text-align: center;
  margin-bottom: 1.5rem;

  @media (max-width: 1300px) {
    grid-template-columns: auto 1fr min-content;
    font-size: 1.5rem;
  }
  @media (max-width: 700px) {
    font-size: 1.2rem;
  }
  position: relative;
`;
const Selected = styled.div`
  justify-self: center;
  justify-self: stretch;
  align-self: stretch;
  background: green;
  padding: 1rem 2rem;
  color: white;
  background: ${(props) => props.theme.lightBlue};
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    padding: 0.5rem 1rem;
  }
  cursor: pointer;
  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border: solid 20px transparent;
    border-top: solid 15px ${(props) => props.theme.lightBlue};
    right: calc(50% - 2rem);
    top: 100%;
  }
`;
const StyledLinks = styled.ul`
  display: flex;
  justify-self: end;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid ${(props) => props.theme.lightGrey};
  width: 100%;
  a,
  button {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    min-width: 100px;
    @media (max-width: 700px) {
      padding: 0 1rem;
      min-width: 80px;
    }
    &:before {
      content: '';
      width: 2px;
      height: 100%;
      background: ${(props) => props.theme.lightGrey};
      /* transform: skew(-7deg); */
      position: absolute;
      left: 0;
    }
    &:first-child {
      &:before {
        content: none;
      }
    }
    &:after {
      content: '';
      width: 0;
      height: 2px;
      background: ${(props) => props.theme.lightBlue};
      position: absolute;
      margin-top: 1em;
      transition: all 0.4s ease;
    }
    &:focus,
    &:hover {
      &:after {
        width: 50%;
      }
    }
  }
`;
const SearchBox = styled.div``;
const MenuStyles = styled.div``;

export default CampaignNavStyles;
export { MenuStyles, Selected, StyledLinks, SearchBox };
