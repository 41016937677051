import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import fetchGoogleInstance from '../../../../utils/fetchGoogleInstance';

export const useDestinationCard = ({ setFieldValue }) => {
  const [loading, setLoading] = useState(false);
  const [adAccounts, setAdAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [adGroups, setAdGroups] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const fetchAdAccounts = async () => {
    try {
      setLoading(true);
      const response = await fetchGoogleInstance('/getListOfCustomers');

      if (!response.ok) {
        throw new Error('Failed to fetch ad accounts');
      }

      const data = await response.json();
      if (data.entries) {
        setAdAccounts(data.entries);
      } else if (data.error && data.message?.includes('invalid_grant')) {
        toast.error(
          'Session expired. Please log into your Google account again.',
        );
        setAdAccounts([]);
      } else {
        throw new Error(data.message || 'Invalid response format');
      }
    } catch (error) {
      toast.error(error.message);
      setAdAccounts([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaigns = async (customerId) => {
    try {
      setLoading(true);
      const response = await fetchGoogleInstance(
        `/getListOfCampaigns?cid=${customerId}&eid=${customerId}`,
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message ||
            `Failed to fetch campaigns (${response.status}: ${response.statusText})`,
        );
      }

      const data = await response.json();
      if (data.entries) {
        setCampaigns(data.entries);
      } else {
        setCampaigns([]);
      }
    } catch (error) {
      toast.error(error.message);
      setCampaigns([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdGroups = async (customerId, campaignId) => {
    try {
      setLoading(true);
      const response = await fetchGoogleInstance(
        `/getListOfAdGroups?cid=${customerId}&eid=${customerId}&campaignId=${campaignId}`,
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch ad groups');
      }

      const data = await response.json();
      if (data.entries) {
        setAdGroups(data.entries);
      } else {
        console.warn('No ad groups found:', data);
        setAdGroups([]);
      }
    } catch (error) {
      toast.error(error.message);
      setAdGroups([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdAccounts();
  }, []);

  const handleAccountChange = async (accountId) => {
    const account = adAccounts.find(
      (acc) => acc.customerId.toString() === accountId.toString(),
    );

    if (!account) return;

    setSelectedCustomerId(account.customerId);
    setFieldValue('destination.account', account);
    setFieldValue('destination.campaign', null);
    setFieldValue('destination.adGroup', null);
    setCampaigns([]);
    setAdGroups([]);

    await fetchCampaigns(account.customerId);
  };

  const handleCampaignChange = async (campaignId) => {
    const campaign = campaigns.find(
      (camp) => camp.id.toString() === campaignId.toString(),
    );
    setFieldValue('destination.campaign', campaign);
    setFieldValue('destination.adGroup', null);
    setAdGroups([]);

    if (campaign && selectedCustomerId) {
      await fetchAdGroups(selectedCustomerId, campaignId);
    }
  };

  const handleAdGroupChange = (adGroupId) => {
    if (!adGroupId) {
      setFieldValue('destination.adGroup', null);
      return;
    }

    const adGroup = adGroups.find(
      (group) => group.id.toString() === adGroupId.toString(),
    );

    if (!adGroup) return;

    setFieldValue('destination.adGroup', adGroup);
  };

  const accountOptions = useMemo(
    () =>
      adAccounts?.map((account) => ({
        value: account.customerId,
        label: account.name,
      })) || [],
    [adAccounts],
  );

  const campaignOptions = useMemo(
    () =>
      campaigns?.map((campaign) => ({
        value: campaign.id.toString(),
        label: campaign.name,
      })) || [],
    [campaigns],
  );

  const adGroupOptions = useMemo(() => {
    return (
      adGroups?.map((group) => ({
        value: group.id.toString(),
        label: group.name,
      })) || []
    );
  }, [adGroups]);

  return {
    loading,
    handleAccountChange,
    handleCampaignChange,
    handleAdGroupChange,
    accountOptions,
    campaignOptions,
    adGroupOptions,
  };
};
