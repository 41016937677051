import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRightShort } from 'react-bootstrap-icons';
import { toast } from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import DefaultLayout from '../../Components/DefaultLayout';
import { generateFinalUrl } from '../../utils/urlBuilder';
import DestinationCard from './cards/DestinationCard';
import BannersCard from './cards/BannersCard';
import AdDetailsCard from './cards/AdDetailsCard';
import URLBuilderCard from './cards/URLBuilderCard';
import { useGoogleAds } from './hooks/google/useGoogleAds';

import styles from './styles/GoogleCampaignPage.module.scss';

const validationSchema = Yup.object().shape({
  destination: Yup.object().shape({
    account: Yup.object().nullable().required('Ad Account is required'),
    campaign: Yup.object().nullable().required('Campaign is required'),
    adGroup: Yup.object().nullable().required('Ad Group is required'),
  }),
  banners: Yup.object().shape({
    group: Yup.object().nullable().required('Group is required'),
    resolutions: Yup.array().min(1, 'At least one resolution is required'),
  }),
  urlBuilder: Yup.object().shape({
    websiteUrl: Yup.string()
      .url('Must be a valid URL')
      .required('Website URL is required'),
    campaignSource: Yup.string().required('Campaign Source is required'),
    campaignMedium: Yup.string().required('Campaign Medium is required'),
    campaignName: Yup.string().required('Campaign Name is required'),
    campaignTerm: Yup.string().required('Campaign Term is required'),
    campaignContent: Yup.string(),
  }),
  adDetails: Yup.object().shape({
    namePrefix: Yup.string().required('Name Prefix is required'),
    displayUrl: Yup.string().required('Display URL is required'),
    finalUrl: Yup.string()
      .url('Must be a valid URL')
      .required('Final URL is required'),
  }),
});

const GoogleAdsPage = () => {
  const [unlockedSteps, setUnlockedSteps] = useState([1]);

  const {
    bannerSet,
    handleSubmit,
    loading,
    handleStepComplete,
    validateSubmission,
  } = useGoogleAds({
    unlockedSteps,
    setUnlockedSteps,
  });

  const initialValues = {
    destination: {
      account: null,
      campaign: null,
      adGroup: null,
    },
    banners: {
      group: null,
      resolutions: [],
    },
    urlBuilder: {
      websiteUrl: '',
      campaignSource: '',
      campaignMedium: '',
      campaignName: '',
      campaignTerm: '',
      campaignContent: '',
    },
    adDetails: {
      namePrefix: '',
      displayUrl: '',
      finalUrl: '',
    },
  };

  const handleFormSubmit = useCallback(
    async (values, { setSubmitting }) => {
      console.log('Form Submit Values:', values);
      console.log('Form Submit adDetails:', values.adDetails);
      console.log('Final URL value:', values.adDetails.finalUrl);

      try {
        if (!validateSubmission(values)) {
          console.log('Validation failed. Current values:', values);
          toast.error('Please fill in all required fields');
          return;
        }
        await handleSubmit(values);
      } catch (error) {
        console.error('Submission error:', error);
      } finally {
        setSubmitting(false);
      }
    },
    [handleSubmit, validateSubmission],
  );

  return (
    <DefaultLayout>
      <div className={styles.wrapper}>
        <Link
          to={`/campaign/${bannerSet?.campaignId}`}
          className={styles.backLink}
        >
          <ArrowLeft />
          Back
        </Link>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className={styles.cardsGrid}>
                  <DestinationCard
                    disabled={false}
                    platform="google"
                    onComplete={(data) => {
                      setFieldValue('destination', data);
                      handleStepComplete('destination', values);
                    }}
                  />
                  <BannersCard
                    disabled={!unlockedSteps.includes(2)}
                    platform="google"
                    onComplete={async (data) => {
                      await setFieldValue('banners', data);
                      const updatedValues = { ...values, banners: data };
                      handleStepComplete('banners', updatedValues);
                    }}
                    bannerSet={bannerSet}
                  />
                </div>
                <div className={styles.card}>
                  <URLBuilderCard
                    disabled={!unlockedSteps.includes(3)}
                    onComplete={(urlBuilderData) => {
                      const finalUrl = generateFinalUrl(urlBuilderData);
                      console.log('URLBuilder onComplete - Initial:', {
                        urlBuilderData,
                        finalUrl,
                        currentValues: values,
                      });
                      Promise.all([
                        setFieldValue('urlBuilder', urlBuilderData),
                        setFieldValue('adDetails.finalUrl', finalUrl),
                      ]).then(() => {
                        handleStepComplete('urlBuilder', {
                          ...values,
                          urlBuilder: urlBuilderData,
                          adDetails: {
                            ...values.adDetails,
                            finalUrl,
                          },
                        });
                      });
                    }}
                  />
                </div>
                <div className={styles.card}>
                  <AdDetailsCard
                    platform="google"
                    disabled={!unlockedSteps.includes(3)}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className={styles.submitFooter}>
                  <button
                    type="submit"
                    className={styles.submitButton}
                    disabled={!unlockedSteps.includes(3) || loading}
                  >
                    {loading ? 'Sending to Google...' : 'Send to Google'}
                    <ArrowRightShort />
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </DefaultLayout>
  );
};

export default GoogleAdsPage;
