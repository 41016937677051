import styled from 'styled-components';

export const ClientSearchBarStyle = styled.div`
  display: flex;
  justify-content: space-between;

  .search-client-wrapper {
    position: relative;

    input#search-client {
      padding: 1rem 4.5rem;
      padding-right: 2rem;
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #000000;
      height: 100%;
      width: 300px;
      border-radius: ${(props) => props.theme.borderRadius};
      border-color: ${(props) => props.theme.red};
      border: ${(props) => props.theme.border};
      cursor: pointer;

      &:focus {
        border: 1px solid ${(props) => props.theme.blue};
        outline: none;
      }

      &::placeholder {
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
      }
    }

    svg {
      width: 16px;
      height: 16px;
      font-size: 16px;
      position: absolute;
      top: 13px;
      left: 13px;
    }
  }

  .filter-add-client {
    display: flex;

    .filter-client {
      text-align: left;
      cursor: pointer;
      margin-right: 10px;
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #000000;
      min-height: 39px;

      div {
        min-height: 26px;
      }

      div > div > div {
        display: flex;
        align-items: center;
      }
    }
  }
`;
