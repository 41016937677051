import React from 'react';
import { LayersFill } from 'react-bootstrap-icons';
import { useMixer } from '../MixerContext';
import { MixerElement } from '../Consts/DefaultElements';
import styles from './SelectedElement.module.css';

const SelectedElement = () => {
  const { selectedElement } = useMixer();

  if (selectedElement?.id === MixerElement.id) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <LayersFill /> Selected element:
      </div>
      <div className={styles.content}>
        {selectedElement?.name ?? 'No element selected'}
      </div>
      {selectedElement?.id && (
        <small className={styles.id}>ID: {selectedElement?.id}</small>
      )}
    </div>
  );
};

export default SelectedElement;
