export const DefaultElements = {
  root: {
    id: 'banner-root',
    name: 'Banner root',
  },
  background: {
    id: 'background',
    name: 'Background image',
  },
};

export const MixerElement = {
  id: 'mixer',
  name: 'Ready to mix',
};
