import React from 'react';
import cx from 'classnames';
import FieldWrapper from '../FieldWrapper';
import styles from './ImagesSet.module.css';
import ImageElement from './ImageElement';

const ImagesSet = ({
  variations,
  notEditable,
  label,
  isBaseSet,
  onRemove,
  setId,
  onPreview,
  isActive,
  onImageUpload,
  onImageRemove,
  onImageDisable,
  preserveResolutionAspect,
  isBackground,
  showActions,
  onCopyMainToAll,
  onCopyFromMain,
}) => {
  const onPreviewClick = () => {
    if (isBackground) {
      onPreview(variations);
      return;
    }
    onPreview(setId);
  };

  const onVariantRemove = () => {
    onRemove(setId, variations);
  };

  return (
    <div className={cx(styles.root, isActive && styles.active)}>
      <FieldWrapper
        label={label}
        whiteBox
        onPreviewClick={onPreview && onPreviewClick}
        onRemove={!notEditable && onVariantRemove}
        isActive={isActive}
      >
        <div
          className={cx(
            styles.imagesContainer,
            notEditable && styles.notEditable,
          )}
        >
          {Object.entries(variations).map(([resolution, data], index) => (
            <ImageElement
              key={data.id}
              setId={setId}
              resolution={resolution}
              data={data}
              preserveResolutionAspect={preserveResolutionAspect}
              notEditable={notEditable}
              isBaseSet={isBaseSet}
              isActivePreviewSet={isActive}
              onUpload={onImageUpload}
              onRemove={onImageRemove}
              onDisable={onImageDisable}
              showActions={showActions}
              onCopyMainToAll={index === 0 && onCopyMainToAll}
              onCopyFromMain={index > 0 && onCopyFromMain}
            />
          ))}
        </div>
      </FieldWrapper>
    </div>
  );
};

export default ImagesSet;
