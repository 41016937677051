const defaultFonts = {
  Arial: {
    name: 'Arial',
    isSystem: true,
  },
  'Arial Black': {
    name: 'Arial Black',
    isSystem: true,
  },
  'Times New Roman': {
    name: 'Times New Roman',
    isSystem: true,
  },
  Times: {
    name: 'Times',
    isSystem: true,
  },
  'Courier New': {
    name: 'Courier New',
    isSystem: true,
  },
  Courier: {
    name: 'Courier',
    isSystem: true,
  },
  Tahoma: {
    name: 'Tahoma',
    isSystem: true,
  },
  Verdana: {
    name: 'Verdana',
    isSystem: true,
  },
  Georgia: {
    name: 'Georgia',
    isSystem: true,
  },
  Palatino: {
    name: 'Palatino',
    isSystem: true,
  },
  Garamond: {
    name: 'Garamond',
    url: 'https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap',
    isGoogle: true,
  },
  Bookman: {
    name: 'Bookman',
    url: 'https://fonts.googleapis.com/css2?family=Bookman+Old+Style&display=swap',
    isGoogle: true,
  },
  Impact: {
    name: 'Impact',
    isSystem: true,
  },
  'Comic Sans': {
    name: 'Comic Sans MS',
    isSystem: true,
  },
};

export default defaultFonts;
