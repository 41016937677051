export function capitalizeString(str, capitalizeAll = false) {
  if (!str || typeof str !== 'string') {
    throw new Error('Invalid input. Please provide a valid string.');
  }

  if (capitalizeAll) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
