import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Floppy } from 'react-bootstrap-icons';

import TopBar from '../../TopBar/TopBar';
import fetchInstance from '../../../utils/fetchInstance';
import Button from '../../../Components/FormComponents/Button';
import { ButtonVariantEnum } from '../../../shared/enums/buttonEnum';
import getDefaultFrame from '../Helpers/GetDefaultFrame';
import normalizeVariations from '../Helpers/NormalizeVariations';
import ResolutionButtons from '../../../Components/FormComponents/ResolutionButtons';
import ConfirmModal from '../../../ComponentsV2/ConfirmModal/ConfirmModal';
import { FontsProvider } from '../../../context/FontsContext';
import { Dispatcher, Events } from '../../../Components/Events';
import DefaultLayout from '../../../Components/DefaultLayout';
import config from '../../../config';
import normalizeTransform from '../Helpers/NormalizeTransform';
import BannerSetStatusEnum from '../../../shared/enums/bannerSetStatus';
import handleBannerSetRedirect from '../../../utils/handleBannerSetRedirect';
import DebugInfo from './Elements/DebugInfo';
import NavigationButtons from './Elements/NavigationButtons';
import CreatorActions from './Actions';
import BannerCreatorPreview from './BannerCreatorPreview';
import styles from './Creator.module.css';

const BannerCreator = () => {
  const { id } = useParams();
  const [bannerSet, setBannerSet] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedResolution, setSelectedResolution] = useState(null);
  const [elements, setElements] = useState([]);
  const [backgrounds, setBackgrounds] = useState({ variations: {} });
  const [key, setKey] = useState(0);
  const isMounted = useRef(true);
  const allResolutionsRef = useRef([]);
  const [bannerId, setBannerId] = useState(null);

  const history = useHistory();

  const allResolutions = useMemo(() => {
    return bannerSet?.resolutions?.map((res) => `${res.width}x${res.height}`);
  }, [bannerSet]);

  useEffect(() => {
    allResolutionsRef.current = allResolutions || [];
  }, [allResolutions]);

  useEffect(() => {
    const fetchBannerSet = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetchInstance(
          `/getBannerSet/${id}?aggregate=true`,
        );
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch banner set');
        }

        setBannerSet(data);

        if (handleBannerSetRedirect(data.status, data._id, history)) {
          return;
        }

        if (data.banners && data.banners.length > 0) {
          const existingBanner = data.banners[0];
          setBannerId(existingBanner.id || existingBanner._id);

          if (existingBanner.elements) {
            const normalizedElements = existingBanner.elements.map(
              (element, index) => {
                const variations = normalizeVariations(
                  element.variations,
                  data.resolutions.map((res) => `${res.width}x${res.height}`),
                  element.type,
                  index,
                );

                const processedVariations = Object.keys(variations).reduce(
                  (acc, resolution) => {
                    const currentTransform =
                      variations[resolution]?.frame?.transform || {};
                    const normalizedTransform =
                      normalizeTransform(currentTransform);

                    return {
                      ...acc,
                      [resolution]: {
                        ...variations[resolution],
                        frame: {
                          ...variations[resolution].frame,
                          transform: normalizedTransform,
                          'z-index':
                            variations[resolution]?.frame?.['z-index'] !==
                            undefined
                              ? variations[resolution].frame['z-index']
                              : index,
                        },
                      },
                    };
                  },
                  {},
                );

                return {
                  ...element,
                  id: element.id || element._id,
                  variations: processedVariations,
                };
              },
            );

            setElements(normalizedElements);
          }

          if (existingBanner.backgrounds) {
            setBackgrounds({
              variations: existingBanner.backgrounds,
            });
          }
        }

        if (data.resolutions && data.resolutions.length > 0) {
          const firstRes = data.resolutions[0];
          setSelectedResolution(`${firstRes.width}x${firstRes.height}`);
        }
      } catch (error) {
        console.error('Error fetching banner set:', error);
        setError('Failed to load banner set. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchBannerSet();
    }
  }, [id]);

  const selectedResolutionRef = useRef(null);

  const handleResolutionToggle = (resolution) => {
    selectedResolutionRef.current = resolution;
    setSelectedResolution(resolution);
    setKey((prev) => prev + 1);
  };

  const handlePrevResolution = () => {
    const currentIndex = allResolutions.indexOf(selectedResolution);
    if (currentIndex > 0) {
      const prevResolution = allResolutions[currentIndex - 1];
      handleResolutionToggle(prevResolution);
    }
  };

  const handleNextResolution = () => {
    const currentIndex = allResolutions.indexOf(selectedResolution);
    if (currentIndex < allResolutions.length - 1) {
      const nextResolution = allResolutions[currentIndex + 1];
      handleResolutionToggle(nextResolution);
    }
  };

  const handleSaveAndGoToMixer = async () => {
    try {
      await saveBanner();

      const response = await fetchInstance(
        `/updateBannerSet/${bannerSet._id}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            bannerSet: {
              status: BannerSetStatusEnum.MIXER,
            },
          }),
        },
      );
      const updatedBannerSet = await response.json();
      if (!updatedBannerSet.success) {
        throw new Error('Failed to update banner set status');
      }
      history.replace(`${config.appRoutes.bannerMixer}/${bannerSet._id}`);
    } catch (error) {
      toast.error('Error creating mixer data.');
      console.error('Error creating mixer data:', error);
    }
  };

  const handleSubmit = async () => {
    toast.promise(handleSaveAndGoToMixer(), {
      loading: 'Saving...',
      success: <b>Banner saved!</b>,
      error: <b>Could not save.</b>,
    });
  };

  const handleSave = async () => {
    toast.promise(saveBanner(), {
      loading: 'Saving...',
      success: <b>Banner saved!</b>,
      error: <b>Could not save.</b>,
    });
  };

  const saveBanner = async () => {
    const submissionData = {
      elements: elements.map((element) => {
        const isFrontendId = element.id.startsWith('banner_element_');
        return {
          ...(bannerId && !isFrontendId ? { _id: element.id } : {}),
          type: element.type,
          variations: element.variations,
        };
      }),
      backgrounds: backgrounds.variations,
    };

    try {
      let response;
      if (bannerId) {
        response = await fetchInstance(`/updateBanner/${bannerId}`, {
          method: 'PUT',
          body: JSON.stringify({
            banner: submissionData,
          }),
        });

        const updatedData = await response.json();
        if (response.ok && updatedData.banner.elements) {
          const updatedElements = updatedData.banner.elements.map(
            (serverElement) => {
              const existingElement = elements.find(
                (el) =>
                  el.id === serverElement._id || el.id === serverElement.id,
              );

              return {
                ...serverElement,
                id: serverElement._id || serverElement.id,
                variations: Object.keys(serverElement.variations).reduce(
                  (acc, resolution) => ({
                    ...acc,
                    [resolution]: {
                      ...serverElement.variations[resolution],
                      frame: {
                        ...serverElement.variations[resolution].frame,
                        'z-index':
                          existingElement?.variations[resolution]?.frame?.[
                            'z-index'
                          ] ||
                          serverElement.variations[resolution]?.frame?.[
                            'z-index'
                          ] ||
                          0,
                      },
                    },
                  }),
                  {},
                ),
              };
            },
          );
          setElements(updatedElements);
        }
      } else {
        response = await fetchInstance('/banner', {
          method: 'POST',
          body: JSON.stringify({
            banner: {
              ...submissionData,
              bannerSet: id,
            },
          }),
        });

        const data = await response.json();
        if (response.ok) {
          setBannerId(data.banner.id || data.banner._id);

          if (data.banner.elements || data.elements) {
            const serverElements = data.banner.elements || data.elements;
            const updatedElements = serverElements.map(
              (serverElement, index) => {
                const existingElement = elements.find(
                  (el) =>
                    el.id === serverElement._id || el.id === serverElement.id,
                );

                return {
                  ...serverElement,
                  id: serverElement._id || serverElement.id,
                  variations: Object.keys(serverElement.variations).reduce(
                    (acc, resolution) => ({
                      ...acc,
                      [resolution]: {
                        ...serverElement.variations[resolution],
                        frame: {
                          ...serverElement.variations[resolution].frame,
                          'z-index':
                            existingElement?.variations[resolution]?.frame?.[
                              'z-index'
                            ] ||
                            serverElement.variations[resolution]?.frame?.[
                              'z-index'
                            ] ||
                            index,
                        },
                      },
                    }),
                    {},
                  ),
                };
              },
            );
            setElements(updatedElements);
          }
        }
      }

      if (!response.ok) {
        throw new Error('Failed to save banner');
      }
    } catch (error) {
      console.error('Error saving banner:', error);
      throw error;
    }
  };

  const elementsRef = useRef([]);

  const handleAddElement = useCallback((payload) => {
    if (!isMounted.current) return;

    const elementId = `banner_element_${elementsRef.current.length}`;
    const variations = { ...payload.variations };

    const maxZIndex = elementsRef.current.reduce((max, element) => {
      const elementMaxZ = Object.values(element.variations).reduce(
        (elementMax, variation) => {
          const zIndex = variation?.frame?.['z-index'] ?? 0;
          return Math.max(elementMax, zIndex);
        },
        0,
      );
      return Math.max(max, elementMaxZ);
    }, -1);

    const newZIndex = maxZIndex + 1;

    allResolutionsRef.current.forEach((resolution) => {
      if (variations[resolution]?.status === 'disabled') {
        variations[resolution] = {
          status: 'disabled',
          options: {},
          frame: getDefaultFrame({ zIndex: newZIndex }),
          locked: false,
        };
      } else {
        variations[resolution] = {
          ...variations[resolution],
          status: variations[resolution]?.status ?? 'enabled',
          frame:
            variations[resolution]?.frame ??
            getDefaultFrame({ zIndex: newZIndex }),
          locked: false,
        };
      }
    });

    const newElement = {
      id: elementId,
      type: payload.type,
      variations: variations,
    };

    setElements((prev) => {
      const updatedElements = [...prev, newElement];
      elementsRef.current = updatedElements;
      return updatedElements;
    });
  }, []);

  const getSortedElements = (elements, selectedResolution) => {
    return [...elements].sort((a, b) => {
      const aZ = a.variations[selectedResolution]?.frame?.['z-index'];
      const bZ = b.variations[selectedResolution]?.frame?.['z-index'];

      if (aZ === undefined || bZ === undefined) {
        return 0;
      }

      return aZ - bZ;
    });
  };

  const handleBringToFront = useCallback(
    (elementId) => {
      if (!isMounted.current) return;

      setElements((prevElements) => {
        const sortedElements = getSortedElements(
          prevElements,
          selectedResolution,
        );
        const currentIndex = sortedElements.findIndex(
          (el) => el.id === elementId,
        );

        if (currentIndex === -1 || currentIndex === sortedElements.length - 1) {
          return prevElements;
        }

        const currentElement = sortedElements[currentIndex];
        const nextElement = sortedElements[currentIndex + 1];

        const currentZ =
          currentElement.variations[selectedResolution]?.frame?.['z-index'];
        const nextZ =
          nextElement.variations[selectedResolution]?.frame?.['z-index'];

        if (currentZ === undefined || nextZ === undefined) {
          toast.error("Can't change order");
          return prevElements;
        }

        return prevElements.map((element) => {
          if (element.id === currentElement.id) {
            return {
              ...element,
              variations: {
                ...element.variations,
                [selectedResolution]: {
                  ...element.variations[selectedResolution],
                  frame: {
                    ...element.variations[selectedResolution].frame,
                    'z-index': currentZ + 1,
                  },
                },
              },
            };
          }
          if (element.id === nextElement.id) {
            return {
              ...element,
              variations: {
                ...element.variations,
                [selectedResolution]: {
                  ...element.variations[selectedResolution],
                  frame: {
                    ...element.variations[selectedResolution].frame,
                    'z-index': nextZ - 1,
                  },
                },
              },
            };
          }
          return element;
        });
      });
    },
    [selectedResolution],
  );

  const handleSendToBack = useCallback(
    (elementId) => {
      if (!isMounted.current) return;

      setElements((prevElements) => {
        const sortedElements = getSortedElements(
          prevElements,
          selectedResolution,
        );
        const currentIndex = sortedElements.findIndex(
          (el) => el.id === elementId,
        );

        if (currentIndex === -1 || currentIndex === 0) {
          return prevElements;
        }

        const currentElement = sortedElements[currentIndex];
        const prevElement = sortedElements[currentIndex - 1];

        const currentZ =
          currentElement.variations[selectedResolution]?.frame?.['z-index'];
        const prevZ =
          prevElement.variations[selectedResolution]?.frame?.['z-index'];

        if (currentZ === undefined || prevZ === undefined) {
          toast.error("Can't change order");
          return prevElements;
        }

        return prevElements.map((element) => {
          if (element.id === currentElement.id) {
            return {
              ...element,
              variations: {
                ...element.variations,
                [selectedResolution]: {
                  ...element.variations[selectedResolution],
                  frame: {
                    ...element.variations[selectedResolution].frame,
                    'z-index': currentZ - 1,
                  },
                },
              },
            };
          }
          if (element.id === prevElement.id) {
            return {
              ...element,
              variations: {
                ...element.variations,
                [selectedResolution]: {
                  ...element.variations[selectedResolution],
                  frame: {
                    ...element.variations[selectedResolution].frame,
                    'z-index': prevZ + 1,
                  },
                },
              },
            };
          }
          return element;
        });
      });
    },
    [selectedResolution],
  );

  useEffect(() => {
    elementsRef.current = elements;
  }, [elements]);

  const handleUpdateElement = useCallback((payload) => {
    if (!isMounted.current) return;
    const { id, options, frame, variations: newVariations } = payload;

    setElements((prevElements) =>
      prevElements.map((element) => {
        if (element.id === id) {
          if (!element.variations[selectedResolutionRef.current]) {
            const firstResolution = Object.keys(element.variations)[0];
            element.variations[selectedResolutionRef.current] = {
              ...element.variations[firstResolution],
            };
          }

          const existingZIndex =
            element.variations[selectedResolutionRef.current]?.frame?.[
              'z-index'
            ];

          return {
            ...element,
            variations: newVariations || {
              ...element.variations,
              [selectedResolutionRef.current]: {
                ...element.variations[selectedResolutionRef.current],
                options: options
                  ? {
                      ...element.variations[selectedResolutionRef.current]
                        .options,
                      ...options,
                    }
                  : element.variations[selectedResolutionRef.current].options,
                frame: frame
                  ? {
                      ...element.variations[selectedResolutionRef.current]
                        .frame,
                      ...frame,
                      'z-index':
                        frame['z-index'] !== undefined
                          ? frame['z-index']
                          : existingZIndex,
                    }
                  : element.variations[selectedResolutionRef.current].frame,
                locked:
                  newVariations?.[selectedResolutionRef.current]?.locked ??
                  element.variations[selectedResolutionRef.current]?.locked ??
                  false,
              },
            },
          };
        }
        return element;
      }),
    );
  }, []);

  const handleBackgroundUpdate = useCallback((payload) => {
    if (!isMounted.current) return;

    const { resolution, file, disabled } = payload;
    setBackgrounds((prev) => ({
      ...prev,
      variations: {
        ...prev.variations,
        [resolution]: disabled
          ? { disabled: true }
          : {
              src: file.path,
              disabled: false,
            },
      },
    }));
  }, []);

  const getCurrentElements = () => {
    return elements
      .map((element) => {
        const variation =
          element.variations[selectedResolution] ||
          element.variations[Object.keys(element.variations)[0]];

        return {
          id: element.id,
          type: element.type,
          options: variation.options,
          frame: variation.frame,
          locked: variation.locked || false,
          variations: element.variations,
          currentResolution: selectedResolution,
        };
      })
      .sort((a, b) => (a.frame['z-index'] || 0) - (b.frame['z-index'] || 0));
  };

  const handleDeleteElement = useCallback((payload) => {
    if (!isMounted.current) return;

    const { id } = payload;

    setElements((prevElements) =>
      prevElements.filter((element) => element.id !== id),
    );
  }, []);

  useEffect(() => {
    if (!bannerSet) return;

    Dispatcher.listen(Events.ADD_BANNER_ELEMENT, handleAddElement);
    Dispatcher.listen(Events.UPDATE_BANNER_ELEMENT, handleUpdateElement);
    Dispatcher.listen(Events.CHANGE_BANNER_BACKGROUND, handleBackgroundUpdate);
    Dispatcher.listen(Events.REMOVE_BANNER_ELEMENT, handleDeleteElement);
  }, [bannerSet]);

  useEffect(() => {
    selectedResolutionRef.current = selectedResolution;
  }, [selectedResolution]);

  const handleRemoveAllElements = useCallback(() => {
    if (!isMounted.current) return;

    ConfirmModal({
      title: 'Are you sure you want to remove all elements?',
      subMessage: [
        'This will remove all elements from all resolutions.',
        'This action cannot be undone.',
      ],
      confirmText: 'Remove Elements',
      cancelText: 'Cancel',
      isDanger: true,
      onConfirm: () => {
        toast.promise(
          new Promise((resolve) => {
            setElements([]);
            resolve();
          }),
          {
            loading: 'Removing all elements...',
            success: 'All elements have been removed successfully!',
            error: 'Failed to remove elements.',
          },
        );
      },
    });
  }, []);

  const handleRemoveAllBackgrounds = useCallback(() => {
    if (!isMounted.current) return;

    ConfirmModal({
      title: 'Are you sure you want to remove all backgrounds?',
      subMessage: [
        'This will remove all backgrounds from all resolutions.',
        'This action cannot be undone.',
      ],
      confirmText: 'Remove Backgrounds',
      cancelText: 'Cancel',
      isDanger: true,
      onConfirm: () => {
        toast.promise(
          new Promise((resolve) => {
            setBackgrounds({ variations: {} });
            resolve();
          }),
          {
            loading: 'Removing all backgrounds...',
            success: 'All backgrounds have been removed successfully!',
            error: 'Failed to remove backgrounds.',
          },
        );
      },
    });
  }, []);

  const handleReset = useCallback(() => {
    if (!isMounted.current) return;

    ConfirmModal({
      title: 'Are you sure you want to reset this banner?',
      subMessage: [
        'This will remove all elements and backgrounds.',
        'This action cannot be undone.',
      ],
      confirmText: 'Reset',
      cancelText: 'Cancel',
      isDanger: true,
      onConfirm: () => {
        toast.promise(
          new Promise((resolve) => {
            setElements([]);
            setBackgrounds({ variations: {} });
            resolve();
          }),
          {
            loading: 'Resetting banner...',
            success: 'Banner has been reset successfully!',
            error: 'Failed to reset banner.',
          },
        );
      },
    });
  }, []);

  if (isLoading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;

  return (
    <DefaultLayout>
      <FontsProvider clientId={bannerSet?.campaign?.client?._id}>
        <div className={styles.root}>
          <TopBar
            title="Banner elements"
            network={bannerSet?.platform}
            actions={
              <Button
                onClick={handleSave}
                leftIcon={<Floppy />}
                variant={ButtonVariantEnum.TEXT}
              >
                Save draft
              </Button>
            }
          />
          <div className={styles.contentWrapper}>
            <div className={styles.buttonsBar}>
              <div className={styles.actions}>
                <CreatorActions
                  bannerSet={bannerSet}
                  currentBackgrounds={backgrounds.variations}
                  currentElementsLength={elements.length}
                />
              </div>
              <div className={styles.resolutionButtons}>
                <ResolutionButtons
                  networkResolutions={allResolutions}
                  selectedResolutions={
                    selectedResolution ? [selectedResolution] : []
                  }
                  onResolutionToggle={handleResolutionToggle}
                  hideLabel
                  darkerButtons
                />
              </div>
            </div>
            <BannerCreatorPreview
              elements={getCurrentElements()}
              background={backgrounds.variations[selectedResolution]}
              resolution={selectedResolution}
              key={key}
              onBringToFront={handleBringToFront}
              onSendToBack={handleSendToBack}
              onRemoveElements={handleRemoveAllElements}
              onRemoveBackground={handleRemoveAllBackgrounds}
              onReset={handleReset}
            />
            <NavigationButtons
              selectedResolution={selectedResolution}
              availableResolutions={allResolutions}
              onPrevious={handlePrevResolution}
              onNext={handleNextResolution}
              onSubmit={handleSubmit}
            />
            <DebugInfo identifier={bannerSet._id} />
          </div>
          <div id="modal-holder"></div>
        </div>
      </FontsProvider>
    </DefaultLayout>
  );
};

const LoadingState = () => (
  <DefaultLayout>
    <div className={styles.root}>
      <TopBar title="Loading..." />
      <div className={styles.loading}>Loading banner set...</div>
    </div>
  </DefaultLayout>
);

const ErrorState = ({ error }) => (
  <DefaultLayout>
    <div className={styles.root}>
      <TopBar title="Error" />
      <div className={styles.error}>{error}</div>
    </div>
  </DefaultLayout>
);

export default BannerCreator;
