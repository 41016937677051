import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import Fade from '@material-ui/core/Fade/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import AIMainePage from '../styles/AIMainePageStyle';
import CloseBtnModal from '../styles/CloseBtnModal';
import FileUploader from '../FileUploader/FileUploader';
import fetchInstance from '../../utils/fetchInstance';

const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '10000!important',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 600,
    border: 'none',
    outline: 'none',
    position: 'relative',
  },

  content: {
    overflow: 'scroll',
    maxHeight: '90%',
    overflowX: 'hidden',
  },

  loader: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  chip: {
    margin: 2,
    fontSize: '13px',
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },
  select: {
    height: '50px',
    minWidth: '200px',
    overflow: 'auto',
  },

  root: {
    fontSize: '14px',
  },
  loader_form: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position: 'absolute',
    background: 'rgba(255,255,255,0.5)',
  },
  relative: {
    position: 'relative',
  },
  submit_wrapper: {
    width: '150px',
    position: 'absolute',
    left: '170px',
    top: '18px',
  },
  divider_title: {},
});

const ModalStyling = styled.div`
  .form-section {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr 0.5fr;
    grid-gap: 1rem;

    input,
    select {
      height: 30px;
    }
  }

  .cancel_button,
  input[type='reset'] {
    padding: 8px 25px;
    border-radius: 5px;
    background: lightgray;
    color: #000;
    margin-left: 10px;
    border: 2px solid transparent;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      background: none;
      border: 2px solid lightgray;
    }
  }

  .cancel_button,
  button[type='reset'] {
    padding: 8px 25px;
    border-radius: 5px;
    background: lightgray;
    color: #000;
    margin-left: 10px;
    border: 2px solid transparent;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      background: none;
      border: 2px solid lightgray;
    }
  }

  .submit_button,
  input[type='submit'] {
    padding: 8px 25px;
    border-radius: 5px;
    background: #52bad5;
    color: white;
    margin-left: 20px;
    border: 2px solid transparent;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      background: white;
      border: 2px solid #52bad5;
      color: #52bad5;
    }

    &:disabled {
      opacity: 0.75;
    }
  }

  .submit_button,
  button[type='submit'] {
    padding: 8px 25px;
    border-radius: 5px;
    background: #52bad5;
    color: white;
    margin-left: 20px;
    border: 2px solid transparent;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      background: white;
      border: 2px solid #52bad5;
      color: #52bad5;
    }

    &:disabled {
      opacity: 0.75;
    }
  }
`;

const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h3 {
    border-bottom: solid 2px #393939;
    width: 90%;
    text-align: left;
  }

  divider {
    display: block;
    width: 90%;
    background: #e1e1e1;
    height: 2px;
    margin: 0;
  }

  fieldset {
    width: 90%;
    text-align: right;
  }

  table {
    border: 2px solid #e1e1e1;
    border-radius: 10px;
    width: 90%;
    thead {
      tr {
        th {
          font-size: 14px;
          text-align: left;
          padding-bottom: 10px;
        }
      }
    }
    tbody {
      tr:first-child {
        td {
          border-top: 2px solid #e1e1e1;
          padding-top: 20px;
        }
      }
      tr {
        td {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
  .hover-active {
    cursor: pointer;
  }
  .table-loader {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .pagination-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button:disabled {
      color: #e1e1e1;
    }

    button.current-active {
      background: lightgray;
    }
  }
`;

class AISettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: true,
      options: {
        path: '',
        name: '',
        resolution_width: '',
        resolution_height: '',
      },
      form_loading: false,
      refresh_uploads: false,
      pagination: {
        has_next: false,
        has_prev: false,
        page: 1,
        pages: 0,
        per_page: 10,
        pagination: '',
      },
      items: [],
      open_remove_confirmation: false,
      remove_template_id: false,
      weights: {
        weight_b: 2,
        weight_p: 10,
        weight_c: 10,
        weight_m: 0.3,
      },
      new_weights: {},
      open_change_weights: false,
    };
  }

  componentDidMount() {
    this.getTemplates(false);
    this.getWeights();
  }

  setWeights = (weights_dict) => {
    console.log('==== SET WEIGHTS ====');
    console.log(weights_dict);
    fetchInstance('/setAIWeights', {
      method: 'POST',
      body: JSON.stringify(weights_dict),
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        if (data.success) console.log(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  getWeights = () => {
    fetchInstance('/getAIWeights', {
      method: 'POST',
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        if (data.success) {
          let weights = {
            weight_b: data.weights['wb'],
            weight_c: data.weights['wc'],
            weight_p: data.weights['wp'],
            weight_m: data.weights['wm'],
          };
          this.setState(() => ({ weights }));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  getTemplates = (templates_loader) => {
    if (templates_loader) {
      this.setState({ templates_loader: true });
    } else {
      this.setState({ loading: true });
    }

    var params = {};
    params['per_page'] = this.state.pagination.per_page;
    params['page'] = this.state.pagination.page;

    fetchInstance('/getAiTemplates', {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        if (data.success) {
          this.setState(
            {
              items: data['data'],
              loading: false,
              table_loading: false,
              image_location: data['image_location'],
              pagination: {
                has_next: data.has_next,
                has_prev: data.has_prev,
                page: data.page,
                pages: data.pages,
                per_page: data.per_page,
                pagination: '',
              },
            },
            () => {
              let new_state = this.state;
              new_state['pagination']['pagination'] = this.getPagination();
              this.setState(new_state);
            },
          );
        }
        console.log(this.state);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  getPagination = () => {};

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenWeightsModal = () => {
    this.setState({ open_change_weights: true });
  };

  onCloseWeightsModal = () => {
    this.setState({ open_change_weights: false });
  };

  onChange = (uploader, e) => {
    this.setState({
      options: { ...this.state.options, ...uploader.uploads[0] },
      imgName: uploader.uploads[0].filename,
    });
  };

  refreshUploadComponent = () =>
    this.setState({ refresh_uploads: !this.state.refresh_uploads });

  handleFormSubmit = (e) => {
    e.preventDefault();
    let data = this.state.options;
    if (
      data.path.length &&
      data.name.length &&
      data.resolution_width.length &&
      data.resolution_height.length
    ) {
      this.setState({ form_loading: true });

      fetchInstance('/sendAiTemplate', {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error(res.status + ' ' + res.statusText);
        })
        .then((data) => {
          if (data.success) {
            this.setState({
              options: {
                path: '',
                name: '',
                resolution_width: '',
                resolution_height: '',
              },
              form_loading: false,
            });
            this.refreshUploadComponent();
            this.onCloseModal();
            this.getTemplates(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  setName = (e) => {
    const options = { ...this.state.options, name: e.target.value };
    this.setState(() => ({ options }));
  };

  setWidth = (e) => {
    const options = { ...this.state.options, resolution_width: e.target.value };
    this.setState(() => ({ options }));
  };

  setHeight = (e) => {
    const options = {
      ...this.state.options,
      resolution_height: e.target.value,
    };
    this.setState(() => ({ options }));
  };

  renderModal = () => {
    const { classes } = this.props;
    const { state } = this;
    const { handleFormSubmit, onChange } = this;

    return (
      <ModalStyling>
        <form
          ref={this.formRef}
          id="mainForm"
          action="/sendAiTemplate"
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleFormSubmit}
          className={classes.relative}
        >
          {this.state.form_loading && (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )}
          <div className="form-section">
            <div>
              <label htmlFor="template-name">
                <b>Template Name</b>
              </label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  id="template-name"
                  placeholder="template name"
                  onChange={this.setName}
                  type="text"
                  value={state.options.name}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="form-section">
            <FileUploader
              multiple={false}
              uploads={[state.options]}
              onChange={onChange}
              persistent={'True'}
              refreshForm={this.state.refresh_uploads}
              onRefreshed={this.refreshUploadComponent}
            />
            <br />
          </div>
          <div className="form-section">
            <div>
              <label htmlFor="template-width">
                <b>Template Intended Resolution (px)</b>
              </label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  id="template-width"
                  placeholder="Width"
                  onChange={this.setWidth}
                  type="number"
                  value={state.options.resolution_width}
                  style={{ width: '45%' }}
                  step="1"
                  min="1"
                />
                <span>&nbsp; X &nbsp;</span>
                <input
                  id="template-height"
                  placeholder="Height"
                  onChange={this.setHeight}
                  type="number"
                  value={state.options.resolution_height}
                  style={{ width: '45%' }}
                  step="1"
                  min="1"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '2rem',
            }}
          >
            <div>
              <input
                type="submit"
                value="Add"
                disabled={
                  !this.state.options.path.length &&
                  !this.state.options.name.length &&
                  !this.state.options.resolution_width.length &&
                  !this.state.options.resolution_height.length &&
                  !this.state.form_loading
                }
              />
              <input type="reset" onClick={this.onCloseModal} value="Cancel" />
            </div>
          </div>
        </form>
      </ModalStyling>
    );
  };

  removeTemplate = (template_id) => {
    this.setState({
      remove_template_id: template_id,
      open_remove_confirmation: true,
    });
  };

  handleRemoveCancel = () => {
    this.setState({
      remove_template_id: false,
      open_remove_confirmation: false,
    });
  };

  confirmTemplateRemoval = () => {
    var template = this.state.remove_template_id;
    console.log(template);
    if (template) {
      let data = {
        template_id: template,
      };
      fetchInstance('/removeAiTemplate', {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error(res.status + ' ' + res.statusText);
        })
        .then((data) => {
          if (data.success) {
            this.refreshUploadComponent();
            this.handleRemoveCancel();
            this.getTemplates(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  setWeightFromInput = (e) => {
    let new_weights = { ...this.state.new_weights };
    new_weights[e.target.name] = parseFloat(e.target.value);
    this.setState({ new_weights });
  };

  handleWeightsChange = () => {
    let new_weights = { ...this.state.weights };
    Object.keys(this.state.new_weights).forEach((key) => {
      new_weights[key] = this.state.new_weights[key];
    });
    this.setState({ weights: new_weights });
    this.setWeights(new_weights);
  };

  obtainWeightName = (v) => {
    let split_name = v.split('_');
    split_name = split_name[0] + ' ' + split_name[1];
    console.log('split name', split_name);
    return split_name;
  };

  render() {
    const { open } = this.state;
    const { classes } = this.props;

    return (
      <AIMainePage>
        <Modal
          open={this.state.open_remove_confirmation}
          onClose={this.handleRemoveCancel}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open_remove_confirmation}>
            <div className={classes.paper}>
              <h2>Remove this template?</h2>
              <CloseBtnModal onClick={this.handleRemoveCancel}>x</CloseBtnModal>
              <div className={classes.content} style={{ overflow: 'hidden' }}>
                <ModalStyling>
                  {this.state.remove_loading && (
                    <div className={classes.loader}>
                      <CircularProgress />
                    </div>
                  )}
                  <p>Are you sure you want to remove this template?</p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '2rem',
                    }}
                  >
                    <div>
                      <button
                        type="button"
                        onClick={this.confirmTemplateRemoval}
                        className={classes.submit_button}
                      >
                        Remove
                      </button>
                      <button
                        type="button"
                        onClick={this.onCloseModal}
                        className={classes.cancel_button}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </ModalStyling>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          open={this.state.open_change_weights}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open_change_weights}>
            <div className={classes.paper}>
              <h2>Change AI's weights?</h2>
              <CloseBtnModal onClick={() => this.onCloseWeightsModal()}>
                x
              </CloseBtnModal>
              <div className={classes.content} style={{ overflow: 'hidden' }}>
                <ModalStyling>
                  <p>The new values will be saved in the database</p>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {Object.keys(this.state.weights).map((v) => (
                      <div key={v}>
                        <InputLabel
                          variant="standard"
                          htmlFor={v}
                          style={{ margin: '2px', fontSize: 15 }}
                        >
                          {this.obtainWeightName(v)}
                        </InputLabel>
                        <Input
                          id={v}
                          name={v}
                          step="0.1"
                          placeholder="0"
                          type="number"
                          // multiline={true}
                          onChange={(evt) => this.setWeightFromInput(evt)}
                          defaultValue={this.state.weights[v]}
                          value={this.state.new_weights[v]}
                          style={{ width: '45%', fontSize: 15 }}
                        />
                      </div>
                    ))}
                  </div>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '2rem',
                    }}
                    className="form-section"
                  >
                    <button
                      type="submit"
                      onClick={this.handleWeightsChange}
                      className={classes.submit_button}
                    >
                      Change
                    </button>
                    <button
                      type="reset"
                      onClick={this.onCloseWeightsModal}
                      className={classes.cancel_button}
                    >
                      Cancel
                    </button>
                  </div>
                </ModalStyling>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          className={classes.modal}
          onClose={this.onCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <div className={classes.paper} id="ModalContent">
              <h2>Add a new template</h2>
              <CloseBtnModal onClick={this.onCloseModal}>x</CloseBtnModal>
              <div className={classes.content} style={{ overflow: 'hidden' }}>
                {this.renderModal()}
              </div>
            </div>
          </Fade>
        </Modal>

        <h3
          style={{ width: '90%', marginLeft: '5%', position: 'relative' }}
          className={classes.divider_title}
        >
          AI CONTEXT TEMPLATES
          <button
            title="Add New Template"
            onClick={() => {
              this.onOpenModal();
            }}
            style={{
              display: 'inline-flex',
              width: 'auto',
              margin: '2px',
              padding: '10px',
              position: 'absolute',
              right: 0,
              bottom: 0,
            }}
          >
            <AddToQueueIcon fontSize="large" />
          </button>
          <button
            title="Change AI weights"
            onClick={() => {
              this.onOpenWeightsModal();
            }}
            style={{
              display: 'inline-flex',
              width: 'auto',
              margin: '2px',
              padding: '10px',
              position: 'absolute',
              right: '50px',
              bottom: 0,
            }}
          >
            Change Weights
          </button>
        </h3>

        <div>
          {this.state.loading && (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )}
          {!this.state.loading && (
            <TableContent>
              {this.state.table_loading && (
                <div className="table-loader">
                  {' '}
                  <CircularProgress />{' '}
                </div>
              )}
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Image</th>
                    <th>Resolution</th>
                    <th>Added on</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="5"></td>
                  </tr>
                  {this.state.items &&
                    this.state.items.map((template, index) => (
                      <tr key={index}>
                        <td>
                          {(parseInt(this.state.pagination.page) - 1) *
                            parseInt(this.state.pagination.per_page) +
                            parseInt(index) +
                            1}
                        </td>
                        <td>
                          <img
                            height="100px"
                            src={
                              this.state.image_location +
                              template.accessible_location
                            }
                          />
                        </td>
                        <td>
                          {template.resolution_width} X{' '}
                          {template.resolution_height}
                        </td>
                        <td>{template.created_date}</td>
                        <td>
                          <button
                            onClick={() => {
                              this.removeTemplate(template.id);
                            }}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </TableContent>
          )}
        </div>
      </AIMainePage>
    );
  }
}

export default withStyles(styles)(AISettingsPage);
