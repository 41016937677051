import React, { useContext, useState, useEffect } from 'react';
import { Search, FilePlus } from 'react-bootstrap-icons';
import Select from 'react-select';
import ClientCard from '../ComponentsV2/Client/ClientCard';
import Pagination from '../ComponentsV2/Pagination/Pagination';
import config from '../config';
import fetchInstance from '../utils/fetchInstance';
import { useDebounce } from '../hooks/useDebounce';
import DefaultLayout from './DefaultLayout';
import Title from './styles/TitleStyle';
import FlexContainer from './styles/FlexContainer';
import { GeneralModalContext } from './GeneralModal';

import ClientDetails from './Clients/ClientDetails';
import { ClientSearchBarStyle } from './styles/ClientSearchBarStyle';
import DeleteModal from './Modals/DeleteModal';

const selectStyles = {
  control: (base) => ({
    ...base,
    border: '1px solid #e0e0e0',
    outline: 'none',
    boxShadow: 'none',
    fontSize: '14px',
    width: '235px',
    cursor: 'pointer',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#000',
    '&:hover': {
      color: '#000',
    },
    padding: '0 8px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const options = [
  { value: 'recently-added-campaigns', label: 'Recently Added Campaigns' },
  { value: 'recently-added-users', label: 'Recently Added Users' },
];

const ClientsMain = ({ extractCSRF, setBanner }) => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [visibleClients, setVisibleClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(true);
  const [, setActiveClient] = useState(null);
  const [modalType, setModalType] = useState('');
  const [currentFilter, setCurrentFilter] = useState('recently-added-users');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalClients, setTotalClients] = useState(0);

  const fetchClients = async (page = 1, name = '') => {
    setLoadingClients(true);
    try {
      const queryParams = new URLSearchParams({
        page: page.toString(),
        limit: config.ITEMS_PER_PAGE.toString(),
        filter: currentFilter,
        ...(name && { name }),
      });

      const res = await fetchInstance(`/getClients?${queryParams}`);
      if (!res.ok) throw new Error(res.statusText);

      const data = await res.json();
      if (!data.error) {
        setClients(data.clients);
        setVisibleClients(data.clients);
        setTotalPages(data.totalPages);
        setTotalClients(data.totalClients);
        setCurrentPage(page);
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      setBanner(true, 'bad', err.message);
    } finally {
      setLoadingClients(false);
    }
  };

  useEffect(() => {
    fetchClients(currentPage);
  }, [currentFilter]);

  const handlePageChange = (page) => {
    fetchClients(page);
  };

  const openDeleteModal = (client) => {
    setClient(client);
    setModalType('delete-client');
    handleShowModal();
  };

  const openClientModal = (client) => {
    setClient(client);
    setModalType('edit-client');
    handleShowModal();
  };

  const debouncedFetchClients = useDebounce((searchTerm) => {
    fetchClients(1, searchTerm);
  });

  const handleSearchClientsInput = (ev) => {
    const searchTerm = ev.target.value.toLowerCase();
    setCurrentPage(1);
    debouncedFetchClients(searchTerm);
  };

  const handleClientFilter = (ev) => {
    if (ev.value !== currentFilter) {
      setCurrentFilter(ev.value);
      setCurrentPage(1);
    }
  };

  const deleteClient = (ev) => {
    fetchInstance('/client', {
      method: 'delete',
      body: JSON.stringify({ client }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.error) throw new Error(data.message);
        fetchClients(currentPage);
        setActiveClient(null);
        handleCloseModal();
      })
      .catch((err) => setBanner(true, 'bad', err.message, false));
  };

  const updateClients = async (updatedClient) => {
    try {
      await fetchClients(currentPage);
      setClient(null);
      handleCloseModal();
      setBanner(true, 'good', 'Client updated successfully');
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const { showModal, handleShowModal, handleCloseModal } =
    useContext(GeneralModalContext);

  let DeleteModalUx, EditModal;

  if (showModal && modalType === 'delete-client') {
    DeleteModalUx = (
      <DeleteModal
        key={client._id}
        acceptFunction={deleteClient}
        showModal={showModal}
        message={['Do you really want to delete ', <b>{client.name}</b>, '?']}
        handleCloseModal={handleCloseModal}
      />
    );
  }

  if (showModal && modalType === 'edit-client') {
    EditModal = (
      <ClientDetails
        extractCSRF={extractCSRF}
        client={client}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        updateClients={updateClients}
        setBanner={setBanner}
        edit={Boolean(client)}
      />
    );
  }

  return (
    <DefaultLayout>
      <FlexContainer
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
      >
        <Title>Clients</Title>
        <ClientSearchBarStyle
          style={{ marginBottom: '4rem', textAlign: 'right' }}
        >
          <FlexContainer
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <span className="search-client-wrapper">
              <Search />
              <input
                onChange={handleSearchClientsInput}
                id="search-client"
                type="text"
                placeholder="Search Client"
              />
            </span>

            <div className="filter-add-client">
              <button
                className="confirm-button"
                onClick={() => {
                  openClientModal(null);
                }}
              >
                <FilePlus />
                <span>New client</span>
              </button>
            </div>
          </FlexContainer>
        </ClientSearchBarStyle>
      </FlexContainer>

      <FlexContainer direction="row" gap="20px" justifyContent="start">
        {visibleClients.length !== 0 &&
          visibleClients.map((client) => {
            return (
              <ClientCard
                client={client}
                openDeleteModal={openDeleteModal}
                openEditModal={openClientModal}
                key={client._id}
              />
            );
          })}
        {loadingClients === false && visibleClients.length === 0 && (
          <h2>No clients found</h2>
        )}
      </FlexContainer>

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}

      {DeleteModalUx}
      {EditModal}
    </DefaultLayout>
  );
};

export default ClientsMain;
