import React from 'react';
import { Ban, Shuffle, ArrowDown } from 'react-bootstrap-icons';
import cx from 'classnames';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../../shared/enums/buttonEnum';
import Button from '../../../../../../Components/FormComponents/Button';
import styles from './ActionsButtons.module.css';

const ActionsButtons = ({
  isMainResolution,
  selectedResolution,
  disabledResolutions,
  handleCopyToAll,
  handleCopyFromMain,
  handleDisable,
  isDisabled,
}) => {
  return (
    <div className={cx(styles.root, isDisabled && styles.isDisabled)}>
      {isMainResolution && (
        <Button
          type="button"
          variant={ButtonVariantEnum.TEXT}
          onClick={handleCopyToAll}
          size={ButtonSizeEnum.S}
          leftIcon={<Shuffle />}
        >
          Copy to all resolutions
        </Button>
      )}
      {!isMainResolution && (
        <Button
          variant={ButtonVariantEnum.TEXT}
          onClick={() => handleCopyFromMain(selectedResolution)}
          size={ButtonSizeEnum.S}
          leftIcon={<ArrowDown />}
        >
          Copy from main
        </Button>
      )}
      <Button
        variant={ButtonVariantEnum.TEXT}
        className={styles.disableButton}
        size={ButtonSizeEnum.S}
        onClick={() => handleDisable(selectedResolution)}
        leftIcon={<Ban />}
      >
        {disabledResolutions.includes(selectedResolution)
          ? 'Disabled'
          : 'Disable'}{' '}
        on this resolution
      </Button>
    </div>
  );
};

export default ActionsButtons;
