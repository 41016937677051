import React from 'react';
import cx from 'classnames';
import { useFormCard } from '../hooks/useFormCard';
import Input from '../../../Components/FormComponents/Input';
import styles from '../styles/Cards.module.scss';

const URL_BUILDER_INPUTS = [
  {
    columns: 3,
    inputs: [
      {
        name: 'urlBuilder.websiteUrl',
        type: 'text',
        placeholder: 'The full website URL (e.g. https://www.example.com)',
        label: 'Website URL',
      },
      {
        name: 'urlBuilder.campaignSource',
        type: 'text',
        placeholder: 'The referrer (e.g. google, newsletter)',
        label: 'Campaign Source',
      },
      {
        name: 'urlBuilder.campaignMedium',
        type: 'text',
        placeholder: 'Marketing medium (e.g. cpc, banner, email)',
        label: 'Campaign Medium',
      },
    ],
  },
  {
    columns: 3,
    inputs: [
      {
        name: 'urlBuilder.campaignName',
        type: 'text',
        placeholder: 'Product, promo code or slogan',
        label: 'Campaign Name',
      },
      {
        name: 'urlBuilder.campaignTerm',
        type: 'text',
        placeholder: 'Identify the paid keywords',
        label: 'Campaign Term',
      },
      {
        name: 'urlBuilder.campaignContent',
        type: 'text',
        placeholder: 'Use to differentiate ads',
        label: 'Campaign Content',
      },
    ],
  },
];

const URLBuilderCard = ({ disabled, onComplete }) => {
  const { values, handleInputChange, handleBlur, touched, errors } =
    useFormCard();

  const handleBlurWithComplete = (e) => {
    handleBlur(e);
    if (
      values.urlBuilder.websiteUrl &&
      values.urlBuilder.campaignSource &&
      values.urlBuilder.campaignMedium &&
      values.urlBuilder.campaignName &&
      values.urlBuilder.campaignTerm
    ) {
      const urlBuilderData = values.urlBuilder;
      onComplete({
        ...urlBuilderData,
        finalUrl: urlBuilderData.websiteUrl,
      });
    }
  };

  return (
    <div className={cx(styles.card, { [styles.disabled]: disabled })}>
      <h2 className={styles.title}>URL Builder</h2>
      <div className={styles.formGrid}>
        {URL_BUILDER_INPUTS.map((rowConfig, index) => (
          <div
            key={`row-${index}`}
            className={cx(styles.row, {
              [styles.threeColumns]: rowConfig.columns === 3,
              [styles.twoColumns]: rowConfig.columns === 2,
              [styles.fullWidth]: rowConfig.columns === 1,
            })}
          >
            {rowConfig.inputs.map((input) => (
              <Input
                key={input.name}
                {...input}
                label={input.label}
                value={values?.urlBuilder?.[input.name.split('.')[1]]}
                onChange={handleInputChange}
                onBlur={handleBlurWithComplete}
                error={
                  touched?.urlBuilder?.[input.name.split('.')[1]] &&
                  errors?.urlBuilder?.[input.name.split('.')[1]]
                }
                disabled={disabled}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default URLBuilderCard;
