import React, { Component } from 'react';
import { AspectRatioFill } from 'react-bootstrap-icons';

import Button from '../../../../../Components/FormComponents/Button';
import { ButtonSizeEnum } from '../../../../../shared/enums/buttonEnum';

import BannerModal from '../../BannerModal';
import BannerBackgroundOptions from './BannerBackgroundOptions';

class BannerBackgroundButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { state, handleOpen, handleClose } = this;
    const { resolutions, currentBackgrounds } = this.props;

    return (
      <>
        <Button
          onClick={handleOpen}
          leftIcon={<AspectRatioFill />}
          size={ButtonSizeEnum.S}
        >
          Background
        </Button>

        <BannerModal
          title="Set background image"
          open={state.open}
          handleClose={handleClose}
        >
          <BannerBackgroundOptions
            closeModal={handleClose}
            resolutions={resolutions}
            existingBackgrounds={currentBackgrounds}
          />
        </BannerModal>
      </>
    );
  }
}

export default BannerBackgroundButton;
