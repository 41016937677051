import BannerSetStatusEnum from '../shared/enums/bannerSetStatus';
import config from '../config';

/**
 * Gets the appropriate URL for a banner set based on its status
 * @param {string} status - The status of the banner set
 * @param {string} bannerSetId - The ID of the banner set
 * @returns {string|null} The URL for the banner set or null if status is not handled
 */
export const getBannerSetUrl = (status, bannerSetId) => {
  switch (status) {
    case BannerSetStatusEnum.CREATOR:
      return `${config.appRoutes.bannerCreator}/${bannerSetId}`;
    case BannerSetStatusEnum.MIXER:
      return `${config.appRoutes.bannerMixer}/${bannerSetId}`;
    case BannerSetStatusEnum.MIXING:
      return `${config.appRoutes.bannerMixing}/${bannerSetId}`;
    case BannerSetStatusEnum.GALLERY:
      return `${config.appRoutes.gallery}/${bannerSetId}`;
    default:
      return null;
  }
};

/**
 * Checks if a banner set status requires a redirect and performs the redirect if needed
 * @param {string} status - The status of the banner set
 * @param {string} bannerSetId - The ID of the banner set
 * @param {object} history - The history object from react-router-dom
 * @returns {boolean} Returns true if a redirect was performed, false otherwise
 */
const handleBannerSetRedirect = (status, bannerSetId, history) => {
  const redirectUrl = getBannerSetUrl(status, bannerSetId);

  if (!redirectUrl) {
    return false;
  }

  const currentPath = window.location.pathname.replace(/^\//, '');
  const redirectPath = redirectUrl.replace(/^\//, '');

  if (currentPath !== redirectPath) {
    history.replace(redirectUrl);
    return true;
  }

  return false;
};

export default handleBannerSetRedirect;
