import React from 'react';
import { ArrowRightShort, ArrowLeftShort } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import Button from '../../../../Components/FormComponents/Button';
import { ButtonVariantEnum } from '../../../../shared/enums/buttonEnum';
import styles from './NavigationButtons.module.css';

const NavigationButtons = ({
  selectedResolution,
  availableResolutions,
  onPrevious,
  onNext,
  onSubmit,
  submitLabel = 'Submit',
}) => {
  const isFirstResolution =
    availableResolutions.indexOf(selectedResolution) === 0;
  const isLastResolution =
    availableResolutions.indexOf(selectedResolution) ===
    availableResolutions.length - 1;

  return (
    <div className={styles.root}>
      <Button
        className={styles.navButton}
        onClick={onPrevious}
        disabled={isFirstResolution}
        variant={ButtonVariantEnum.SECONDARY}
        leftIcon={<ArrowLeftShort />}
      >
        Previous
      </Button>
      {isLastResolution ? (
        <Button
          className={styles.navButton}
          onClick={onSubmit}
          variant={ButtonVariantEnum.PRIMARY}
          rightIcon={<ArrowRightShort />}
        >
          {submitLabel}
        </Button>
      ) : (
        <Button
          className={styles.navButton}
          onClick={onNext}
          variant={ButtonVariantEnum.PRIMARY}
          rightIcon={<ArrowRightShort />}
        >
          Next
        </Button>
      )}
    </div>
  );
};

NavigationButtons.propTypes = {
  selectedResolution: PropTypes.string.isRequired,
  availableResolutions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
};

export default NavigationButtons;
