import React, { Component } from 'react';
import cx from 'classnames';
import fetchInstance from '../../utils/fetchInstance';
import './FileUploader.scss';

class FileUploader extends Component {
  static defaultProps = {
    multiple: false,
    persistent: null,
    required: false,
    label: 'Upload an image',
    extensions: '.png,.jpg,.jpeg,.svg,.gif',
    preview: 'true',
    onChange: () => {},
    className: '',
    inputClassName: '',
  };

  constructor(props) {
    super(props);

    this.persistent = props.persistent;
    this.multiple = props.multiple;
    this.onChange = props.onChange;
    this.onRefreshed = props.onRefreshed;
    this.original = props.original ? props.original : false;

    this.state = {
      refreshForm: props.refreshForm,
      files: props.files || null,
      uploads: props.uploads || null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.refreshForm !== prevState.refreshForm) {
      return {
        refreshForm: nextProps.refreshForm,
        files: nextProps.files || null,
        uploads: nextProps.uploads || null,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshForm !== this.props.refreshForm && this.onRefreshed) {
      this.onRefreshed();
    }
  }

  onChangeHandler = (event) => {
    let files = event.target.files;

    const form = new FormData();

    form.append('persistent', this.persistent);
    form.append('multiple', this.multiple);
    form.append('original', this.original);

    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        form.append('files', files[i]);
      }

      fetchInstance('/store', {
        method: 'POST',
        body: form,
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.files) return;

          this.setState(
            {
              files: files,
              uploads: res.files,
            },
            () => {
              if (this.onChange) {
                this.onChange(this.state, event);
              }
            },
          );
        })
        .catch((err) => {
          console.error('Error uploading files:', err);
        });
    }
  };

  renderThumbnails = () => {
    if (!this.state.uploads) return;

    return this.state.uploads.map((image) => (
      <img
        className="thumbnail"
        style={{ marginTop: '1rem' }}
        key={`image-${image.filename}`}
        src={image.path}
        width="auto"
        height="100px"
        alt={image.filename}
      />
    ));
  };

  render() {
    const {
      className,
      inputClassName,
      label,
      extensions,
      preview,
      onChange,
      onRefreshed,
      refreshForm,
      files,
      uploads,
      persistent,
      original,
      ...inputProps
    } = this.props;

    const { onChangeHandler, renderThumbnails } = this;

    const thumbStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: renderThumbnails() ? '' : 'center',
    };

    return (
      <div className={cx('file-uploader file-uploader-root', className)}>
        <label className="btn-default" htmlFor="bg-img">
          {label}
        </label>
        <input
          accept={extensions}
          style={{ display: 'none' }}
          id="bg-img"
          type="file"
          className={cx('form-control', inputClassName)}
          onChange={onChangeHandler}
          {...inputProps}
        />
        {preview === 'true' &&
        this.state.uploads &&
        this.state.uploads.length >= 1 ? (
          <div className="thumbnails" style={thumbStyle}>
            {renderThumbnails()}
          </div>
        ) : null}
      </div>
    );
  }
}

export default FileUploader;
