import styled from 'styled-components';

const CampaignDrawerStyles = styled.div`
  position: absolute;
  background: ${(props) => props.theme.lightBlue};
  width: 100%;
  margin: auto;
  grid-template-columns: 1fr;
  font-size: 1em;
  color: white;
  top: 100%;
  transition: all 0.2s ease-out;
  opacity: 0;
  padding: 2rem 3rem;
  z-index: 10;

  &.drawer-enter-done {
    /* background: red; */
    opacity: 1;
  }
  ul {
    text-transform: uppercase;
    font-weight: 600;
    list-style: none;
    padding: 0;
    margin: 0;
    column-count: 3;
    min-height: 70vh;
    li {
      cursor: pointer;
    }
  }
`;

export default CampaignDrawerStyles;
