import styled from 'styled-components';

export const GroupColorBox = styled.div`
  background-color: ${({ color }) => color || 'unset'};
  border-radius: 5px;
  height: ${({ size }) => size || '16px'};
  width: ${({ size }) => size || '16px'};
  cursor: ${({ editable }) => (editable ? 'pointer' : 'unset')};
  position: ${({ editable }) => (editable ? 'relative' : 'unset')};
`;
