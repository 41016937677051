import React from 'react';
import { StackedContainer, StackedImage } from './styled/Stacked';

export const StackedImages = ({
  images,
  overlapPercentage = 80,
  heightDecreasePercentage = 10,
}) => {
  const numImages = images.length;

  // Ensure percentages are between 0 and 100
  const validOverlap = Math.max(0, Math.min(overlapPercentage, 100));
  const validHeightDecrease = Math.max(
    0,
    Math.min(heightDecreasePercentage, 100),
  );

  // Calculate denominator for image width calculation
  const denominator = 1 + (numImages - 1) * (1 - validOverlap / 100);

  // Calculate image width as percentage
  const imageWidth = (1 / denominator) * 100;

  // Calculate shift per image
  const shiftPercentage = (1 - validOverlap / 100) * imageWidth;

  // Set initial height percentage
  const initialHeight = 100; // Start with 100% of the container height

  return (
    <StackedContainer>
      {images.map(({ src }, index) => {
        const left = shiftPercentage * index;
        const height =
          initialHeight * Math.pow(1 - validHeightDecrease / 100, index);

        return (
          <StackedImage
            key={index}
            src={src}
            alt={`Image ${index + 1}`}
            left={left}
            width={imageWidth}
            height={height}
            zIndex={numImages - index} // Reverse z-index to bring first images on top
          />
        );
      })}
    </StackedContainer>
  );
};
