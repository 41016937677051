import React, { createContext, useContext, useMemo, useState } from 'react';
import { StackedResolution } from '../FormComponents/ResolutionButtons';

export const AllBannersGroup = { value: 'all', label: 'All' };
export const UngroupedBannersGroup = { value: 'ungrouped', label: 'Ungrouped' };

/**
 * @typedef {Object} FilterBannerGroupType
 * @property {string} value - Value of the banner set group
 * @property {string} label - Label of the banner set group
 * @property {*[]} [banners] - Array of banners assigned to group
 * @property {ReactElement} [startReactElement] - Custom node to display before the label
 * @property {ReactElement} [endReactElement] - Custom node to display after the label
 */

/**
 * @typedef {Object} FiltersType
 * @property {string[]} networkResolutions - Array of available network resolutions
 * @property {function} setNetworkResolutions - Function that sets the available network resolutions
 * @property {string} selectedResolution - Array of currently selected resolutions
 * @property {function} setSelectedResolution - Function that sets the currently selected resolutions
 * @property {FilterBannerGroupType[]} bannerGroups - Array of available banner set groups
 * @property {function} setBannerGroups - Function that sets the available banner set groups
 * @property {FilterBannerGroupType} selectedBannerGroup - Currently selected banner set group
 * @property {function} setSelectedBannerGroup - Function that sets the currently selected banner set group
 *
 */

/**
 * @typedef {Object} AiScoresType
 * @property {Map<string, number>} aiScoresData - Map of AI scores for each banner path
 * @property {function} setAiScoresData - Function that sets the AI scores
 * @property {boolean} showAiScores - Whether to show the AI scores
 * @property {function} setShowAiScores - Function that sets whether to show the AI scores
 */

/**
 * @typedef {Object} GalleryContextType
 * @property {FiltersType} filters - Filters for the gallery
 * @property {*[]} bannerGroups - Array of available banner set groups
 * @property {function} setBannerGroups - Function that sets the available banner set groups
 * @property {*[]} bannersList - Array of available banners
 * @property {function} setBannersList - Function that sets the available banners
 * @property {string[]} selectedBannersIds - Array of selected banners
 * @property {function} setSelectedBannersIds - Function that sets the selected banners
 * @property {Record<string, *[]>} bannerGroupsMap - Object that store the banner set groups per banner id
 * @property {function} setBannerGroupsMap - Function that sets the available banner set groups
 * @property {string | null} bannerSetId - Current banner set id
 * @property {function} setBannerSetId - Function that sets the current banner set id
 * @property {*} bannerSet - Current banner set
 * @property {function} setBannerSet - Function that sets the current banner set
 * @property {Symbol} refetchBannerGroupsSymbol - Symbol to trigger a refetch
 * @property {function} refetchBannerGroups - Function that sets the refetch symbol
 * @property {*} currentCampaign - Current campaign
 * @property {function} setCurrentCampaign - Function that sets the current campaign
 * @property {AiScoresType} aiScores - AI scores
 *
 */

/**
 * @type {React.Context<GalleryContextType>}
 */
const GalleryContext = createContext({
  filters: {
    networkResolutions: [],
    setNetworkResolutions: (value) => {},
    selectedResolution: StackedResolution,
    setSelectedResolution: (value) => {},
    bannerGroups: [],
    setBannerGroups: (value) => {},
    selectedBannerGroup: AllBannersGroup,
    setSelectedBannerGroup: (value) => {},
  },
  aiScores: {
    showAiScores: true,
    setShowAiScores: (value) => {},
    aiScoresData: new Map(),
    setAiScoresData: (value) => {},
  },
  bannerGroups: [],
  setBannerGroups: (value) => {},
  bannerGroupsMap: {},
  setBannerGroupsMap: (value) => {},
  bannersList: [],
  setBannersList: (value) => {},
  selectedBannersIds: [],
  setSelectedBannersIds: (value) => {},
  bannerSet: null,
  setBannerSet: (value) => {},
  bannerSetId: null,
  setBannerSetId: (value) => {},
  refetchBannerGroupsSymbol: null,
  refetchBannerGroups: (value) => {},
  currentCampaign: null,
  setCurrentCampaign: (value) => {},
});

/**
 * Context provider for Gallery
 * @param {{children: React.ReactNode}} props
 * @returns {JSX.Element}
 */
export const GalleryContextProvider = ({ children }) => {
  const [bannerSetId, setBannerSetId] = useState(null);
  const [bannerSet, setBannerSet] = useState(null);
  const [networkResolutions, setNetworkResolutions] = useState([]);
  const [bannerGroups, setBannerGroups] = useState([]);
  const [bannerGroupsMap, setBannerGroupsMap] = useState({});
  const [filterBannerGroups, setFilterBannerGroups] = useState([]);
  const [bannersList, setBannersList] = useState([]);
  const [selectedResolution, setSelectedResolution] =
    useState(StackedResolution);

  const [selectedBannerGroup, setSelectedBannerGroup] =
    useState(AllBannersGroup);

  const [refetchBannerGroupsSymbol, setRefetchBannerGroupsSymbol] =
    useState(null);

  const [selectedBannersIds, setSelectedBannersIds] = useState([]);

  const [aiScoresData, setAiScoresData] = useState(new Map());

  const [showAiScores, setShowAiScores] = useState(true);
  const [currentCampaign, setCurrentCampaign] = useState(false);

  const refetchBannerGroups = () => {
    setRefetchBannerGroupsSymbol(Symbol());
  };

  return (
    <GalleryContext.Provider
      value={{
        filters: {
          networkResolutions,
          setNetworkResolutions,
          selectedResolution,
          setSelectedResolution,
          selectedBannerGroup,
          setSelectedBannerGroup,
          bannerGroups: filterBannerGroups,
          setBannerGroups: setFilterBannerGroups,
        },
        aiScores: {
          showAiScores,
          setShowAiScores,
          aiScoresData,
          setAiScoresData,
        },
        bannerGroups,
        setBannerGroups,
        bannerGroupsMap,
        setBannerGroupsMap,
        bannersList,
        setBannersList,
        selectedBannersIds,
        setSelectedBannersIds,
        bannerSet,
        setBannerSet,
        bannerSetId,
        setBannerSetId,
        refetchBannerGroupsSymbol,
        refetchBannerGroups,
        currentCampaign,
        setCurrentCampaign,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

/**
 *
 * @returns {GalleryContextType}
 */
export const useGalleryContext = () => useContext(GalleryContext);
