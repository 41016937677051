import React from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import cx from 'classnames';

import styles from '../../styles/Cards.module.scss';
import Select from '../../../../Components/FormComponents/Select';
import { FACEBOOK_PLACEMENTS } from '../../constants/facebookPlacements';
import Dropdown from '../../../Dropdown/Dropdown';
import Button from '../../../../Components/FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../shared/enums/buttonEnum';
import { useBannerPlacementsCard } from '../../hooks/facebook/useBannerPlacementsCard';
import PlacementBox from './PlacementBox';

const BannersPlacementsCard = ({
  disabled,
  onComplete,
  availableResolutions,
}) => {
  const {
    loading,
    selectedGroup,
    placements,
    handleGroupChange,
    handleAddPlacement,
    handleResolutionToggle,
    handleRemovePlacement,
    groupOptions,
  } = useBannerPlacementsCard({ onComplete, availableResolutions });

  const dropdownItems = Object.entries(FACEBOOK_PLACEMENTS).map(
    ([key, placement]) => ({
      id: key,
      label: placement.name,
      disabled: placements.some((p) => p.id === key),
      data: placement,
    }),
  );

  const handlePlacementSelect = (item) => {
    handleAddPlacement(item.id, item.data);
  };

  return (
    <div className={cx(styles.card, { [styles.disabled]: disabled })}>
      <h2 className={styles.title}>Banners</h2>
      <div className={styles.content}>
        <Select
          name="banners.group"
          value={selectedGroup?._id || ''}
          onChange={handleGroupChange}
          options={groupOptions}
          label="Banner group"
          disabled={disabled || loading}
        />

        <div className={styles.placementsSection}>
          <div className={styles.placementsTitle}>
            Placements and resolutions
          </div>
          {placements.length > 0 && (
            <div className={styles.placementBoxes}>
              {placements.map((placement) => (
                <PlacementBox
                  key={placement.id}
                  placement={placement}
                  availableResolutions={availableResolutions}
                  selectedResolutions={placement.resolutions}
                  onResolutionToggle={(resolution) =>
                    handleResolutionToggle(placement.id, resolution)
                  }
                  onRemove={() => handleRemovePlacement(placement.id)}
                />
              ))}
            </div>
          )}
          <Dropdown
            trigger={
              <Button
                className={styles.addPlacementButton}
                size={ButtonSizeEnum.S}
                variant={ButtonVariantEnum.SECONDARY}
                disabled={disabled || loading}
                rightIcon={<ChevronDown />}
              >
                Add new placement
              </Button>
            }
            items={dropdownItems}
            onSelect={handlePlacementSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default BannersPlacementsCard;
