import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import CampaignNav from './CampaignNav';
import Loader from './styles/Loader';
import CampaignContext from './CampaignContext';

class CampaignsMainPage extends Component {
  render() {
    return (
      <CampaignContext.Consumer>
        {(context) => (
          <>
            {context.loadingCampaigns && <Loader />}
            {context.activeCampaign && <Redirect to="/campaign" />}
            <CampaignNav showingCampaignDrawer={true} />
          </>
        )}
      </CampaignContext.Consumer>
    );
  }
}

CampaignsMainPage.contextType = CampaignContext;
export default CampaignsMainPage;
