import React from 'react';
import { Trash, PencilSquare } from 'react-bootstrap-icons';
import cx from 'classnames';
import Button from '../../../../../Components/FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../shared/enums/buttonEnum';
import styles from './FieldWrapper.module.css';

export const FieldWrapper = ({
  label,
  children,
  onPreviewClick,
  whiteBox,
  onEdit,
  onRemove,
  isActive,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <label className={styles.label} onClick={onPreviewClick}>
          {label}
        </label>
        <div className={styles.actions}>
          {onEdit && (
            <Button
              className={styles.editButton}
              variant={ButtonVariantEnum.TEXT}
              size={ButtonSizeEnum.S}
              onClick={onEdit}
            >
              <PencilSquare />
            </Button>
          )}
          {onRemove && (
            <Button
              className={styles.disableButton}
              variant={ButtonVariantEnum.TEXT}
              size={ButtonSizeEnum.XS}
              onClick={() => onRemove()}
            >
              <Trash />
            </Button>
          )}
        </div>
      </div>
      <div
        className={cx(
          styles.inner,
          whiteBox && styles.whiteBox,
          isActive && styles.active,
        )}
      >
        {onPreviewClick && (
          <div
            className={styles.boxClickableOverlay}
            onClick={onPreviewClick}
          />
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default FieldWrapper;
