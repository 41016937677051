export const generateFinalUrl = (urlBuilder) => {
  if (!urlBuilder.websiteUrl) return '';

  const params = new URLSearchParams();
  if (urlBuilder.campaignSource)
    params.append('utm_source', urlBuilder.campaignSource);
  if (urlBuilder.campaignMedium)
    params.append('utm_medium', urlBuilder.campaignMedium);
  if (urlBuilder.campaignName)
    params.append('utm_campaign', urlBuilder.campaignName);
  if (urlBuilder.campaignTerm)
    params.append('utm_term', urlBuilder.campaignTerm);
  if (urlBuilder.campaignContent)
    params.append('utm_content', urlBuilder.campaignContent);

  const queryString = params.toString();
  return `${urlBuilder.websiteUrl}${queryString ? '?' + queryString : ''}`;
};
