import React, { Component } from 'react';
import { X } from 'react-bootstrap-icons';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import './Styles/forms.scss';

const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 4px 15px rgb(0 0 0 / 7%), 0 1px 3px rgb(0 0 0 / 7%)',
    padding: theme.spacing(2, 4, 3),
    width: 800,
    border: 'none',
    outline: 'none',
    position: 'relative',
  },
});

class BannerModal extends Component {
  constructor(props) {
    super(props);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, handleClose } = this.props;
    const { props } = this;

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={props.open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          styles={{ overlay: { background: '#FFFF00' } }}
        >
          <Fade in={props.open}>
            <div
              className={classes.paper}
              style={{ borderRadius: '5px', borderColor: '1px solid #e0e0e0' }}
            >
              <button
                type="button"
                onClick={handleClose}
                className="modal-close-button"
              >
                <X color="white" width={24} height={24} />
              </button>
              <h2 id="transition-modal-title" className="modal-title">
                {props.title}
              </h2>
              <div className="modal-content">{props.children}</div>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
}

export default withStyles(styles)(BannerModal);
