import React from 'react';
import styles from './ErrorBox.module.scss';

const ErrorFormBox = ({ message }) => {
  if (!message) return null;

  return <div className={styles.root}>{message}</div>;
};

export default ErrorFormBox;
