import React from 'react';
import { PlusSquare } from 'react-bootstrap-icons';
import Button from '../../../../../../Components/FormComponents/Button';
import { BulkUpload } from '../../BulkUpload/BulkUpload';
import ImagesSet from './ImagesSet';
import styles from './ImagesSets.module.css';

const ImagesSets = ({
  baseSet,
  customVariants,
  onRemoveSet,
  onAddNewSet,
  onPreviewClick,
  activeSet,
  onImageUpload,
  onImageRemove,
  onImageDisable,
  preserveResolutionAspect,
  isBackground,
  onCopyMainToAll,
  onCopyFromMain,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.imagesSets}>
        <ImagesSet
          variations={baseSet}
          notEditable
          label="Base"
          preserveResolutionAspect={preserveResolutionAspect}
          isBaseSet
        />
        {customVariants.map((imageSet, index) => (
          <ImagesSet
            key={imageSet.id}
            setId={imageSet.id}
            variations={imageSet.variations}
            label={`Variant ${index + 1}`}
            onRemove={() => onRemoveSet(imageSet.id, imageSet.variations)}
            onPreview={onPreviewClick}
            isActive={
              isBackground
                ? activeSet === imageSet.variations
                : activeSet === imageSet.id
            }
            onImageUpload={onImageUpload}
            onImageRemove={onImageRemove}
            onImageDisable={onImageDisable}
            preserveResolutionAspect={preserveResolutionAspect}
            isBackground={isBackground}
            onCopyMainToAll={onCopyMainToAll}
            onCopyFromMain={onCopyFromMain}
            showActions
          />
        ))}
      </div>
      <div className={styles.footerActions}>
        <Button onClick={onAddNewSet} leftIcon={<PlusSquare />}>
          Add new variation
        </Button>
        <BulkUpload isBackground={isBackground} elementId={baseSet._id} />
      </div>
    </div>
  );
};

export default ImagesSets;
