import styled from 'styled-components';

export default styled.form`
  h1 {
    margin-top: 10px;
    text-align: center;
  }

  label {
    font-size: 14px;
  }

  input,
  textarea,
  select {
    line-height: 16px;
    padding: 12px 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 100%;
    background: #fff;
    &:focus {
      outline: 0;
    }
  }

  input[type='file'] {
    border: none;
    padding-left: 0;
  }

  input[type='file'],
  select {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  input,
  select {
    height: 36px;
  }

  .group {
    margin-bottom: 10px;
    // display: flex;
    // flex-direction: column;
  }

  .button-wrapper {
    text-align: center;
  }

  input[type='checkbox'] {
    display: none;
    & + label {
      width: 52px;
      height: 24px;
      cursor: pointer;
      border: solid 2px ${(props) => props.theme.lightGrey};
      display: block;
      position: relative;
      border-radius: 50px;

      &:before {
        content: '';
        position: absolute;
        border: solid 2px white;
        left: 0;
        width: 26px;
        border-radius: 50px;
        height: calc(24px - 4px);
        background: ${(props) => props.theme.lightGrey};
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
      }
    }
  }

  input[type='checkbox']:checked {
    & + label {
      &:before {
        background: ${(props) => props.theme.lightBlue};
        left: 22px;
      }
    }
  }

  .form-button {
    color: white;
    padding: 1rem 3rem;
    font-weight: bold;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 2rem;
    margin-top: 10px;
    width: initial;
    &:hover,
    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  .form-save {
    background: #52bad5;
  }

  .form-delete {
    background: ${(props) => props.theme.red};
    margin-right: 10px;
  }

  .back-button {
    text-align: right;
    margin-bottom: -34px;
  }

  .back-button a {
    text-decoration: none;
    color: rgb(57, 57, 57);
    background: #000;
    padding: 13px 25px;
    color: #fff;
    border-radius: 0px 5px 0px 0px;
    position: relative;
    top: -18px;
    right: -27px;
  }

  .brandstyles {
    display: flex;

    select {
      margin-right: 1rem;
      width: 35%;
      font-family: 'Open Sans';
    }

    input {
      margin-right: 1rem;
    }

    &__description {
      position: relative;
      width: 100%;

      svg {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 25px;
        cursor: pointer;
        border: 1px solid ${(props) => props.theme.blue};
        color: ${(props) => props.theme.blue};
        border-radius: ${(props) => props.theme.borderRadius};
      }
    }
  }
`;
