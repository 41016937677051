import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Trash3, PencilSquare, PlusSquare } from 'react-bootstrap-icons';
import cx from 'classnames';
import { useMixer } from '../../MixerContext';
import Button from '../../../../../Components/FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../shared/enums/buttonEnum';
import styles from './Color.module.css';
import FieldWrapper from './FieldWrapper';

const ColorOption = ({ color, onEdit, onRemove, index, name }) => {
  const [showPicker, setShowPicker] = useState(false);
  const { handlePreviewOption, previewOptions } = useMixer();

  const isActive =
    previewOptions[name]?.value === color &&
    previewOptions[name]?.index === index;

  const handleClick = (e) => {
    e.stopPropagation();
    handlePreviewOption({ name, value: color, index });
  };

  const handleRemove = () => {
    onRemove(index);
    handlePreviewOption({ name, forceRemove: true });
  };

  return (
    <div className={cx(styles.colorOption, isActive && styles.previewActive)}>
      <div className={styles.clickableWrapper} onClick={handleClick} />
      {onEdit && showPicker && (
        <div className={styles.popover}>
          <div className={styles.cover} onClick={() => setShowPicker(false)} />
          <SketchPicker color={color} onChange={onEdit} disableAlpha={true} />
        </div>
      )}
      <div className={styles.colorPreview} style={{ backgroundColor: color }} />
      <div className={styles.footer}>
        <div className={styles.colorName}>{color}</div>
        {onEdit && (
          <Button
            className={styles.editButton}
            onClick={() => setShowPicker(true)}
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.S}
          >
            <PencilSquare />
          </Button>
        )}
        {onRemove && (
          <Button
            className={styles.removeButton}
            onClick={handleRemove}
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.S}
          >
            <Trash3 />
          </Button>
        )}
      </div>
    </div>
  );
};

export const ColorField = ({ label, name }) => {
  const { handleOptionChange, rootOptions } = useMixer();

  const values = rootOptions[name];

  const addNewColor = () => {
    handleOptionChange(name, [...values, '#ffffff']);
  };

  const updateColor = (index, newColor) => {
    const newValues = [...values];
    newValues[index] = newColor.hex;
    handleOptionChange(name, newValues);
  };

  const removeColor = (indexToRemove) => {
    handleOptionChange(
      name,
      values.filter((_, index) => index !== indexToRemove),
    );
  };

  return (
    <FieldWrapper label={label}>
      <div className={styles.root}>
        {values && values.length > 0 && (
          <div className={styles.colorsList}>
            {values.map((color, index) => (
              <ColorOption
                key={`${color}-${index}`}
                color={color}
                onEdit={(newColor) => updateColor(index, newColor)}
                index={index}
                onRemove={removeColor}
                name={name}
              />
            ))}
          </div>
        )}
        <Button
          className={styles.addButton}
          onClick={addNewColor}
          variant={ButtonVariantEnum.TEXT}
          leftIcon={<PlusSquare />}
          size={ButtonSizeEnum.S}
        >
          Add new variant
        </Button>
      </div>
    </FieldWrapper>
  );
};

export default ColorField;
